import { Box, HStack, Image, Stack } from "@chakra-ui/react";
import React from "react";
import { common } from "../../constants/constants";
import { getImageURL } from "../../util/getImage";
import Medium from "../../Theme/Typography/Medium";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

export default function PostWithCitation({ data, handleGrid }) {
  return (
    <>
      {data?.map((item, index) => {
        return (
          <Box height="auto" key={index}>
            <Box onClick={() => handleGrid(item)} border={"1px"} borderColor={"color.lightgray5"} cursor={"pointer"} height={{ base: "150px", md: "205px" }} width={"100%"} display="flex">
              <Image fallbackSrc={placeholderImg} src={getImageURL(item?.image)} alt="" width="100%" height="auto" objectFit="contain" />
            </Box>
            <Stack pb="0" mt="8px"
            >
              <HStack>
                <Medium style={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }} color="color.blue">
                  {item?.title ? item?.title : common.CONTENT_AWAITED}
                </Medium>
              </HStack>
            </Stack>
          </Box>
        );
      })}
    </>
  );
}

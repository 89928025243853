// Core Files
import { Container, HStack, Image, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Service Files
import * as getFilmCastData from "../../../../services/Film";
import * as getFilmCrewData from "../../../../services/Crew";

// Subsection Files
import ImageGridList from "./ImageGridList.js";
import { isArray } from "lodash";
import DrawerHeader from "../../../../FunctionalComponents/CommonDrawer/DrawerHeader.js";
import DrawerBody from "../../../../FunctionalComponents/CommonDrawer/DrawerBody.js";
import CommonDrawer from "../../../../FunctionalComponents/CommonDrawer/CommonDrawer.js";
import H5 from "../../../../Theme/Typography/H5.js";
import { getImageURL } from "../../../../util/getImage.js";
import { common } from "../../../../constants/constants.js";
import LargeRegular from "../../../../Theme/Typography/LargeRegular.js";
import { DataNotFound } from "../../../../CommonComponents/DataNotFound.js";
//ImageFiles
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

function HorizontalLayout({ winnerName, tableName, masterlistType,name }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {drawerId}=useParams();
  const navigate = useNavigate();
  const [drawerData, setDrawerData] = useState(null);
  //key value
  const [castData, setCastData] = useState([]);
  const [crewData, setCrewData] = useState([]);

  const [loading, setLoading] = useState(true);
  const getData = async () => {
    try {
      setLoading(true);
    const result = await getFilmCastData.filmCastData(masterlistType, winnerName);
    setLoading(false);

    if (result && result?.length > 0) {
      setLoading(false);
      setCastData(
        result
          ?.map((item) => {
            if (item?.key !== "alias") {
              return {
                display: item?.display,
                anothervalue: item?.value,
              };
            }
            return null; // Skip the "alias" key
          })
          .filter(Boolean)
      );

      if(result && result?.length > 0 && drawerId){
          const record = result.find(item => 
            Array.isArray(item?.value) && item?.value.some(subItem => subItem.alias === drawerId)
        );

      if(record!==undefined){
        setDrawerData(() => drawerFormation(record.value[0]))
        setDrawerOpen(true)
      }

      }
    } else {
      setCastData([])
    }
  }catch (error) {
    setLoading(false);
      console.error("Error fetching data:", error);
      setCastData([])
    }
  };
  const getCrewData = async () => {
    try {
      setLoading(true);
    const result = await getFilmCrewData.getFilmCrewsByMasterlist(
      winnerName
    );
    setLoading(false);
    if (result && result?.length > 0) {
      setCrewData(
        result
          .map((item) => {
            return {
              image: item?.find(
                (nestedItem) => nestedItem?.key === "image_alias"
              )?.value?.direct_url_of_cover_image,
              subTitle: item?.find(
                (nestedItem) => nestedItem?.key === "character_name"
              )?.value 
                ? `as ${item.find((nestedItem) => nestedItem?.key === "character_name")?.value}`
                : '',
              title: item?.find(
                (nestedItem) => nestedItem?.key === "personality"
              )?.value[0]?.full_name,
              link: item?.find(
                (nestedItem) => nestedItem?.key === "artist_name"
              )?.link,
              alias:item?.find(
                (nestedItem) => nestedItem?.key === "alias"
              )?.value
            };
          })
          .filter(Boolean)
      );
      if (result?.length > 0 && drawerId) {
        let record;
        for (const entry of result) {
            for (const item of entry) {
                // Check if the alias matches the drawerId
                if (item?.key === "alias" && item?.value === drawerId) {
                    // Find the associated character name
                    const characterNameEntry = entry.find(e => e.key === "character_name");
                    const artistName = entry.find(e => e.key === "personality")?.value[0]?.full_name;

                    // Find the associated image URL
                    const imageAliasEntry = entry.find(e => e.key === "image_alias");
                    const imageUrl = imageAliasEntry ? imageAliasEntry.value.direct_url_of_cover_image : '';
                    record = {
                        image: imageUrl,
                        subTitle: `as ${characterNameEntry ? characterNameEntry.value : ''}`,
                        title: artistName, // Assuming 'item?.value' contains the title
                        link: '', // 'link' not provided in the data, add if needed
                        alias: item?.value
                    };
                    break;
                }
            }
            if (record) break;
        }
        if (record !== undefined) {
            setDrawerData(() => drawerFormation(record));
            setDrawerOpen(true);
        }
    }
    
    } else {
    }
  }catch (error) {
    setLoading(false);
      console.error("Error fetching data:", error);
      setCrewData([])
    }
  };
  useEffect(() => {
    getData();
    getCrewData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName, winnerName]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const drawerFormation = (data) => {
    return {
      name: data.full_name || data.title, 
      links: [
        { link_name: common.WORKING_STILLS,
          link: `/${masterlistType}/${winnerName}/agraphy/research-centre/archive?filter={"search":"${data?.full_name || data.title}","filters":{"sortable_year":[],"document_type":["CINE.wks"],"creator":[],"type_of_creator":[]},"sortBy_v":"","gate":"OR"}`,
        },
        { link_name: common.FILM_STILLS,
          link: `/${masterlistType}/${winnerName}/agraphy/research-centre/archive?filter={"search":"${data?.full_name || data.title}","filters":{"sortable_year":[],"document_type":["CINE.pho"],"creator":[],"type_of_creator":[]},"sortBy_v":"","gate":"OR"}`,
        },
        {
          link_name: common.BOOKS,
          link: `/${masterlistType}/${winnerName}/agraphy/research-centre/library?filter={"search":"${data?.full_name || data.title}","filters":{"sortable_year":[],"document_type":[],"creator":[],"type_of_creator":[]},"sortBy_v":"","gate":"AND"}`,
        },
      ],
      Image: ``,
      masterlist: {
        text: `Visit ${data?.full_name || data.title} Masterlist →`,
        link: `/generic/${data?.alias}`,
      },
    };
  };

  const handleCastDrawer=(item)=>{
    navigate(item?.alias)
    setDrawerData(() => drawerFormation(item));
    setDrawerOpen(true)
  }

  const handleClose = () => {
    setDrawerOpen(false);
    navigate(`/${masterlistType}/${winnerName}/agraphy/body-of-work/credit-cast`);
  };

  return (
    <Stack position={"relative"} minHeight={"calc(100vh - 280px)"}>
      <Stack
        width={{ base: "100%",  md: "1135px" }}
        pb={4}  
        flexDirection={"row-reverse"}
      >
        {drawerData !== undefined && drawerData!==null && (
          <CommonDrawer
            setDrawerOpen={handleClose}
            drawerOpen={drawerOpen}
            data={drawerData}
            drawerImage={getImageURL(name)}
            drawerHeader={<DrawerHeader drawerImage={getImageURL(name)} setDrawerOpen={handleClose} data={drawerData}/>}
            drawerBody={<DrawerBody data={drawerData}/>}
          />
        )}
        {loading ? (
          <Container maxW="auto" py="5" display={"flex"} justifyContent={"center"} >
            {" "}
            <Image src={loaderImg} />
          </Container>
        ) : (
        <Container maxW="auto" px="0">
          {castData?.length>0 || crewData?.length>0 ?
          <HStack py="20px">
            <Text className="wordspacing col-11" px="0px">
              {!loading && castData.length > 0 &&
                // eslint-disable-next-line
                castData.map((item, id) => {
                  if (
                    item?.anothervalue !== undefined &&
                    item?.anothervalue.length > 0
                  ) {
                    return (
                      <>
                        <H5 as="span" fontWeight={700} lineHeight={"17.61px"}>{item?.display}</H5>{" "}
                        {isArray(item?.anothervalue)
                          ? item?.anothervalue.map((it, index) => {
                             return (
                                <React.Fragment key={index}>
                                  {it.link ? (
                                    <>
                                      <span
                                        onClick={() => handleCastDrawer(it)}
                                        style={{
                                          color: "color.blue",
                                          fontWeight: "400",
                                          cursor: "pointer",
                                          wordSpacing: "2px",
                                        }}
                                      >
                                        {it?.full_name}
                                       {index <
                                          item?.anothervalue.length - 1 &&
                                          ","}
                                        {""}
                                      </span>
                                    </>
                                  ) : (
                                    <LargeRegular
                                     as={"span"}
                                     color={"color.blue"}
                                     cursor={"pointer"}
                                     className="word-spacing"
                                     onClick={() => handleCastDrawer(it)}
                                    >
                                      {it?.full_name}
                                      {index <
                                          item?.anothervalue.length - 1 &&
                                        ", "}{" "}
                                    </LargeRegular>
                                  )}
                                </React.Fragment>
                              );
                            })
                          : item?.anothervalue}
                      </>
                    );
                  }
                })}
            </Text>
          </HStack>
          :(
            <DataNotFound/>
          )
          
          }

          <Stack bg="white">
            {crewData && crewData?.length > 0 && (
              <ImageGridList
                data={crewData}
                columns={[1, 2, null, 4]}
                spacing={"15px"}
                setDrawerOpen={handleCastDrawer}
                text={true}
                width={"269px"}
                height={"198px"}
              />
            )}
          </Stack>
        </Container>
      )}
      </Stack>
    </Stack>
  );
}
export default HorizontalLayout;

import { common, explore } from "../../constants/constants";
import AccordianEduction from "../../FunctionalComponents/Accordion/AccordianEduction";
import AccordianPersonalia from "../../FunctionalComponents/Accordion/AccordianPersonalia";
import AccordianTeaching from "../../FunctionalComponents/Accordion/AccordianTeaching";
import AccordianText from "../../FunctionalComponents/Accordion/AccordianText";

export function formatData(introData, personalia, educationData, teachingData,showFamilyMemberImages,showEducationImages) {
    const components = [
      {
        name: `${explore.PREFACE}`,
        data: introData,
        component: <AccordianText data={introData!==null ? introData :common.CONTENT_AWAITED} />,
      },
      {
        name: `${explore.PERSONALIA}`,
        data: personalia,
        component: <AccordianPersonalia personalia={personalia ? personalia :[]} showFamilyMemberImages={showFamilyMemberImages} />,
      },
      {
        name: `${explore.EDUCATION}`,
        data: educationData,
        component: <AccordianEduction educationData={educationData.length>0 ? educationData :common.CONTENT_AWAITED} showEducationImages={showEducationImages} />,
      },
      {
        name: `${explore.TEACHING_INFRA_B}`,
        data: teachingData,
        component: <AccordianTeaching teachingData={teachingData ? teachingData : {}} showFamilyMemberImages={showFamilyMemberImages} />,
      }
    ];
  
    return components
  }


  export const categorizeData = (data) => {
    const categorized = {};
    data?.length>0 && data.forEach((item) => {
      const { post } = item;
      const key = post;
      if (!categorized[key]) {
        categorized[key] = [];
      }
      categorized[key].push(item);
    });
    return categorized;
  };
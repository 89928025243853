import {
  Box,
} from "@chakra-ui/react";
import _ from "lodash";
import React from "react";

export default function AccordianText({data,width="100%", py="0", textAlign="justify", fontSize="14px", fontWeight="500", lineHeight="16px"}) {
  const formattedData = _.replace(data, /\n\n/g, '<br/><br/>');

  return (
    <>
      {data &&
           (
            <Box
              fontSize={fontSize}
              fontWeight={fontWeight}
              color="black"
              lineHeight={lineHeight}
              textAlign={textAlign}
              width={width}
              py={py}
              dangerouslySetInnerHTML={{ __html: formattedData }}

            />
             
          )
        }
    </>
  );
}

export function getChartOptions(dynamicdata) {
    // Extract languages from keys of first data object (assuming all objects have the same structure)
    const categories = Object.keys(dynamicdata[0]).filter(key => key !== 'year');
  
    // Extract population data for each year
    const population2001 = categories.map(category => dynamicdata[0][category]);
    const population2011 = categories.map(category => dynamicdata[1][category]);
  
    // Return the options
    return {
      title: {
        text: 'Language Demography',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: ['2001 Population', '2011 Population'],
        bottom: 0,
      },
      xAxis: {
        type: 'category',
        data: categories, // ['Hindi', 'Awadhi', 'Urdu'] extracted dynamically
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: '2001 Population',
          type: 'bar',
          data: population2001, // Population data for 2001
          itemStyle: {
            color: '#0044cc', // Blue for 2001
          },
          barWidth: '20%',
        },
        {
          name: '2011 Population',
          type: 'bar',
          data: population2011, // Population data for 2011
          itemStyle: {
            color: '#ffcc00', // Yellow for 2011
          },
          barWidth: '20%',
        },
      ],
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true,
      },
    };
  }
  
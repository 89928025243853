import { Box, Container, HStack, Stack } from "@chakra-ui/react";
import React from "react";
import { FilterButton } from "../../../CommonComponents/FilterButton";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../CommonComponents/NoMoreToLoad";
import { CopyPasteService } from "../../../services/CopyPaste";
import { DataNotFound } from "../../../CommonComponents/DataNotFound";
import { FilterBox } from "../../../FunctionalComponents/Filter/FilterBox";
import Grid from "../../../FunctionalComponents/SimpleGrid/Grid";
import ImageGridList from "../../../FunctionalComponents/SimpleGrid/ImageGridList";

import FilterPills from "../../FilterPills/FilterPills";
//ImageFiles
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

export default function Gridview({
  showFilterBox,
  masterlistType,
  handleFilterClick,
  data,
  fetchMoreData,
  hasMore,
  showArchiveImages,
  setShowFilterBox,
  setApply,
  explore,
  facets,
  sort,
  handleCheckboxChange,
  handleSearch,
  filterOptions,
  filteroptionData,
  filterCount,
  setFilterOptions,
  setSearchTerm,
  searchTerm,
  handleReset,
  filerStyle,
  filterPills,
  setFilterPills,
  filteredResultCount,
}) {
  return (
    <>
    <Stack flexDirection={"row-reverse"}>
      {!showFilterBox && (
        <FilterButton
          marginTop="40px"
          masterlistType={masterlistType}
          onClick={handleFilterClick}
        />
      )}
     <Container
          maxW="auto"
          className="songs"
          px={{ base: "4", md: "0px" }}
          py={masterlistType === "generic"? "45px":"8"}
          position={"relative"}
        >
         <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {data && data.length > 0 ? (
            <Stack
            onCopy={(e) =>
              CopyPasteService(e, `${window.location.href}/0/1`)
            }
            marginRight={{ base: "0px", md: "30px" }}
            alignItems={"flex-start"}
          >
          <Box zIndex={99} bg="white" width={"100%"} position={"sticky"}top={masterlistType === "film"
        ? "245px":"125px"}>
          {((filterPills &&  filterPills!==undefined && filterCount>0) || filterOptions?.search) &&
          <FilterPills
          filters={filterPills}
          setFilterOptions={setFilterOptions}
          setApply={setApply}
          setSearchTerm={setSearchTerm}
          setFilterPills={setFilterPills}
          filteredResultCount={filteredResultCount}
          />
          }</Box>
              <InfiniteScroll
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <Box textAlign="center" marginLeft={"50%"}>
                    <Stack marginTop={"20px"}>
                      <img
                        src={loaderImg}
                        width={"100px"}
                        height={"100px"}
                        alt="Loading"
                      />
                    </Stack>
                  </Box>
                }
                endMessage={<NoMoreToLoad />}
              >
               <Grid
                   columns={{ base: 2, md: 3, lg: 4, xl: 4 }}
                   spacing={"24px"}
                   height={"200px"}
                   data={data}
                   showArchiveImages={showArchiveImages}
                   archive={true}
                   text={true}
                   component={ImageGridList}
                  />
              </InfiniteScroll>
            </Stack>
          ) : (
            <>
               <Stack
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/1`)
                  }
                  margin={"auto"}
                >
                  <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                   {filterPills &&  filterPills!==undefined && filterCount>0&&
               <FilterPills
               filters={filterPills}
               setFilterOptions={setFilterOptions}
               setApply={setApply}
               setFilterPills={setFilterPills}
                  />
          }
          </Box>
                  <DataNotFound />
                </Stack>
            </>
          )}

          <HStack
            marginLeft={"4"}
            marginRight={"0px!important"}
            className="mt-0"
            alignItems={"flex-start"}
            pos={masterlistType === "generic" && "relative"}
            right={masterlistType === "generic" && "-5px"}
          >
            {showFilterBox && (
              <FilterBox
                setShowFilterBox={setShowFilterBox}
                setApply={setApply}
                explore={explore}
                facets={facets}
                sort={sort}
                handleCheckboxChange={handleCheckboxChange}
                handleSearch={handleSearch}
                filterOptions={filterOptions}
                filteroptionData={facets}
                filterCount={filterCount}
                setFilterOptions={setFilterOptions}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                handleReset={handleReset}
                className={filerStyle? filerStyle : "box-filter-film"}
              />
            )}
          </HStack>
        </Stack>
      </Container>
    </Stack>
    </>
  );
}

// Core Files
import { Box, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";

// Subsection Files
import Medium from "../../../../Theme/Typography/Medium";
import Small from "../../../../Theme/Typography/Small";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

export default function ImageGridList({
  data,
  setDrawerOpen,
  text,
  columns,
  spacing,
  handleImageClick,
  handleImage,
  width,
  height,
  transition,
  className,
  archive,
  ...rest
}) {
  return (
    <>
      {data && <SimpleGrid columns={columns} spacing={spacing} justifyItems={"center"}>
        {data?.map((item, index) => (
          <Box
            height="100%"
            justifyItems="center"
            justifyContent="space-around"
            width={width && width}
          >
            <Box height={height ? height : "100%"} width={width && width} className={className && className}>
              <Image
                src={item?.image}
                fallbackSrc={placeholderImg}
                width={"100%"}
                height={height && "100%"}
                objectFit={"contain"}
                cursor={"pointer"}
                transition={transition && transition}
                onClick={
                  setDrawerOpen
                    ? 
                    () => {
                          (item
                            ?
                            setDrawerOpen(item)
                            : archive?setDrawerOpen(item): setDrawerOpen(false));
                      }
                    : item.link && ((handleImageClick && handleImageClick) || (handleImage && handleImage(item?.alias)))
                }
              />
            </Box>
            {text && (
              <Box textAlign={{base:"center",md:"left"}} py="1">
                <Medium
                  color="color.blue"
                  cursor="pointer"
                  textStyle="primary"
                  lineHeight={"18px"}
                  textAlign={"left"}
                  onClick={
                    setDrawerOpen
                      ? () => {
                            (index === 0
                              ?
                              setDrawerOpen(item)
                              : setDrawerOpen(false));
                        }
                      : handleImageClick
                  }
                >
                  {item?.title}
                </Medium>
                {item?.subTitle && (
                  <Small
                    pb="1"
                    fontWeight="300"
                    color="color.gray"
                    lineHeight="14px"
                    textStyle="secondary"
                    textAlign={"left"}
                    marginInlineStart={"0.0rem !important"}
                  >{`${item?.subTitle}`}</Small>
                )}
              </Box>
            )}
          </Box>
        ))}
      </SimpleGrid>}
    </>
  );
}

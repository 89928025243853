import { HStack, IconButton, Image, Stack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { BsGrid } from "react-icons/bs";
import { GridView } from "./GridView";
import TabularView from "./TabularView";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";
import * as getFilmographyData from "../../services/Filmography";
import { common } from "../../constants/constants";
import { formatName } from "../../util/helper";
import { useNavigate } from "react-router-dom";
import { totalFilterCount} from "../../util/helper";
import FilterPills from "../FilterPills/FilterPills";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const printer = `${IMAGE_BASE_PATH}/ui/Chevron/printer.svg`;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;
export default function FilmoGraphyTabpanel({
  handlePrint,
  masterlist,
  tableName,
  masterlistType,
  setDataCount
}) {
  const [visible, setVisible] = React.useState(false);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState({
    search: "",
    filters: {}
  });
  const [filterPills, setFilterPills] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  const [apply, setApply] = useState(false);
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches",e.target.value)
  };

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";  
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
        case "gate":
          selectedString = filterOptions.gate;
          break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }
  
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
  
    if (selectedString !== type) {
      selectedString = type;
    }
  
    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
        case "gate":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          gate: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };

  const citationFactor = (data) => {
    return data.map((item) => ({
      year:
        item.find((nestedItem) => nestedItem.key === "year_of_film")?.value ||
        "",
      desc:
        `${
          item.find((nestedItem) => nestedItem.key === "bibliographic_citation")
            ?.value
        }` || "",
      shortdesc: [
        item.find((nestedItem) => nestedItem.key === "film_length")?.value || null,
        item.find((nestedItem) => nestedItem.key === "film_type")?.value || null,
        item.find((nestedItem) => nestedItem.key === "color")?.value || null,
        item.find((nestedItem) => nestedItem.key === "duration_mins")?.value || null,
        item.find((nestedItem) => nestedItem.key === "country_of_origin_of_film")
          ?.value || null
      ]
        .filter(Boolean) // Filters out null, undefined, or empty values
        .join(" | "),
      link: item.find((nestedItem) => nestedItem.key === "drawer_link")?.value,
      mainTitle: item.find((nestedItem) => nestedItem.key === "main_title")
        ?.value,
      image:
        item
          .find((nestedItem) => nestedItem.key === "image_alias")
          ?.value || null,
      alias: item.find((nestedItem) => nestedItem.key === "film_alias")?.value,
      bookletAlias:
        item.find((nestedItem) => nestedItem.key === "film_alias")?.value || ``,
    }));
  };

  const handleReset = () => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setFilterCount(0)
    setSearchTerm("");
  }

  const [facets, setFacets] = useState([]);
  const [sort, setSort] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const initialLoadComplete = useRef(false);

  async function fetchData() {
    try {
      setLoading(true);
      const result =
        await await getFilmographyData.getFilmographyDataByMasterlist(
          masterlistType,
          masterlist,
          filterOptions,
          page
        );
        setLoading(false);
      if (result && result?.data?.length > 0) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        if (!initialLoadComplete.current) {
          setPage((prevPage) => prevPage + 1);
          initialLoadComplete.current = true;
        }
        setDataCount(result?.total)
        const formattedFacets = result?.facetsHeaders?.map((header) => {
          const key = Object.keys(header)[0];
          const display = header[key];
          const value = result?.facets[0][key]; // Assuming facetsData has only one object

          return {
            key,
            display,
            value,
          };
        });
        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        setFacets(formattedFacets);
        if (formattedFacets && formattedFacets?.length > 0) {
          setFilterOptions((prevFilterOptions) => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              ...formattedFacets.reduce((acc, facet) => {
                acc[facet.key] = [];
                return acc;
              }, {}),
            },
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setData([]);
    }
  }
  useEffect(() => {
    fetchData();
    setFilterPills(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchMoreData = async () => {
    try {
      const result =
        await await getFilmographyData.getFilmographyDataByMasterlist(
          masterlistType,
          masterlist,
          filterOptions,
          page
        );
      if (result) {
        const processedData = citationFactor(result?.data);
        setData((prevData) => [...prevData, ...processedData]);
        setPage((prevPage) => prevPage + 1);
        const formattedFacets = result?.facetsHeaders?.map((header) => {
          const key = Object.keys(header)[0];
          const display = header[key];
          const value = result?.facets[0][key];
          return {
            key,
            display,
            value,
          };
        });
        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        setFacets(formattedFacets);
        if (data?.length >= result?.total) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function fetchFilterData(page=1) {
    try {
      setLoading(true);

      const result =
        await await getFilmographyData.getFilmographyDataByMasterlist(
          masterlistType,
          masterlist,
          filterOptions,
          page
        );
        setLoading(false);

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        const formattedFacets = result?.facetsHeaders?.map((header) => {
          const key = Object.keys(header)[0];
          const display = header[key];
          const value = result?.facets[0][key];

          return {
            key,
            display,
            value,
          };
        });
        setFacets(formattedFacets);
        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        const filtercnt= totalFilterCount(filterOptions?.filters)
        
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }
        if (data?.length >= result?.total) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);

    }
  }

  useEffect(() => {
    if (apply) {
      setApply(false);
      setHasMore(true);
      setPage(1)
      fetchFilterData();
      setFilterPills(filterOptions)
      const filtercnt= totalFilterCount(filterOptions?.filters)
      setFilterCount(filtercnt)
    }

    const filtercnt= totalFilterCount(filterOptions?.filters)
    setFilterCount(filtercnt)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);
  const drawerFormation = (data) => {
    return {
      name: ` ${formatName(masterlist)} & ${data?.mainTitle}`,
      links: [
        {
          link_name: common.BOOKS,
          link: `/film/${data?.alias}/agraphy/research-centre/library?filter={"search":"","filters":{"sortable_year":[],"document_type":["BOOK.bka","BOOK.bke"],"creator":[],"type_of_creator":[]},"sortBy_v":"","gate":"AND"}`,
        },
        {
          link_name: common.MAGAZINE_ARTICLES,
          link: `/film/${data?.alias}/agraphy/research-centre/library?filter={"search":"","filters":{"sortable_year":[],"document_type":["Book.mag","BOOK.jou","BOOK.exc.solo","BOOK.ffc","BOOK.exc.group","BOOK.exi","BOOK.auc"],"creator":[],"type_of_creator":[]},"sortBy_v":"","gate":"AND"}`,
        },
        {
          link_name: common.MEMORABILIA,
          link: `/film/${data?.alias}/agraphy/research-centre/archive`,
        },
      ],
      Image: data?.Image,
      masterlist: {
        text: `Visit ${data?.mainTitle} Masterlist →`,
        link: `/generic/${data?.alias}`,
      },
    };
  };
  const handleBooklet = (bookletAlias) => {
    navigate(bookletAlias);
  };
  const handleDrawer = (item) => {
    setOpenDrawer(true);
    // drawerFormation(item)
    if (item) setDrawerBook(drawerFormation(item));
    // navigate(`${item?.alias}`);
  };
  const [drawerBook, setDrawerBook] = useState("");

  return (
    <Stack position={"relative"}>
      <Stack
        zIndex={99}
        position={"sticky"}
        top={{ base: "124px", md: "80px" }}
        marginTop={"-60px"}
        marginLeft={"auto"}
        marginRight={"45px"}
        flexDirection="row"
        justifyContent={"flex-end"}
        id="listgridviewbtn"
        gap={0}
        bg="white"
      >
        <HStack marginTop={"0px!important"}>
          <IconButton
            variant="unstyled"
            onClick={() => setVisible(false)}
            color={!visible ? "black" : "#989898"}
            fontSize="20px"
            display="flex"
            borderTopLeftRadius="4px"
            borderBottomLeftRadius="4px"
            borderTopRightRadius="0px"
            borderBottomRightRadius="0px"
            border="1px"
            borderColor={"#989898"}
            height="32px"
          >
            <RiMenuLine ml="4" />
          </IconButton>
        </HStack>
        <HStack marginTop={"0px!important"}>
          <IconButton
            variant="unstyled"
            onClick={() => setVisible(true)}
            color={visible ? "black" : "#989898"}
            fontSize="16px"
            display="flex"
            borderTopLeftRadius="0px"
            borderBottomLeftRadius="0px"
            borderTopRightRadius="4px"
            borderBottomRightRadius="4px"
            border="1px"
            borderColor={"#989898"}
            height="32px"
          >
            <BsGrid />
          </IconButton>
        </HStack>
        {!visible && (
          <HStack
            paddingLeft={{ base: "15px", md: "30px" }}
            marginRight={{ base: "-15px!important", md: "-45px!important" }}
            marginTop={"0px!important"}
          >
            <Image
              onClick={handlePrint}
              cursor={"pointer"}
              src={printer}
              width={"24px"}
              height={"24px"}
            ></Image>
          </HStack>
        )}
      </Stack>
     {!loading ?
      <>
      {filterPills &&  filterPills!==undefined && filterCount>0 &&
      <HStack pt={4}>
         <FilterPills
          filters={filterPills}
          setFilterOptions={setFilterOptions}
          setApply={setApply}
          setSearchTerm={setSearchTerm}
          setFilterPills={setFilterPills}
          filteredResultCount={filteredResultCount}
          />
      </HStack>
   }
      {visible ? (
        <GridView
          masterlist={masterlist}
          tableName={tableName}
          masterlistType={masterlistType}
          data={data}
          showFilterBox={showFilterBox}
          handleFilterClick={handleFilterClick}
          handleCheckboxChange={handleCheckboxChange}
          handleSearch={handleSearch}
          filterOptions={filterOptions}
          filteroptionData={facets}
          setFilterOptions={setFilterOptions}
          setSearchTerm={setSearchTerm}
          filterCount={filterCount}
          searchTerm={searchTerm}
          handleReset={handleReset}
          fetchMoreData={fetchMoreData}
          hasMore={hasMore}
          setShowFilterBox={setShowFilterBox}
          setApply={setApply}
          sort={sort}
          loading={loading}
          handleDrawer={handleDrawer}
          drawerBook={drawerBook}
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}

        />
      ) : (
        <TabularView
          masterlist={masterlist}
          tableName={tableName}
          masterlistType={masterlistType}
          data={data}
          showFilterBox={showFilterBox}
          handleFilterClick={handleFilterClick}
          handleCheckboxChange={handleCheckboxChange}
          handleSearch={handleSearch}
          filterOptions={filterOptions}
          filteroptionData={facets}
          setFilterOptions={setFilterOptions}
          setSearchTerm={setSearchTerm}
          filterCount={filterCount}
          searchTerm={searchTerm}
          handleReset={handleReset}
          fetchMoreData={fetchMoreData}
          hasMore={hasMore}
          setShowFilterBox={setShowFilterBox}
          setApply={setApply}
          sort={sort}
          loading={loading}
          handleBooklet={handleBooklet}
          setOpenDrawer={setOpenDrawer}
          handleDrawer={handleDrawer}
          drawerBook={drawerBook}
          openDrawer={openDrawer}
          setFilterPills={setFilterPills}
          filterPills={filterPills}

        />
      )}
      </> :
      <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} >
      <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" mt={12} />
    </HStack>
      }
    </Stack>
  );
}

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  HStack,
  Stack,
  Image,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useBreakpointValue,
} from "@chakra-ui/react";

// Core files
import InfiniteScroll from 'react-infinite-scroll-component';

// Service Files
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getresearchCentreAll from "../../../services/Search";

// Subsection Files
import { explore } from "../../../constants/constants";
import { FilterButton } from "../../../CommonComponents/FilterButton";
import { useParams, useNavigate } from "react-router";
import { FilterBox } from "../../../FunctionalComponents/Filter/FilterBox";
import NoMoreToLoad from "../../../CommonComponents/NoMoreToLoad";
import { createDataObject, totalFilterCount } from "../../../util/helper";
import CommonDrawer from "../../../FunctionalComponents/CommonDrawer/CommonDrawer";
import DrawerHeader from "../../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerHeader";
import DrawerBody from "../../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerBody";
import DrawerFooter from "../../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerFooter";
import ArchiveGrid from "../../../FunctionalComponents/SimpleGrid/ArchiveGrid";
import Grid from "../../../FunctionalComponents/SimpleGrid/Grid";
import SubscriptionModal from "../../../FunctionalComponents/SubscriptionModal/SubscriptionModal";
import { DataNotFound } from "../../../CommonComponents/DataNotFound";
import SortBy from "../../../components/ResearchCentre/Components/SortBy";
import FilterPills from "../../../components/FilterPills/FilterPills";
import { updateFacetsData } from "../../../util/helper";
import { FullScreenImage } from "../../../components/FullScreen/AuctionFullScreen";
import * as getResearchCentreBook from "../../../services/book";
import ContentModal from "../../Film/ResearchCentre/Library/ContentModal";
import SliderModal from "../../Film/ResearchCentre/Library/SliderModal";
import { drawerFactor } from "../../../util/ResearchCentreLibraryDrawer";

//ImageFiles
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

export const All = ({ tableName, masterlist, masterlistType = "search", setAllCount, setOpenDrawer, openDrawer, imageTitle, SearchTerm, handleBookClick, filterbuttonStyle }) => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState({ search: "", filters: {}, sortBy_v: "sortable_year", gate: "AND" });
  const [apply, setApply] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [archiveFullScreenImage, setArchiveFullScreenImage] = useState(false);
  const [archiveImgData, setArchiveImgData] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [filterCount, setFilterCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const { tabValue, bookAlias } = useParams();
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [filterPills, setFilterPills] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const initialLoadComplete = useRef(false);
  const [contentClicked, setContentClicked] = useState(false);
  const [scanClicked, setScanClicked] = useState(false);
  const [indexValue, setIndexValue] = useState();
  const [swiper, setSwiper] = useState(null);
  const [bookData, setBookData] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  const showSliderImage = (index) => {
    swiper.slideTo(index);
  };
  const handleContentClicked = (index, text) => {
    setIndexValue(index);
    // showSliderImage(2);
    if (text === "Contents Pages→") {
      setContentClicked(true);
      setScanClicked(false);
    }
    if (text === "Scanned Pages→") {
      setScanClicked(true);
      setContentClicked(false);
    }
  };
  const navigate = useNavigate();
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  //  Filter Execution starts
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches", e.target.value)
  };

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";

    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      case "gate":
        selectedString = filterOptions.gate;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }

    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }

    if (selectedString !== type) {
      selectedString = type;
    }

    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
      case "gate":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          gate: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };

  const citationFactor = (data) => {
    return data.map((item, index) => {

      //archive
      const image = item?.find((nestedItem) => nestedItem?.key ===
        "direct_url_of_preview_image")?.value ||
        "";
      const title = item?.find((nestedItem) => nestedItem?.key ===
        "bibliographic_citation")?.value ||
        item?.find((nestedItem) => nestedItem?.key ===
          "bibliograhpic_citation")?.value ||
        "";
      const listDesc = item?.find((nestedItem) => nestedItem?.key ===
        "bibliographic_caption")?.value
      const tittle = createDataObject([item?.find((nestedItem) => nestedItem?.key === "title")]);
      const medium = createDataObject([item?.find((nestedItem) => nestedItem?.key === "medium_artwork")]);
      const lot_number = createDataObject([item?.find((nestedItem) => nestedItem?.key === "lot_number")]);
      const auction_house = createDataObject([item?.find((nestedItem) => nestedItem?.key === "auction_house_name")]);
      const venue_location = createDataObject([item?.find((nestedItem) => nestedItem?.key === "venue")]);
      const date_of_auction = createDataObject([item?.find((nestedItem) => nestedItem?.key === "auction_year_of_event")]);
      const type_of_auction = createDataObject([item?.find((nestedItem) => nestedItem?.key === "type_of_auction")]);
      const size = createDataObject([item?.size]);

      //library
      const imagePath = item?.find((nestedItem) => nestedItem?.key === "direct_url_of_cover_image")?.value || item?.find((nestedItem) => nestedItem?.key === "direct_url_of_preview_image")?.value || "";
      const firstName = item?.find((nestedItem) => nestedItem?.key === "creator_first_name")?.value
      const lastName = item?.find((nestedItem) => nestedItem?.key === "creator_surname")?.value
      const creatorName = item?.find((nestedItem) => nestedItem?.key === "creator_name")?.value
      const author = lastName && firstName ? `${firstName} ${lastName}` : "";
      const bookKey =
        item?.find((nestedItem) => nestedItem?.key === "title")?.value || null;
      const book = bookKey;
      const link =
        item?.find((nestedItem) => nestedItem?.key === "drawer_link")?.value ||
        null;

      const docType =
        item?.find((nestedItem) => nestedItem?.key === "document_type")
          ?.value || null;
      const typeCreator =
        item?.find((nestedItem) => nestedItem?.key === "type_of_creator")
          ?.value || null;
      const fullname = creatorName !== "" ? author : creatorName;
      const sliderImage = item?.find((nestedItem) => nestedItem?.key === "drawer_image_alias")
        ?.value &&
        item?.find((nestedItem) => nestedItem?.key === "drawer_image_alias")
          ?.value.length > 0 &&
        item?.find((nestedItem) => nestedItem?.key === "drawer_image_alias")
          ?.value;
      const bibliograhpic_citation =
        item?.find((nestedItem) => nestedItem?.key === "bibliograhpic_citation")
          ?.value ||
        item?.find((nestedItem) => nestedItem?.key === "bibliographic_citation")
          ?.value ||
        "";
      const desc = bibliograhpic_citation || "";
      const alias = item?.find(
        (nestedItem) => nestedItem?.key === "alias"
      )?.value;
      const drawerTitle = item?.find(
        (nestedItem) => nestedItem?.key === "title"
      )?.value;
      const header = `${drawerTitle ? drawerTitle : ''}`;
      const publisher = item?.find(
        (nestedItem) => nestedItem?.key === "place_of_publication"
      )?.value;
      const bookCaption =
        item?.find((nestedItem) => nestedItem?.key === "book_caption")?.value ||
        null;
      const publicationYear =
        item?.find((nestedItem) => nestedItem?.key === "sortable_year")
          ?.value || null;
      const firstEdition =
        item?.find((nestedItem) => nestedItem?.key === "date_of_first_edition")
          ?.value || null;
      // const ind=index;
      return {
        image,
        title,
        listDesc,
        imagePath,
        desc,
        header,
        bookKey,
        link,
        typeCreator,
        book,
        author,
        docType,
        sliderImage,
        fullname,
        alias,
        drawerTitle,
        publisher,
        bookCaption,
        publicationYear,
        firstEdition,
        index,
        fullImageTitle: listDesc,
        essayistData: [
          tittle,
          medium,
          lot_number,
          auction_house,
          venue_location,
          date_of_auction,
          type_of_auction,
          size,
        ],
        body: [
          {
            text: "Index Page →",
          }
        ],
        footer: [
          {
            text: "Contents Page→",
          },
          {
            text: "Scanned Pages→",
          },

        ]
      }
    });
  };
  const [facets, setFacets] = useState([]);
  const [sort, setSort] = useState([]);

  async function fetchData() {
    try {
      setIsLoading(true);
      const result = await getresearchCentreAll.searchResearchAllTab(SearchTerm, filterOptions, 1);
      setIsLoading(false);
      if (result && result?.data.length > 0) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        if (!initialLoadComplete.current) {
          setPage(page + 1);
          initialLoadComplete.current = true;
        }
        setAllCount(result?.total)
        if (result?.data?.length === result?.total) {
          setHasMore(false);
        }
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);

          if (formattedFacets && formattedFacets.length > 0) {
            setFilterOptions((prevFilterOptions) => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                ...formattedFacets.reduce((acc, facet) => {
                  acc[facet.key] = [];
                  return acc;
                }, {}),
              },
            }));
          }
        }
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setAllCount(result?.total)
        setSort(sortedFacets);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  }
  const handleResetPage = () => {
    initialLoadComplete.current = false;
    setHasMore(true);
    setPage(1);
    setTriggerFetch(prev => !prev);
  }
  useEffect(() => {
    handleResetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchTerm]);

  useEffect(() => {
    fetchData();
    setFilterPills(filterOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFetch]);

  const fetchMoreData = async () => {
    try {
      const result = await getresearchCentreAll.searchResearchAllTab(SearchTerm, filterOptions, page);

      if (result) {

        const processedData = citationFactor(result?.data);
        setData(prevData => [...prevData, ...processedData]);
        setPage(page + 1);

        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);

        if (data?.length >= result?.total) {
          setHasMore(false);
        }

      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function fetchFilterData(page = 1) {

    try {
      setIsLoading(true);
      const result = await getresearchCentreAll.searchResearchAllTab(SearchTerm, filterOptions, page);
      setIsLoading(false);
      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        setPage(prev => prev + 1)
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        const filtercnt = totalFilterCount(filterOptions?.filters)
        if (filtercnt > 0 || filterOptions?.search) {
          setFilteredResultCount(result?.total)
        } else {
          setFilteredResultCount(0);
        }
        if (result?.data?.length >= result?.total) {
          setHasMore(false);
        }

        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  }
  useEffect(() => {

    if (apply) {
      setApply(false);
      setHasMore(true);
      setPage(1)
      fetchFilterData();
      setFilterPills(filterOptions)
    }

    const filtercnt = totalFilterCount(filterOptions?.filters)
    setFilterCount(filtercnt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const showArchiveImages = (image, item, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setArchiveFullScreenImage(flag);
      setArchiveImgData(image);
      if (masterlistType === "search") {
        if (!window.location.pathname.includes(item?.alias)) {
          navigate(`/${masterlistType}/research-centre/all/${item?.alias}?query=${encodeURIComponent(SearchTerm)}`);
        }
      } else {
        if (tabValue === "all") {
          navigate(`/${masterlistType}/${SearchTerm}/research-centre/all/${item?.alias}`);
        }
      }
    } else {
      setArchiveFullScreenImage(flag);
      setArchiveImgData(null);
    }
  };
  const handleDrawer = (item) => {

    if (item?.docType?.includes("BOOK")) {
      setOpenDrawer(true);
      setDrawerBook(item);
      if (masterlistType === "search") {
        navigate(`/${masterlistType}/research-centre/all/${item?.alias}?query=${encodeURIComponent(SearchTerm)}`);
      } else {
        navigate(`/${masterlistType}/${SearchTerm}/research-centre/all/${item?.alias}`);
      }
    }
    else {
      showArchiveImages(item?.image ? item?.image : item?.imagePath, item, true, item?.index)
    }
  };

  const handleReset = () => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setFilterCount(0)
    setSearchTerm("");
  }
  const handleClose = (item) => {
    setOpenDrawer(false);
    setDrawerBook("");
    showArchiveImages(null, false)
    setArchiveFullScreenImage(false);
    setContentClicked(false);
    setScanClicked(false);
    setShowSubscribeModal(false)
    if (tabValue === "all" && masterlistType === "search") {
      showArchiveImages(null, false);
      if (!(SearchTerm === item?.alias)) {
        navigate(-1)
      }
    }
    if (masterlistType === "search") {
      navigate(`/${masterlistType}/research-centre/all?query=${encodeURIComponent(SearchTerm)}`);
    } else {
      navigate(`/${masterlistType}/${SearchTerm}/research-centre/all`);

    }
  };
  const [drawerBook, setDrawerBook] = useState("");

  useEffect(() => {

    if (data && bookAlias && bookAlias !== undefined && tabValue === "all") {
      const item = data.find((it) => it?.alias === bookAlias);
      if (item !== undefined) {
        if (item?.docType?.includes("BOOK")) {
          setDrawerBook(item);
          // setOpenDrawer(true);
        } else {
          showArchiveImages(item?.image, item, true, item?.index);
        }
      }
    }
    const fetchData = async () => {
      let result;
      result = await getResearchCentreBook.getResearchCentreBookbyBookAlias(
        bookAlias
      );
      const item = result?.data[1]?.dynamicBookData?.find((nestedItem) => nestedItem?.key === "document_type")?.value;
      if (item !== undefined) {
        if (item?.includes("BOOK")) {
          setBookData(drawerFactor(result?.data[0]?.staticBookData, result?.data[1]?.dynamicBookData));
          setOpenDrawer(true);
        }
      }
    };
    if (data && bookAlias && bookAlias !== undefined && tabValue === "all") {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  }, [data, bookAlias]);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const [pageIndex, setPageIndex] = useState(0);

  const handlePagination = (item) => {
    const initalPage = item.split("-");
    setPageIndex(+initalPage[0]);
    setScanClicked(true);
    if (scanClicked) {
      showSliderImage(+initalPage[0])
    }
  }
  return (
    <>
      {sort && sort?.length > 0 &&
        <SortBy
          sort={sort}
          masterlistType={masterlistType}
          filterOptions={filterOptions}
          handleCheckboxChange={handleCheckboxChange}
          setApply={setApply}
        />
      }
      {
        !isLoading ?
          <Stack flexDirection={"row-reverse"}>
            {bookAlias && bookData && (
              <CommonDrawer
                drawerOpen={openDrawer}
                setDrawerOpen={handleClose}
                returnFocusOnClose={false}
                drawerBook={drawerBook}
                drawerHeader={
                  <DrawerHeader
                    imageTitle={imageTitle}
                    data={bookData}
                    handleClose={handleClose}
                    masterlist={masterlist}
                  />
                }
                drawerBody={
                  <DrawerBody
                    setSwiper={setSwiper}
                    data={bookData}
                    handleBookClick={handleBookClick}
                    handlePagination={handlePagination}

                  // bibliographyObject={bibliographyObject}
                  />
                }
                drawerFooter={
                  <DrawerFooter
                    data={bookData}
                    // setShowSubscribeModal={setShowSubscribeModal}
                    contentClicked={contentClicked}
                    scanClicked={scanClicked}
                    handleContentClicked={handleContentClicked}
                    indexValue={indexValue}
                  />
                }
              />
            )}
            {!showFilterBox && <FilterButton filterbuttonStyle={filterbuttonStyle} marginTop="40px" masterlistType={"generic"} onClick={handleFilterClick} />}
            <Container
              maxW="auto"
              className="songs"
              px={{ base: "0px", md: "0px" }}
              py={{ base: masterlistType === "generic" ? "40px" : "0px", md: masterlistType === "generic" ? "40px" : "8" }}
              position={"relative"}
            >

              <Stack flexDirection={{ base: "column", md: "row" }} gap={4} justifyContent="space-between">
                {data && data.length > 0 ?
                  <Stack
                    marginRight={{ base: "0px", md: "30px" }}
                    alignItems={"flex-start"}
                  >
                    <Box bg="white" width={"100%"} position={"sticky"} top={"105px"}>
                      {((filterPills && filterPills !== undefined && filterCount > 0) || filterOptions?.search) &&
                        <FilterPills
                          filters={filterPills}
                          setFilterOptions={setFilterOptions}
                          setApply={setApply}
                          setSearchTerm={setSearchTerm}
                          setFilterPills={setFilterPills}
                          filteredResultCount={filteredResultCount}
                        />
                      }</Box>
                    <InfiniteScroll
                      dataLength={data.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<Box textAlign="center" marginLeft={"50%"}>
                        <Stack marginTop={"20px"}>
                          <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                        </Stack>
                      </Box>}
                      endMessage={<NoMoreToLoad />}
                    >
                      {/* <ImageGridList
                  columns={[1, null, 4]}
                  spacing={"38px"}
                  height={"200px"}
                  data={data}
                  showArchiveImages={showArchiveImages}
                  archive={true}
                  text={true}
                /> */}
                      <Grid
                        data={data}
                        handleDrawer={handleDrawer}
                        spacing={{ base: "10px", md: "24px" }}
                        columns={{ base: 2, md: 3, lg: 4, xl: 4 }}
                        component={ArchiveGrid}
                        border={true}
                      />
                    </InfiniteScroll>
                  </Stack> :
                  <>
                    <HStack
                      onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
                      margin={"auto"}
                    >
                      <DataNotFound />
                    </HStack>
                  </>
                }

                <HStack
                  marginLeft={"4"}
                  marginRight={"0px!important"}
                  className="mt-0"
                  alignItems={"flex-start"}
                  pos={masterlistType === "generic" && "relative"}
                  right={masterlistType === "generic" && "-5px"}
                >
                  {showFilterBox && (
                    isMobile ? (
                      <Modal isOpen={showFilterBox} onClose={() => setShowFilterBox(false)} returnFocusOnClose={false}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalBody px="15px">
                            <FilterBox
                              setShowFilterBox={setShowFilterBox}
                              setApply={setApply}
                              explore={explore}
                              // facets={facets}
                              sort={sort}
                              handleCheckboxChange={handleCheckboxChange}
                              handleSearch={handleSearch}
                              filterOptions={filterOptions}
                              filteroptionData={facets}
                              filterCount={filterCount}
                              setFilterOptions={setFilterOptions}
                              setSearchTerm={setSearchTerm}
                              searchTerm={searchTerm}
                              handleReset={handleReset}
                              className="box-filter-film2" />
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    ) : (
                      <FilterBox
                        setShowFilterBox={setShowFilterBox}
                        setApply={setApply}
                        explore={explore}
                        // facets={facets}
                        sort={sort}
                        handleCheckboxChange={handleCheckboxChange}
                        handleSearch={handleSearch}
                        filterOptions={filterOptions}
                        filteroptionData={facets}
                        filterCount={filterCount}
                        setFilterOptions={setFilterOptions}
                        setSearchTerm={setSearchTerm}
                        searchTerm={searchTerm}
                        handleReset={handleReset}
                        className={masterlistType === "search" || masterlistType !== "research-categories" ? "box-filter-film2" : "box-filter-film3"}
                      />
                    )
                  )}
                </HStack>
              </Stack>
              {contentClicked && (
                <ContentModal
                  contentClicked={contentClicked}
                  setContentClicked={setContentClicked}
                  contentImage={bookData?.content_page_image}
                  masterlistType={masterlistType}
                />
              )}
              {scanClicked && (
                <SliderModal
                  scanClicked={scanClicked}
                  setScanClicked={setScanClicked}
                  sliderModalImg={bookData?.scanned_pages_image}
                  masterlistType={masterlistType}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  setSwiper={setSwiper}
                />
              )}
            </Container>
          </Stack> : (
            <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
              <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
            </HStack>
          )
      }
      {archiveFullScreenImage && tabValue === "all" &&
        <FullScreenImage
          isOpen={archiveFullScreenImage}
          onClose={(item) => handleClose(item)}
          imageData={archiveImgData}
          data={data}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="TopAuctionSales"
        />
      }
      {
        showSubscribeModal &&
        <SubscriptionModal setShowSubscribeModal={setShowSubscribeModal} />
      }
    </>
  );
};
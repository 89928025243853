// Core Files
import { Box, Image, Stack } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";

// Subsection Files
import { EffectFade, Navigation } from "swiper";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

function SliderModal({ scanClicked, setScanClicked, sliderModalImg, width ,masterlistType,pageIndex,setPageIndex,setSwiper}) {
  return (
    <Box position={"relative"} bg="white" height={"100vh"} left={masterlistType==="search"?-4:-8}>
      <Box
        position={"fixed"}
        left={"auto"}
        right={{base:"0",md:"auto"}}
        top={"64px"}
        zIndex={99999}
        background={"white"}
        ml={(masterlistType==="search" || masterlistType === "generic" || masterlistType === "research-categories") ? "-30px":"1px"}
        width={width ? width :{base:"100%",md:(masterlistType === "search" || masterlistType === "generic" || masterlistType === "research-categories") ? "924px" : "774px"}}
        height={"100%"}
        borderRight={"1px"}
        borderColor={"color.lightgray4"}
      >
        <Image
          src={closeIcon}
          paddingLeft="5px"
          position="absolute"
          top={"12px"}
          right="15px"
          cursor={"pointer"}
          zIndex={99}
          onClick={() => {
            setScanClicked(false);
            setPageIndex(0);
          }}
        />
        <Stack
          justifyContent={"space-evenly"}
          width={"100%"}
          alignItems={"flex-start"}
          // marginTop={"64px"}
          id="biblographysliderskkdrawer"
          height={{base:"auto",md:"737px"}}
          flexDirection={"row"}
        >
          <Swiper
            effect={"fade"}
            grabCursor={true}
            pointerEvents="auto"
            navigation={true}
            onSwiper={setSwiper}
            initialSlide={sliderModalImg.length<pageIndex ?sliderModalImg.length:pageIndex}
            modules={[EffectFade, Navigation]}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {sliderModalImg &&
              sliderModalImg.map((it, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Box width={"100%"} height={{base:"auto",md:"737px"}}>
                      <Image src={it} width={"100%"} height={"inherit"} objectFit={"contain"} />
                    </Box>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Stack>
      </Box>
    </Box>
  );
}

export default SliderModal;
import { locationIntroductionData } from "../MockData/Introduction";
import { baraImambara_filter_options, lucknow_bara_imamabara_data, lucknow_filter_options, lucknow_monuments_data, lucknow_the_residency_data} from "../MockData/LocationMockup";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import { transformJSON } from "../util/transformer";

import api from "../services/api";
import { APIS } from "../endpoints";
import axios from "axios";

export const getLocationLandingPageAPI = async (masterlistType,masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.masterlistAgraphySection}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

export const getLocationLandingPage = async (masterlistType,masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getLocationLandingPageDummyData(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getLocationLandingPageAPI(masterlistType,masterlist);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e)
    }
  }
};

export const getLocationLandingPageDummyData = async (masterlist) => {

  // Check if the masterlist exists in the map, if not, use a default value
  return [];
};

//introduction

export const introductionByAliasAPI = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.location}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// This function return complete Personality Introduction data
export const introductionByAlias = async (masterlistType, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getIntroductiondummydata(masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false)  {
    const response = await introductionByAliasAPI(masterlistType, masterlist);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getIntroductiondummydata = (winner) => {

  return [];
};




export const getMonumentsAPI = async (masterlistType, masterlistAlias,filterOptions,page) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);
  const result = await axios.get(`${APIS.BASE_URL}${masterlistType}/${masterlistAlias}/body-of-work/man-made-heritage`, {
    params: params
});
  if (result?.data) {
    return result?.data;
  } else {
    return [];
  }
};

const LocationMonumentschema = {}

export const getMonuments = async (masterlistType, masterlistAlias,filterOptions,page) => {
    if (isTransformationNotAllowed() === true) {
      const response = await getMonumentsDummyData(masterlistAlias);
      return response;
    } else if (isTransformationNotAllowed() === false)  {
      const response = await getMonumentsAPI(masterlistType, masterlistAlias,filterOptions,page);
      try {
        if (response) {
          return response;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    }
  };
  
  export const getMonumentsDummyData = async (masterlist) => {
    const locationMonumentsDataMap = {
        "lucknow": lucknow_monuments_data,
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const locationMonumentsData = locationMonumentsDataMap[masterlist] || lucknow_monuments_data;
      return locationMonumentsData;
  };

// Monuments Detail Page

  export const getMonumentDataByAliasAPI = async (masterlistType, masterlistAlias, monumentAlias) => {
    // http://localhost:5000/tris/api/location/lucknow/body-of-work/monument-detail/lucknow-bara-imambara-1784
    const result = await axios.get(`${APIS.BASE_URL}${masterlistType}/${masterlistAlias}/body-of-work/monument-detail/${monumentAlias}`);
    if (result?.data) {
      return result?.data?.data;
    } else {
      return [];
    }
  };

  export const getMonumentDataByAlias = async (masterlistType, masterlistAlias, monumentAlias) => {
    if (isTransformationNotAllowed() === true) {
      const response = await getMonumentDataByAliasDummyData(monumentAlias);
      return response;
    } else if (isTransformationNotAllowed() === false)  {
      const response = await getMonumentDataByAliasAPI(masterlistType, masterlistAlias, monumentAlias);
      try {
        if (response) {
          return response;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    }
  };
  
  export const getMonumentDataByAliasDummyData = async (monumentAlias) => {
    const locationMonumentsDataMap = {
        "bara-imambara": lucknow_bara_imamabara_data,
        "the-residency": lucknow_the_residency_data,
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const locationMonumentsData = locationMonumentsDataMap[monumentAlias] || lucknow_bara_imamabara_data;
      return locationMonumentsData;
  };

  // Geographical data on introdction page

  export const getGeographicalDataByAliasAPI = async (tableName, masterlist) => {
    return "";
  };

  export const getGeographicalDataByAlias = async (tableName, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const response = await getGeographicalDataByAliasDummyData(masterlist);
      return response;
    } else if (isTransformationNotAllowed() === false)  {
      const response = await getGeographicalDataByAliasAPI(tableName, masterlist);
      try {
        if (response) {
          let transformedData = transformJSON(LocationMonumentschema, true, response);
          return transformedData;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    }
  };
  
  export const getGeographicalDataByAliasDummyData = async (masterlist) => {
    const locationGeographicalDataMap = {
        "lucknow": locationIntroductionData,
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const locationGeographicalData = locationGeographicalDataMap[masterlist] || locationIntroductionData;
      return locationGeographicalData;
  };

  export const getLocationFilterOptions = async (tableName, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const FilterOptions = await getLocationFilterOptionsDummyData(
        masterlist
      );
      return FilterOptions;
    } else if (isTransformationNotAllowed() === false) {
    }
  };
  const getLocationFilterOptionsDummyData = (tableName, masterlist) => {
    const locationFilterOptionsDataMap = {
      "lucknow": lucknow_filter_options,
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const locationFilterOptionsData = locationFilterOptionsDataMap[masterlist] || lucknow_filter_options;
    return locationFilterOptionsData;
  };


  export const getMonumentFilterOptions = async (tableName, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const FilterOptions = await getMonumentFilterOptionsDummyData(
        masterlist
      );
      return FilterOptions;
    } else if (isTransformationNotAllowed() === false) {
    }
  };
  const getMonumentFilterOptionsDummyData = (tableName, masterlist) => {
    const monumentFilterOptionsDataMap = {
      "bara-imambara": baraImambara_filter_options,
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const monumentFilterOptionsData = monumentFilterOptionsDataMap[masterlist] || baraImambara_filter_options;
    return monumentFilterOptionsData;
  };



  //

  export const getNaturalHeriatgeAPI = async (tableName, masterlistAlias) => {
    return "";
  };
  
  
  export const getNaturalHeriatge = async (tableName, masterlist) => {
      if (isTransformationNotAllowed() === true) {
        const response = await getNaturalHeriatgeDummyData(masterlist);
        return response;
      } else if (isTransformationNotAllowed() === false)  {
        const response = await getNaturalHeriatgeAPI(tableName, masterlist);
        try {
          if (response) {
            let transformedData = transformJSON(LocationMonumentschema, true, response);
            return transformedData;
          } else {
            console.error("Invalid response data structure:", response);
            return [];
          }
        } catch (e) {
          console.error("Something is wrong", e);
        }
      }
    };
    
    export const getNaturalHeriatgeDummyData = async (masterlist) => {
        return [];
    };



    export const getRelatedWorkAPI = async (masterlistType, masterlistAlias,monumentAlias,filterOptions,page) => {
      const formData = new FormData();
      formData.append('filters', JSON.stringify(filterOptions));
      formData.append('page_number', page);
      const params = new URLSearchParams(formData);
      const result = await axios.get(`${APIS.BASE_URL}${masterlistType}/${masterlistAlias}/body-of-work/monument-artwork/${monumentAlias}`, {
        params: params
    });
      if (result?.data) {
        return result?.data;
      } else {
        return [];
      }
    };
    
    
    export const getRelatedWork = async (masterlistType, masterlistAlias,monumentAlias,filterOptions,page) => {
        if (isTransformationNotAllowed() === true) {
          const response = await getRelatedWorkDummyData(masterlistAlias);
          return response;
        } else if (isTransformationNotAllowed() === false)  {
          const response = await getRelatedWorkAPI(masterlistType, masterlistAlias,monumentAlias,filterOptions,page);
          try {
            if (response) {
              return response;
            } else {
              console.error("Invalid response data structure:", response);
              return [];
            }
          } catch (e) {
            console.error("Something is wrong", e);
          }
        }
      };
      
      export const getRelatedWorkDummyData = async (masterlist) => {
          // Check if the masterlist exists in the map, if not, use a default value
          return [];
      };


// Bar Graph


export const getGeographicalBarDataByAliasAPI = async (barAlias,
  masterlistType,
  aliasAPI) => {
    const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${aliasAPI}/${APIS.location}/${barAlias}/india/ma`);
    if (result) {
      return result?.data?.data;
    } else {
      return [];
    }
  };

export const getGeographicalBarDataByAlias = async (barAlias,
  masterlistType,
  aliasAPI) => {
  if (isTransformationNotAllowed() === true) {
    const response = [];
    return response;
  } else if (isTransformationNotAllowed() === false)  {
    const response = await getGeographicalBarDataByAliasAPI(barAlias,
      masterlistType,
      aliasAPI);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};



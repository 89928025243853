import { Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { Link, useParams } from 'react-router-dom';
import H2 from '../../components/layouts/Typography/H2';

function JournalDetails({journalDetails}) {
let { alias } = useParams();
const currentEndpoint = window.location.pathname;
const parts = currentEndpoint.split("/");
const masterlistType = parts[1];
  return (
    <>
    <Stack marginTop={"8px"}>
        <H2><Link to={`/${masterlistType}/${alias}`}> {journalDetails.title}</Link></H2>
        {journalDetails?.date_Range && (
          <Text
            fontSize={"14px"}
            fontWeight={"400"}
            color="#000000"
            marginTop={"3px !important"}
            lineHeight={"16px"}
            marginBottom={"10px !important"}
          >
            {journalDetails.date_Range}
          </Text>
        )}
      </Stack>
    </>
  )
}

export default JournalDetails
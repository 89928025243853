import { Box, Image, Text, Stack, HStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CopyPasteService } from "../../services/CopyPaste";
import Medium from "../../Theme/Typography/Medium";
import { getImageURL } from "../../util/getImage";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/x-small.png`;

export default function ImageOverlayGrid({ data, columns, spacing }) {
  const navigate = useNavigate();
  return (
    <>
      {data.map((item) => {
        return (
          <Box
            className={"card"}
            width={"100%"}
            height={{base:"160px",md:"185px"}}
            cursor={"pointer"}
            boxShadow={"none"}
            onClick={() => item.route_type==="generic"? navigate(`${item.navigationLink}/research-centre/all`):item.route_type==="location"?navigate(`${item.navigationLink}/agraphy/introduction`):navigate(item.navigationLink)}
          >
            <Box width={"100%"} height={{base:"130px",md:"100%"}}>
            <Image
              src={getImageURL(item.imgPath, 190, 190)}
              fallbackSrc={placeholderImg}
              height={{base:"inherit", md:"100%"}}
              objectFit={"cover"}
              width="100%"
              border={"1px"}
              borderColor={"#e8e8e8"}
              className={"img-border-radius hover14"}
            />
            </Box>
            <Box display={{base:"none",md:"block"}} textAlign={"center"} className="overlay">
              <Stack alignItems={"center"}>
                <Text fontSize={"16px"} fontWeight={"600"} className="text-p">
                  {item.title}
                </Text>
              </Stack>
            </Box>
            <Box display={{base:"block",md:"none"}}>
            <Stack py="2">
              <HStack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.to}/4`)
                }
              >
                <Medium textStyle={"secondary"} textAlign={"left"}>{item.title}</Medium>
              </HStack>
             </Stack>
            </Box>
          </Box>
        );
      })}
    </>
  );
}

import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, EffectFade, Navigation, Pagination } from "swiper";
import { common } from "../constants/constants";
import { useState } from "react";
import { isArray } from "lodash";
import Medium from "../Theme/Typography/Medium";
import { getImageURL } from "../util/getImage";
//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`
const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`;

export const FullScreenImage = ({
  isOpen,
  onClose,
  clickedImageIndex,
  data,
  setReadMoreFlag,
  bannerFlag,
  title,
}) => {

  const [expanded, setExpanded] = useState(false);
  const MAX_LENGTH = 600; // Maximum length of description to show when collapsed

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Container maxW={"1400px"} px="0px" >
      <Modal
        size={"full"}
        isCentered
        isOpen={isOpen}
        onClick={onClose}
        Id="modalfullscreen"
        motionPreset="none"
        autoFocus={false}
        returnFocusOnClose={false} 
      >
        <ModalOverlay />
        <ModalContent zIndex={"999"}>
          <ModalBody margin={{ base: "0", md: "16" }}>
            <Box
              bg={"white"}
              justifyContent={"center"}
              position={"relative"}
              display={"flex"}
            >
              <Stack maxW={{base:"100%",md:"1400px"}}>
                <Swiper
                  effect={{ sm: "fade", md: "creative" }}
                  slidesPerView={1}
                  navigation
                  initialSlide={clickedImageIndex}
                  id="allsliderskk1"
                  modules={[EffectCreative, EffectFade, Navigation, Pagination]}
                >
                  {data?.length > 0 &&
                    data?.map((item, index) => {
                      return (
                        <SwiperSlide key={index + 1}>
                          <Stack
                            maxW={{base:"100%",md:"1400px"}}
                            ml={{base:"0px",md:"12"}}
                            marginTop={{ base: "250px", md: "0" }}
                            flexDirection={{ base: "column", md: "row" }}
                            alignItems={"flex-start"}
                            gap={{base:"0",md:"8"}}
                          >
                            <HStack
                              width={{base:"auto",md:"680px"}}
                              justifyContent={"center"}
                              border={"0.01rem solid #d7d7d7"}
                              borderRadius={"5px"}
                            >
                              <Stack m="8">
                                <Box
                                  display={"block"}
                                  justifyContent={"center"}
                                  width={"100%"}
                                  height={{ base: "auto", md: "550px" }}
                                >
                                  <Image
                                    src={getImageURL(item?.fullImg)
                                    }
                                    fallbackSrc={placeholderImg}
                                    width={"100%"}
                                    height={"inherit"}
                                    objectFit={"contain"}
                                    alt=""
                                  />
                                </Box>
                              </Stack>
                            </HStack>
                            <HStack
                              alignItems={"flex-start"}
                              width={{base:"auto",md:"500px"}}
                              flexDirection={"column"}
                            >
                              <Box
                                width={"100%"}
                                borderBottom={"1px"}
                                borderColor={"#e8e8e8"}
                              >
                                <Box paddingBottom={"10px"} width={"100%"}>
                                  <Text
                                    fontSize={"20px"}
                                    fontWeight={"700"}
                                    color="#0066B3"
                                  >
                                    {item?.designerdesc}
                                  </Text>
                                </Box>
                                <Stack
                                  pb="4"
                                  marginInlineStart={"0rem!important"}
                                >
                                  {item?.designerData && item?.designerData.length > 0 &&
                                    item?.designerData.map((it,index) => (
                                      <Stack
                                        marginInlineStart={"0rem!important"}
                                        py="1"
                                        key={index}
                                        gap={"2"}
                                        className="mt-0 px-0"
                                        flexDirection={"column"}
                                      >
                                        <HStack className="mt-0 px-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"700"}
                                            color="black"
                                          >
                                            {it.display}
                                          </Text>
                                        </HStack>
                                        <HStack className="mt-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"400"}
                                          >
                                            {it.value}
                                          </Text>
                                        </HStack>
                                      </Stack>
                                    ))}
                                {item.content && (
                                <Box
                                py="2"
                                className="ml-0"
                                alignItems={"flex-start"}
                                flexDirection={"column"}
                              >
                                {isArray(item.content) ? (
                                  item.content.map((it) => (
                                    <Stack
                                      marginInlineStart={"0rem!important"}
                                      py="1"
                                      gap={"2"}
                                      className="mt-0 px-0"
                                    >
                                      <HStack className="mt-0 px-0">
                                        <Medium lineHeight={"16.44px"}>
                                          {it.display}
                                        </Medium>
                                      </HStack>
                                      {it.value && (
                                        <HStack className="mt-0">
                                         <Medium lineHeight={"16.44px"}>
                                            {it.value}
                                            </Medium>
                                        </HStack>
                                      )}
                                    </Stack>
                                  ))
                                ) : (
                                  <Box maxH={"380px"} overflowY={"scroll"}>
                                    <>
                                    <Medium lineHeight={"16.44px"}>
                                        {expanded ? item?.content : `${item?.content.substring(0, MAX_LENGTH)}...`}
                                      </Medium>

                                      <Medium
                                        pt="1"
                                        marginInlineStart={"0px!important"}
                                        fontWeight={"700"}
                                        color="color.blue"
                                        cursor={"pointer"}
                                        onClick={toggleExpanded}

                                      >
                                        {expanded ? common.READ_LESS : common.READ_MORE}
                                      </Medium>
                                    </>
                                  </Box>
                                )}
                              </Box>
                              )}
                                </Stack>
                            </Box>
                            </HStack>
                            <HStack>
                              <Image
                                 right={"3.5%"} top={"1%"} position={"absolute"}
                                src={closeIcon}
                                onClick={() => {
                                  onClose();
                                }}
                                cursor={"pointer"}
                              ></Image>
                            </HStack>
                          </Stack>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};
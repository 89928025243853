import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ImageSlider from "../../Component/ImageSlider";
import { EffectFade, EffectFlip, Navigation, Pagination } from "swiper";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { Swiper, SwiperSlide } from "swiper/react";
import { DataNotFound } from "../../../../CommonComponents/DataNotFound";

export default function Catalouge({ data }) {
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };
  return (
    <Stack position={"relative"}  minH={"60vh"}>
      {data && data?.length > 0 ? (
        <ImageSlider
          sliderData={data}
          handleImageClick={handleImageClick}
          modules={[EffectFlip, Navigation, Pagination]}
          width={{ base: "100%", md: "auto" }}
          height={"100%"}
          sheight={"511px"}
        />
      ) : (
        <Stack >
          <DataNotFound />
        </Stack>
      )}
      {fullScreenImage && (
        <ImagePopupModal
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={data}
          clickedImageIndex={clickedImageIndex}
          imageHeight={{ base: "100%", md: "90vh" }}
        />
      )}
    </Stack>
  );
}
export const ImagePopupModal = ({
  isOpen,
  onClose,
  imageData,
  data,
  clickedImageIndex,
  imageHeight,
}) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"99999"}>
        <ModalBody>
          <Box bg="white" mx={{ base: "0", md: "10" }}>
            <Stack>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                // loop={true}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {data?.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                      >
                        <Image
                          src={item?.imgPath}
                          height={imageHeight ? imageHeight : "auto"}
                          alt=""
                        />
                        <HStack paddingTop="10px">
                          <Text
                            pt={"8px"}
                            fontSize={"15px"}
                            fontWeight={"400"}
                            lineHeight={"17px"}
                            color="#035DA1"
                          >
                            {item.newDesc}
                          </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        // position={"relative"}
                        // justifyContent="flex-end"
                        position="absolute"
                        right="00"
                        top={{ base: "80%", md: "95%" }}
                        marginRight={{ base: "0px", md: "160px" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={"30px"}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={{ base: "1", md: "0.6" }}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          onClick={onClose}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

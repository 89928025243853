import {
  Box,
  useColorModeValue,
  useDisclosure,
  Stack,
  Container,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";

//Service file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component file
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import GridView from "./BodyOfWork/MonumentsAndHeritage/GridView";

//Language file
import {explore, location } from "../../constants/constants";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
import NaturalHeriatge from "./BodyOfWork/NaturalHeriatge/NaturalHeriatge";
import DetailPage from "./BodyOfWork/MonumentsAndHeritage/DetailPage";

export default function BodyOfWork({ children }) {
  const { onClose } = useDisclosure();
  const [introData, setIntroData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "",
        aliasAPI
      );
      if (result) {
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);
        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <Container maxW={"1400px"} px="0px">
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          zIndex={"99999"}
          height="calc(100vh - 64px)"
          aliasAPI={aliasAPI}
          journalDetails={journalDetails}
          introData={introData}
          masterlistType={masterlistType}
          name={name}
        />
        <Box ml={{ base: 0, md: 52 }} bg="white">
          {children}
          <BodyOfWorkBody aliasAPI={aliasAPI} masterlistType={masterlistType} />
        </Box>
      </Box>
    </Container>
  );
}
const SidebarContent = ({ 
  onClose,
  aliasAPI,
  journalDetails,
  introData,
  masterlistType,
   ...rest }) => {

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        journalDetails={journalDetails}
        handleNavigation={handleNavigation}
        leftFlag={true}
      />
    </>
  );
};
export const BodyOfWorkBody = ({aliasAPI,masterlistType}) => {
  // const [modalcard, setModalcard] = useState(false);
  const [tabPanelData, setTabPanelData] = useState([]);
  const {tabValue,monumentAlias}=useParams();
  const [attr, setAttr] = useState(0)
  const pathToIndex = {
    'man-made-heritage': 0,
    'natural-heritage': 1,
    'cultural-landmark':2

  };
  const locations = useLocation();  
  useEffect(() => {
    setAttr(pathToIndex[tabValue] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, attr]);
  function formatData() {
    return [
      {
        name: `${location.MONUMNETS_AND_HERITAGE}`,
        component: <GridView aliasAPI={aliasAPI} />,
        link:`/${masterlistType}/${aliasAPI}/agraphy/body-of-work/man-made-heritage`

      },
      {
        name: `${location.NATURAL_HERITAGE}`,
        component: <NaturalHeriatge aliasAPI={aliasAPI} />,
        link:`/${masterlistType}/${aliasAPI}/agraphy/body-of-work/natural-heritage`

      },
      {
        name: `${location.CULTURAL_LANDMARK}`,
        component: <WebpageComingSoon aliasAPI={aliasAPI} />,
        link:`/${masterlistType}/${aliasAPI}/agraphy/body-of-work/cultural-landmark`

      }
    ];
  }
  useEffect(() => {
    if (aliasAPI) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasAPI]);
  return (
  <>
   {monumentAlias ? 
        <DetailPage/>
        :
    <Stack height={"100%"}>
      <Container maxW="auto" px={{ base: "15px", md: "64px" }} py={{ base: "15px", md: "0px" }}>
        <Stack>
                {tabPanelData && tabPanelData?.length > 0 && (
            <TabMain paddingTop="5px" width={{base:"100%",md:"1055px"}} data={tabPanelData} attr={attr} setAttr={setAttr}/>
          )}
        </Stack>
      </Container>
    </Stack>}
  </>
  );
};
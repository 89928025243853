import {
    Text,
  } from "@chakra-ui/react";
  import React from "react";
  
  export default function AccordianText3({data,width="100%", py="0", textAlign="justify", fontSize="14px", fontWeight="500", lineHeight="20px"}) {
    return (
      <>
        {data && data?.length>0 && data?.map((item,index)=>{
            return(
                <Text
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                  color="black"
                  lineHeight={lineHeight}
                  textAlign={textAlign}
                  width={width}
                  py={py}
                  pl={3}
                  key={index}
                >
                  {item}
                </Text>
              )
        })
             
          }
      </>
    );
  }
  
import { Box, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { common } from "../../constants/constants";
import { getImageURL } from "../../util/getImage";
//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

export default function ImageWithCitation2({
  data,
  handleImageClick,
  fontSize="14px",
  lineHeight="17px"
}) {
  return (
    <>
      {data.length > 0 ? (
        data?.map((item, index) => (
          <Box height="auto" key={index}>
            <Box
              justifyItems="center"
              display="flex"
              height={"270px"}
              bg="white"
              justifyContent="space-around"
            >
              {item.link ? (
                <Image
                  src={getImageURL(item?.imagePath ? item?.imagePath : item?.image, '243', '270')}
                  fallbackSrc={placeholderImg}
                  alt=""
                  cursor={"pointer"}
                  transition="1s ease"
                  width={"auto"}
                  objectFit={"contain"}
                  onClick={() => handleImageClick(item, index)}
                />
              ) : (
                <Image
                  src={getImageURL(item?.image, '243', '270')}
                  fallbackSrc={placeholderImg}
                  alt=""
                  cursor={"pointer"}
                  transition="1s ease"
                  width={"auto"}
                  objectFit={"contain"}
                  onClick={() => handleImageClick(item, index)}
                />
              )}
            </Box>
            
            <Text
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"18.78px"}
              textAlign="left"
              color="#035DA1"
              py="4"
              cursor={"pointer"}
              onClick={() => handleImageClick(item, index)}
            >
              {item?.desc && item?.desc}
              {item?.artist && `${item?.artist?.full_name} | ${item?.title_of_artwork?.value} | ${item?.medium_artwork?.value} | ${item?.medium_artwork?.date_of_artwork}`}
            </Text>
          </Box>
        ))
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text
            py={"40"}
            fontSize={"45px"}
            fontWeight="700"
            textAlign="center"
          >
            {common.NO_DATA}
          </Text>
        </Stack>
      )}
    </>
  );
}

import {
  Box,
  Container,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useRef, useState } from "react";
import { TbFoldersOff } from "react-icons/tb";
import { useParams } from "react-router-dom";

//Service files
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getExhibitions from "../../../services/PersonArtist";

//Component files
import { FilterButton } from "../../../CommonComponents/FilterButton";
// import CommonDrawer from "../../Film/BodyOfWork/Components/CommonDrawer";
// import { isTransformationNotAllowed } from "../../../util/isTranformationAllowed";
import TimeLine from "../../../FunctionalComponents/TimeLine/TimeLine";
import TimelineWithUserIocn from "../../../FunctionalComponents/TimeLine/TimelineWithUserIocn";

//Language files
import { common, explore } from "../../../constants/constants";
import PersonArtistCatlouge from "./PersonArtistCatlouge";
import { FilterBox } from "../../../FunctionalComponents/Filter/FilterBox";
import { getImageURL } from "../../../util/getImage";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Navigation, Pagination } from "swiper";
import NoMoreToLoad from "../../../CommonComponents/NoMoreToLoad";
import InfiniteScroll from "react-infinite-scroll-component";
import { totalFilterCount, updateFacetsData } from "../../../util/helper";
import FilterPills from "../../../components/FilterPills/FilterPills";
import dateFormat from "dateformat";
import { DataNotFound } from "../../../CommonComponents/DataNotFound";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

function TabularView({ tableName, masterlist,masterlistType,setExhibitionsCount }) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [filteroptionData] = useState({});
  // const [openDrawer, setOpenDrawer] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    search: "",
    filters: {},
    gate:"AND"
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [catalogueData, setCatalogueData] = useState(null);
  const {aliasProp}=useParams();
  const [iconClicked, setIconClicked] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [facets, setFacets] = useState([]);
  const [sort, setSort] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [apply, setApply] = useState(false);
  const initialLoadComplete = useRef(false);
  // const [drawerBook, setDrawerBook] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [filterPills, setFilterPills] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches",searchTerm);
  };

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";  
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
        case "gate":
          selectedString = filterOptions.gate;
          break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }
  
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
  
    if (selectedString !== type) {
      selectedString = type;
    }
  
    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
        case "gate":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          gate: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };

  const showImage = (index) => {
    swiper.slideTo(index);
    setTimeout(() => {
      setIconClicked(!iconClicked);
    }, 500);
  };

  // const drawerFormation = (data) => {
  //   return {
  //     name: ` ${formatName(masterlist)} & ${data?.mainTitle}`,
  //     links: [
  //       {
  //         link_name: explore.EXHIBITIONS,
  //         link: ``,
  //       },
  //       {
  //         link_name: common.BOOKS,
  //         link: ``,
  //       },
  //       {
  //         link_name: `Artworks`,
  //         link: ``,
  //       },
  //       {
  //         link_name: `Posts`,
  //         link: ``,
  //       },
  //     ],
  //     Image: data?.Image,
  //     desc: "",
  //     masterlist: {
  //       text: `Visit ${data?.mainTitle} Masterlist →`,
  //       link: `/generic/${data?.alias}`,
  //     },
  //   };
  // };
  // const findItem = (items, key) => items.find(it => it.key === key) || {};
  const [imageData, setImageData] = useState([]);

  const citationFactor = (data) => {
    const images = []; // Create an array to collect images
    const isValidDate = (date) => {
      const parsedDate = new Date(date);
      return !isNaN(parsedDate.getTime());
    };

    const result = data.map((item) => {
      
      const startDate = item?.start_date 
        ? isValidDate(item?.start_date) 
          ? dateFormat(item?.start_date, "d mmm yyyy") 
          : item?.start_date // Use direct value if invalid
        : "";
      
      const endDate = item?.end_date 
        ? isValidDate(item?.end_date) 
          ? dateFormat(item?.end_date, "d mmm yyyy") 
          : item?.end_date // Use direct value if invalid
        : "";

      const city = item?.city_of_event || "";
      //direct_url_of_cover_image is what we are using but data is coming from cover_image key
      const fullImage = item?.cover_image || "";
      const group = item?.type_of_event || item?.type_of_exhibition || "";
    
      // Collect the images
      images.push(fullImage);
    
      return {
        desc: item?.bibliographic_caption || "",
        year: item?.year_of_event || item?.sortable_year || "",
        shortdesc: [
          startDate && endDate ? `${startDate} to ${endDate}` : startDate || endDate,
          city
        ]
          .filter(Boolean) // Filters out null, undefined, or empty values
          .join(", "),
        image: fullImage,
        link: '',
        group: group,
        catalogue: true,
        catalogueTitle: item?.bibliographic_caption,
        exhibition_centre: '',
        mainTitle: item?.title || "",
        alias: item?.alias || "",
      };
    });
    
    // Update the state with all images at once
    setImageData([...imageData, ...images]);
    
    return result; // Return the mapped data
    
  };
  async function fetchData() {
    try {
      setLoading(true);
      const result = await getExhibitions.getExhibitionsDataByMasterlist(masterlistType, masterlist, filterOptions, 1);
        setLoading(false);
      if (result?.data?.length > 0) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        if (!initialLoadComplete.current) {
          setPage((prevPage) => prevPage + 1);
          initialLoadComplete.current = true;
        } 

        const formattedFacets = updateFacetsData(result);

        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        setFacets(formattedFacets);
        setExhibitionsCount(result?.total);
        if (formattedFacets && formattedFacets.length > 0) {
          setFilterOptions((prevFilterOptions) => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              ...formattedFacets.reduce((acc, facet) => {
                acc[facet.key] = [];
                return acc;
              }, {}),
            },
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setData([]);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = async () => {
    try {
      const result = await getExhibitions.getExhibitionsDataByMasterlist(masterlistType, masterlist, filterOptions, page);

      if (result) {
        const processedData = citationFactor(result?.data);
        setData((prevData) => [...prevData, ...processedData]);
        setPage((prevPage) => prevPage + 1);

        const formattedFacets = updateFacetsData(result);

        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        setFacets(formattedFacets);
        if (data.length >= result?.total) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function fetchFilterData(page=1) {
    try {
      const result = await getExhibitions.getExhibitionsDataByMasterlist(masterlistType, masterlist, filterOptions, page);


      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);

        const formattedFacets = updateFacetsData(result);
        setFacets(formattedFacets);

        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });

        const filtercnt= totalFilterCount(filterOptions?.filters)
        
        if(filtercnt > 0 || filterOptions?.search)
        {
          setFilteredResultCount(result?.total)
        }
        else
        {
          setFilteredResultCount(0);
        }

        setSort(sortedFacets);
        if (data.length >= result?.total) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    if (apply) {
      setApply(false);
      fetchFilterData();
      setFilterPills(filterOptions)
    }
    const filtercnt = totalFilterCount(filterOptions?.filters)
    setFilterCount(filtercnt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);


  useEffect(() => {}, [aliasProp]);

  const handleReset = () => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setSearchTerm("");
  }
  // const handleDrawer = (item) => {
  //   setOpenDrawer(true);
  //   // drawerFormation(item)
  //   if (item) setDrawerBook(drawerFormation(item));
  //   // navigate(`${item?.alias}`);
  // };
  const {innerTab}=useParams();
  return (
    <>
      {!loading && data?.length !== 0 ? (
        <>
        {filterPills &&  filterPills!==undefined && filterCount>0&&
              <FilterPills
              filters={filterPills}
              setFilterOptions={setFilterOptions}
              setApply={setApply}
              setFilterPills={setFilterPills}
              filteredResultCount={filteredResultCount}
              />
        }
          {innerTab ? (
            <>
            <PersonArtistCatlouge data={catalogueData} masterlistType={masterlistType} masterlist={masterlist} innerTab={innerTab} />
            </>
          ) : (
            <Stack flexDirection={"row-reverse"} justifyContent="space-between">
              {!showFilterBox && <FilterButton marginRight={"-65px!important"} onClick={handleFilterClick} />}
              {data && data?.length !== 0 ? (
                <Container
                  position={"relative"}
                  maxW="auto"
                  className=""
                  px="0px"
                  paddingTop={{ base: "40px", md: "0px" }}
                >
                  <Stack
                    flexDirection={{ base: "column", md: "row" }}
                    justifyContent="space-between"
                    gap={1}
                    minH={{ base: "100vh", md: "100vh" }}
                  >
                    <HStack
                      flexDirection={"column"}
                      flex="1"
                      onCopy={(e) => CopyPasteService(e, window.location.href)}
                    >
                       {/* <CommonDrawer
                        setDrawerOpen={setOpenDrawer}
                        drawerOpen={openDrawer}
                        data={drawerShatranjkeKhilari}
                      /> */}
                      {/* {drawerBook &&<CommonDrawer
                    setDrawerOpen={setOpenDrawer}
                    drawerOpen={openDrawer}
                    data={drawerBook}
                  />} */}
                      {data.length > 0 ?
                    <InfiniteScroll
                      dataLength={data.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<Box textAlign="center" marginLeft={"50%"}>
                        <Stack marginTop={"20px"}>
                          <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                        </Stack>
                      </Box>}
                      endMessage={<NoMoreToLoad/>}
                    >
                      <TimeLine
                        data={data}
                        // setOpenDrawer={setOpenDrawer}
                        setCatalogue={setCatalogueData}
                        component={TimelineWithUserIocn}
                        showImage={showImage}
                        // handleDrawer={handleDrawer}
                        marginBottom={"5px"}
                      />
                    </InfiniteScroll>
                    : (
                      <HStack w={"100%"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          w={"inherit"}
                          justifyContent={"right"}
                          gap={4}
                        >
                        {showFilterBox && (
                          <FilterBox
                          setShowFilterBox={setShowFilterBox}
                          setApply={setApply}
                          explore={explore}
                          apply={apply}
                          handleCheckboxChange={handleCheckboxChange}
                          handleSearch={handleSearch}
                          filterOptions={filterOptions}
                          filteroptionData={filteroptionData}
                          setFilterOptions={setFilterOptions}
                          setSearchTerm={setSearchTerm}
                          filterCount={filterCount}
                          searchTerm={searchTerm}
                          handleReset={handleReset}
                          className="common-box-filter"
                        />
                        )}
                          <TbFoldersOff size={40} />
                          <Text
                            py={"40"}
                            fontSize={"30px"}
                            fontWeight="700"
                            textAlign="center"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            {common.NO_DATA}
                          </Text>
                        </Box>
                      </HStack>
                    )}
                      {/* <TimeLine
                        data={FilterData}
                        setOpenDrawer={setOpenDrawer}
                        setCatalogue={setCatalogueData}
                        component={TimelineWithUserIocn}
                        showImage={showImage}
                      /> */}
                    </HStack>
                    <HStack
                            px={"0px"}
                            width={{ base: "300px", md: "350px" }}
                            alignItems={"flex-start"}
                          >
                            {!showFilterBox && (
                              <Stack
                                className="col-12 mt-0"
                                id="allsliderskkAward"
                                marginLeft={"-40px!important"}
                                position="sticky"
                                top={"150px"}
                              >
                                <HStack
                                  className={
                                    !iconClicked
                                      ? "imageZoomOut"
                                      : "imageZoomIn"
                                  }
                                >
                                  <Swiper
                                    grabCursor={true}
                                    navigation={true}
                                    modules={[
                                      EffectFlip,
                                      Navigation,
                                      Pagination,
                                    ]}
                                    onSwiper={setSwiper}
                                  >
                                  {imageData && imageData?.length>0 && imageData?.map((item,index)=>(
                                    <SwiperSlide>
                                      <Box
                                        height={{ base: "352px", md: "352px" }}
                                        width={{ base: "100%", md: "280px" }}
                                        border={"1px solid #f1f1f1"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        textAlign={"center"}
                                        display={"flex"}
                                      >
                                        <Image
                                          bg={""}
                                          src={getImageURL(item)}
                                          fallbackSrc={placeholderImg}
                                        />
                                        {/* <Text
                                          fontSize={"14px"}
                                          fontWeight={"700"}
                                        >
                                          {common.IMAGE_AWAITED}
                                        </Text> */}
                                      </Box>
                                    </SwiperSlide>))}
                                  </Swiper>
                                </HStack>
                              </Stack>
                            )}
                          </HStack>
                    <HStack
                      alignItems={"flex-start"}
                      marginRight={"-63px!important"}
                      marginTop={"0px!important"}
                    >
                      {showFilterBox && (
                        <FilterBox
                        setShowFilterBox={setShowFilterBox}
                        setApply={setApply}
                        explore={explore}
                        handleCheckboxChange={handleCheckboxChange}
                        handleSearch={handleSearch}
                        filterOptions={filterOptions}
                        filteroptionData={facets}
                        setFilterOptions={setFilterOptions}
                        setSearchTerm={setSearchTerm}
                        searchTerm={searchTerm}
                        handleReset={handleReset}
                        filterCount={filterCount}
                        sort={sort}
                        className="common-box-filter"
                      />
                      )}
                    </HStack>
                  </Stack>
                </Container>
              ) : (
                <Container
                  position={"relative"}
                  maxW="auto"
                  px="0px"
                  className="songs"
                  mt={"0px !important"}
                >
              <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                {filterPills &&  filterPills!==undefined && filterCount>0&&
              <FilterPills
              filters={filterPills}
              setFilterOptions={setFilterOptions}
              setApply={setApply}
              setFilterPills={setFilterPills}
              />
              }
              </Box>
                  {" "}
                  <Stack
                    flexDirection={"row"}
                    justifyContent="space-between"
                    height={"100vh"}
                    gap={4}
                  >
                    <HStack
                      flexDirection={"column"}
                      flex="1"
                      onCopy={(e) => CopyPasteService(e, window.location.href)}
                    >
                      {" "}
                      <Text
                        py={"40"}
                        fontSize={"45px"}
                        fontWeight="700"
                        textAlign="center"
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <TbFoldersOff style={{ marginRight: "20px" }} />
                        {common.NO_DATA}
                      </Text>
                    </HStack>
                    <HStack
                      alignItems={"flex-start"}
                      marginRight={"-40px!important"}
                      marginTop={"0px!important"}
                    >
                      {showFilterBox && (
                         <FilterBox
                         setShowFilterBox={setShowFilterBox}
                         setApply={setApply}
                         explore={explore}
                         handleCheckboxChange={handleCheckboxChange}
                         handleSearch={handleSearch}
                         filterOptions={filterOptions}
                         filteroptionData={filteroptionData}
                         setFilterOptions={setFilterOptions}
                         setSearchTerm={setSearchTerm}
                         searchTerm={searchTerm}
                         handleReset={handleReset}
                         className="common-box-filter"
                       />
                      )}
                    </HStack>
                  </Stack>
                </Container>
              )}
            </Stack>
          )}
        </>
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
            <DataNotFound/>
        </Stack>
      )}
    </>
  );
}
export default TabularView;

import React, { useEffect, useState } from "react";
import {
  HStack,
  Stack,
  Image,
} from "@chakra-ui/react";

// Core files
import { useNavigate } from "react-router-dom";
// Service Files
import * as getArchiveData from "../../services/Archive";

// Subsection Files
import { explore } from "../../constants/constants";
import { useParams } from "react-router";
import { createDataObject, totalFilterCount, useQuery } from "../../util/helper";
import TabularView from "./Archive/TabularView";
import Gridview from "./Archive/Gridview";
import SortBy from "./Components/SortBy";
import { FullScreenImage } from "../FullScreen/AuctionFullScreen";
import { updateFacetsData } from "../../util/helper";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export const Archive = ({ tableName, masterlist, masterlistType, setArchiveCount,visible, filerStyle }) => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState({ search: "", filters: {}, sortBy_v: "sortable_year",gate:"AND" });
  const [apply, setApply] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [archiveFullScreenImage, setArchiveFullScreenImage] = useState(false);
  const [archiveImgData, setArchiveImgData] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { alias, bookAlias } = useParams();
  const [filterCount, setFilterCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();
  const filterParams = query.get("filter");
  const[firstTimeLoaded,setFirstTimeLoaded]=useState(false)
  const [filterPills, setFilterPills] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

let filterParamsObj = null;

if (filterParams && filterParams?.length)
  {
    try {
      filterParamsObj = JSON.parse(filterParams);
    } catch (error) {
      console.log("filter Params parsing error", error)
    }
  }
  const navigate = useNavigate()
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  //  Filter Execution starts
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches", e.target.value)
  };

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";

    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
        case "gate":
          selectedString = filterOptions.gate;
          break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }

    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }

    if (selectedString !== type) {
      selectedString = type;
    }

    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
        case "gate":
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            gate: selectedString,
          }));
          break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };

  // const createDataObject = (dataArray) => {
  //   const dataObject = {};
  //   if (dataArray.length > 0) {
  //     const dataItem = dataArray[0];
  //     for (const key in dataItem) {
  //       dataObject[key] = dataItem[key];
  //     }
  //   }
  //   return dataObject;
  // };
  const citationFactor = (data) => {
    return data.map((item, index) => 
    {
      const title = createDataObject([item?.find((nestedItem) => nestedItem?.key === "title") ]);
      const medium = createDataObject([item?.find((nestedItem) => nestedItem?.key === "medium_artwork") ]);
      const lot_number = createDataObject([item?.find((nestedItem) => nestedItem?.key === "lot_number") ]);
      const auction_house = createDataObject([item?.find((nestedItem) => nestedItem?.key === "auction_house_name") ]);
      const venue_location = createDataObject([item?.find((nestedItem) => nestedItem?.key === "venue") ]);
      const date_of_auction = createDataObject([item?.find((nestedItem) => nestedItem?.key === "auction_year_of_event") ]);
      const type_of_auction = createDataObject([item?.find((nestedItem) => nestedItem?.key === "type_of_auction") ]);

      const number_of_bids_received = createDataObject([
        item?.number_of_bids_received,
      ]);

      const percentage_over_under_mid_estimate = createDataObject([
        item?.percentage_over_under_mid_estimate,
      ]);

      const artwork_square_inch_rate = createDataObject([
        item?.artwork_square_inch_rate,
      ]);

      const previous_auction_sales = createDataObject([
        item?.previous_auction_sales,
      ]);

      const cagr_based_on_previous_sale = createDataObject([
        item?.cagr_based_on_previous_sale,
      ]);
      const size = createDataObject([item?.size]);

      return {
      image: item?.find((nestedItem) => nestedItem?.key === "direct_url_of_cover_image")?.value || item?.find((nestedItem) => nestedItem?.key === "direct_url_of_preview_image")?.value || "",
      title: item?.find((nestedItem) => nestedItem?.key ===
        "bibliographic_citation")?.value ||
        item?.find((nestedItem) => nestedItem?.key ===
          "bibliograhpic_citation")?.value ||
        "",
        listDesc:item?.find((nestedItem) => nestedItem?.key ===
        "bibliographic_caption")?.value ||
        item?.find((nestedItem) => nestedItem?.key ===
          "bibliograhpic_caption")?.value ||
        "",
        publicationYear:item?.find((nestedItem) => nestedItem?.key ===
        "sortable_year")?.value || "N.D",
      imagedesc: item?.find((nestedItem) => nestedItem?.key ===
        "bibliographic_caption")?.value || "",
      alias: item?.find((nestedItem) => nestedItem?.key ===
        "alias")?.value,
      index: index,
      fullImageTitle:item?.find((nestedItem) => nestedItem?.key ===
      "bibliographic_caption")?.value ||
      item?.find((nestedItem) => nestedItem?.key ===
        "bibliograhpic_caption")?.value ||
      "",
      essayistData: [
        title,
        medium,
        lot_number,
        auction_house,
        venue_location,
        date_of_auction,
        type_of_auction,
        size,
      ],
      auctionData: [
        number_of_bids_received,
        percentage_over_under_mid_estimate,
        artwork_square_inch_rate,
        previous_auction_sales,
        cagr_based_on_previous_sale,
      ],
    }
  }
  );
  };
  const [facets, setFacets] = useState([]);
  const [sort, setSort] = useState([]);

  async function fetchData() {
    try {
      setIsLoading(true);
      const result = await getArchiveData.getArchiveImages(masterlistType, alias, filterOptions, page);
      setIsLoading(false);
      if (result && result?.data.length > 0 && filterParamsObj===null) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        setPage(prevPage => prevPage + 1);
        setArchiveCount(result?.total)
        if (result.total === result?.data.length) {
          setHasMore(false)
        }
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        
          if (formattedFacets && formattedFacets.length > 0) {
            setFilterOptions((prevFilterOptions) => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                ...formattedFacets.reduce((acc, facet) => {
                  acc[facet.key] = [];
                  return acc;
                }, {}),
              },
            }));
          }
        }
        
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
      }
      if(result && filterParamsObj){
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
      fetchData();
      setFilterPills(filterOptions)
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(()=>{
    if (!firstTimeLoaded)
      {
        if (filterParamsObj)
        {
          setFilterOptions(filterParamsObj);
          setSearchTerm(filterParamsObj?.search)
          setApply(true)
        }
        setFirstTimeLoaded(true);  
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filterParamsObj])
  const fetchMoreData = async () => {
    try {
      const result = await getArchiveData.getArchiveImages(masterlistType, alias, filterOptions, page);

      if (result) {

        const processedData = citationFactor(result?.data);
        setData(prevData => [...prevData, ...processedData]);
        setPage(page + 1);
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);

        if (data.length >= result?.total) {
          setHasMore(false);
        }

      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function fetchFilterData(page = 1) {

    try {
      setIsLoading(true);
      const result = await getArchiveData.getArchiveImages(masterlistType, alias, filterOptions, page) || {};
      setIsLoading(false);

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        setPage(prev=>prev+1)
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        if (result?.data?.length >= result?.total) {
          setHasMore(false);
        }
        const filtercnt= totalFilterCount(filterOptions?.filters)
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }
        if (result?.facetsHeaders && result?.facets) {

          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);

    }
  }
  useEffect(() => {

    if (apply) {
      setApply(false);
      setHasMore(true);
      setPage(1)
      fetchFilterData();
      setFilterPills(filterOptions)

    }
    const filtercnt = totalFilterCount(filterOptions?.filters)
    setFilterCount(filtercnt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const showArchiveImages = (image, item, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setArchiveFullScreenImage(flag);
      setArchiveImgData(image);
      if (!window.location.pathname.includes(item?.alias)) {
        navigate(item?.alias)
      }
    } else {
      setArchiveFullScreenImage(flag);
      setArchiveImgData(null);
    }

  };

  const handleReset = () => {
    setFilteredResultCount(0);
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setFilterCount(0)
    setSearchTerm("");
  }
  //modal for archive
  useEffect(() => {
    if (data && bookAlias) {
      const item = data.find((it) => it.alias === bookAlias);
      if (item !== undefined && window.location.pathname.includes('archive')) {
        showArchiveImages(item?.image, item, true, item?.index);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  }, [data, bookAlias]);

  return (
    <>
      {/* <Stack flexDirection={"row-reverse"} height={"100%"} marginTop={"30px"}>
        {!showFilterBox && <FilterButton masterlistType={masterlistType} onClick={handleFilterClick} />}
        <Container maxW={"auto"} py="8" pr={0}>
          <Stack flexDirection={"row"} justifyContent="flex-start" gap={{ base: 0, md: 12 }}>
            {data && data.length > 0 ?
              <HStack>
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<Box textAlign="center" marginLeft={"50%"}>
                    <Stack marginTop={"20px"}>
                      <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                    </Stack>
                  </Box>}
                  endMessage={<NoMoreToLoad />}
                >
                  <ImageGridList
                    columns={[1, null, 4]}
                    spacing={"38px"}
                    height={"200px"}
                    data={data}
                    showArchiveImages={showArchiveImages}
                    archive={true}
                    text={true}
                  />
                </InfiniteScroll>
              </HStack> :
              <>
                <HStack
                  onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
                  margin={"auto"}
                >
                  <DataNotFound />
                </HStack>
              </>
            }

            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
              pos={masterlistType === "generic" && "relative"}
              right={masterlistType === "generic" && "-5px"}
            >
              {showFilterBox && (
                <FilterBox
                  setShowFilterBox={setShowFilterBox}
                  setApply={setApply}
                  explore={explore}
                  facets={facets}
                  sort={sort}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                  filteroptionData={facets}
                  filterCount={filterCount}
                  setFilterOptions={setFilterOptions}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  handleReset={handleReset}
                  className="box-filter-film"
                />
              )}
            </HStack>
          </Stack>
        </Container>
      </Stack> */}
      {!isLoading ? 
        <Stack position={"relative"}>
          
          {sort && sort?.length>0 &&  
          <SortBy
          sort={sort}
          masterlistType={masterlistType}
          filterOptions={filterOptions}
          handleCheckboxChange={handleCheckboxChange}
          setApply={setApply}
          />
          }
      {visible ? (
        <>
          <Gridview
           showFilterBox={showFilterBox}
           masterlistType={masterlistType}
           setFilterPills={setFilterPills}
           handleFilterClick={handleFilterClick}
           data={data}
           filterPills={filterPills}
           fetchMoreData={fetchMoreData}
           hasMore={hasMore}
           showArchiveImages={showArchiveImages}
           setShowFilterBox={setShowFilterBox}
           setApply={setApply}
           explore={explore}
           facets={facets}
           sort={sort}
           handleCheckboxChange={handleCheckboxChange}
           handleSearch={handleSearch}
           filterOptions={filterOptions}
           filteroptionData={facets}
           filterCount={filterCount}
           setFilterOptions={setFilterOptions}
           setSearchTerm={setSearchTerm}
           searchTerm={searchTerm}
           handleReset={handleReset}
           filerStyle={filerStyle}
           filteredResultCount={filteredResultCount}
          />
        </>
      ) : (
        <>
          <TabularView
            showFilterBox={showFilterBox}
            masterlistType={masterlistType}
            handleFilterClick={handleFilterClick}
            data={data}
            filterPills={filterPills}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            showArchiveImages={showArchiveImages}
            setShowFilterBox={setShowFilterBox}
            setApply={setApply}
            explore={explore}
            facets={facets}
            sort={sort}
            handleCheckboxChange={handleCheckboxChange}
            handleSearch={handleSearch}
            filterOptions={filterOptions}
            filteroptionData={facets}
            filterCount={filterCount}
            setFilterOptions={setFilterOptions}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            handleReset={handleReset}
            filerStyle={filerStyle}
            setFilterPills={setFilterPills}
            filteredResultCount={filteredResultCount}
          />
          
        </>
      )}
      </Stack>
      :
      (
        <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
            <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
        </HStack>
      )
    }
           {archiveFullScreenImage && 
      <FullScreenImage
          isOpen={archiveFullScreenImage}
          onClose={() => {
            showArchiveImages(null, false)
            navigate(masterlistType==='generic' ?`/${masterlistType}/${masterlist}/research-centre/archive`:`/${masterlistType}/${masterlist}/agraphy/research-centre/archive`);

          }}
          imageData={archiveImgData}
          data={data}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="TopAuctionSales"
        />
      }

       
    </>
  );
};

import {
  Container,
  HStack,
  Stack,
} from "@chakra-ui/react";

// Core files
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Language files
import TabMain from "../../../../FunctionalComponents/TabComponents/TabMain";
import { osiansCinefan } from "../../../../constants/constants";
import SeminarsAndConferences from "./Programmes/SeminarsAndConferences";
import TalentCampusAndWorkshops from "./Programmes/TalentCampusAndWorkshops";
import Auctions from "./Programmes/Auctions";
import Screenings from "./Programmes/Screenings";
import { useLocation, useParams } from "react-router-dom";

function Programmes({ winnerName, setReadMoreFlag, readmoreNavigation, masterlistType }) {
  const [visible, setVisible] = useState(true);
  const [listGridIcon, setListGridIcon] = useState(true);
  const { t } = useTranslation();
  const [tabPanelData, setTabPanelData] = useState([]);
  const {childTab}=useParams();
  const [attr, setAttr] = useState(0)
  const [screeeningCount, setScreeeningCount] = useState(0)
  const pathToIndex = {
    'screening': 0,
    'seminar-conference': 1,
    'talent-campus': 2,
    'auctions': 3,


  };
  const location = useLocation();
  // const width = { base: "100%", md: "1160px" };

  useEffect(() => {
    setAttr(pathToIndex[childTab] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);
  function formatData() {
    if(childTab==="screening"){
      setListGridIcon(true)
    } return [
      {
        name: t(osiansCinefan.SCREENINGS),
        component: <Screenings  masterlistType={masterlistType} setScreeeningCount={setScreeeningCount} masterlist={winnerName} visible={visible}/>,
        list: true,
        count:`${screeeningCount}`,
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/overview/programmes/screening`

      },
      {
        name: t(osiansCinefan.SEMINARS_AND_CONFERENCES),
        component: (
          <SeminarsAndConferences
            winnerName={winnerName}
            setReadMoreFlag={setReadMoreFlag}
            readmoreNavigation={readmoreNavigation}
          />
        ),
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/overview/programmes/seminar-conference`,
        list: false,
      },
      {
        name: t(osiansCinefan.TALENT_CAMPUS_AND_WORKSHOPS),
        component: (
          <TalentCampusAndWorkshops
            winnerName={winnerName}
            setReadMoreFlag={setReadMoreFlag}
            readmoreNavigation={readmoreNavigation}
          />
        ),
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/overview/programmes/talent-campus`,
        list: false,
      },
      {
        name: t(osiansCinefan.AUCTIONS),
        component: (
          <Auctions
            winnerName={winnerName}
            setReadMoreFlag={setReadMoreFlag}
            readmoreNavigation={readmoreNavigation}
          />
        ),
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/overview/programmes/auctions`,
        list: false,
      },
    ];
  }

  const tabHandle = (item) => {
    setListGridIcon(item);
  };

  useEffect(() => {
    if (winnerName) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winnerName, visible,screeeningCount]);

  return (
    <Container minW="100%" pb="4" paddingX={{ base: "4", md: "0px" }}>
      <Stack>
        <HStack>
          {tabPanelData && tabPanelData.length > 0 && (
            <TabMain
              pos="fixed"
              width="1070px"
              data={tabPanelData}
              listId="listgridbutton3"
              tabTop={{ base: "55px", sm: "75px", md: "109px" }}
              panelTop={{ base: "65px", sm: "85px", md: "70px" }}
              paddingTop="2"
              tabHandle={tabHandle}
              listGridIcon={listGridIcon}
              setVisible={setVisible}
              visible={visible}
              topTogleIcon="130px"
              attr={attr}
            />
          )}
        </HStack>
      </Stack>
    </Container>
  );
}

export default Programmes;

import React, { useEffect, useState } from "react";
import { totalFilterCount, updateFacetsData } from "../../../../util/helper";
import { useParams } from "react-router-dom";
import * as getRelatedArtData from "../../../../services/Location";
import { Box, HStack, Image, Stack } from "@chakra-ui/react";
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import FilterPills from "../../../../components/FilterPills/FilterPills";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../../CommonComponents/NoMoreToLoad";
import Grid from "../../../../FunctionalComponents/SimpleGrid/Grid";
import { CopyPasteService } from "../../../../services/CopyPaste";
import { FilterBox } from "../../../../FunctionalComponents/Filter/FilterBox";
import { DataNotFound } from "../../../../CommonComponents/DataNotFound";
import { explore } from "../../../../constants/constants";
import { FullScreenView } from "./FullScreenView";
import ImageWithCitation5 from "../../../../FunctionalComponents/SimpleGrid/ImageWithCitation5";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export default function RelatedArtWork() {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [ImgData, setImgData] = useState(null);

  // const navigate = useNavigate();
  const { alias, monumentAlias } = useParams();
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    search: "",
    filters: {},
  });
  const [apply, setApply] = useState(false);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
      case "gate":
        selectedString = filterOptions.gate;
        break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }

    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }

    if (selectedString !== type) {
      selectedString = type;
    }

    switch (value) {
      case "sort":
        setFilterOptions((prevFilterOptions) => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
      case "gate":
        setFilterOptions((prevFilterOptions) => ({
          ...prevFilterOptions,
          gate: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions((prevFilterOptions) => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions((prevFilterOptions) => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };

  const handleReset = () => {
    setFilteredResultCount(0);
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters,
    });
    setFilterCount(0);
    setSearchTerm("");
  };

  //api INTEGRATION
  const [facets, setFacets] = useState([]);
  const [sort, setSort] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [filterPills, setFilterPills] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [FullScreenImage, setFullScreenImage] = useState(false);

  const citationFactor = (data) => {
    return (
      data?.length > 0 &&
      data?.map((item) => {
        return {
            citation_name: item?.bibliographic_citation,
          image: item?.direct_url_of_cover_image,
          srNo: item?.wnn,
        };
      })
    );
  };
  async function fetchData() {
    if (!navigator.onLine) {
      alert(
        "No network connection. Please check your internet connection and try again."
      );
      return;
    }
    try {
      setIsLoading(true);
      const result = await getRelatedArtData.getRelatedWork(
        masterlistType,
        alias,
        monumentAlias,
        filterOptions,
        1
      );
      setIsLoading(false);
      if (result && result?.data?.length > 0) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        setPage((prevPage) => prevPage + 1);
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);

          if (formattedFacets && formattedFacets?.length > 0) {
            setFilterOptions((prevFilterOptions) => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                ...formattedFacets.reduce((acc, facet) => {
                  acc[facet.key] = [];
                  return acc;
                }, {}),
              },
            }));
          }
        }

        if (result.total === result?.data?.length) {
          setHasMore(false);
        }

        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    setFilterPills(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = async () => {
    if (!navigator.onLine) {
      alert(
        "No network connection. Please check your internet connection and try again."
      );
      return;
    }
    try {
      const result = await getRelatedArtData.getRelatedWork(
        masterlistType,
        alias,
        monumentAlias,
        filterOptions,
        page
      );

      if (result) {
        const processedData = citationFactor(result?.data);
        setData((prevData) => [...prevData, ...processedData]);
        setPage((prevPage) => prevPage + 1);
        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        if (data?.length === result?.total) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  async function fetchFilterData(page = 1) {
    try {
      setIsLoading(true);
      const result = await getRelatedArtData.getRelatedWork(
        masterlistType,
        alias,
        monumentAlias,
        filterOptions,
        page
      );
      setIsLoading(false);

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        setPage((prev) => prev + 1);
        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        const filtercnt = totalFilterCount(filterOptions?.filters);
        if (filtercnt > 0 || filterOptions?.search) {
          setFilteredResultCount(result?.total);
        } else {
          setFilteredResultCount(0);
        }
        if (result?.data?.length === result?.total) {
          setHasMore(false);
        }

        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (apply) {
      setApply(false);
      setHasMore(true);
      setPage(1);
      fetchFilterData();
      setFilterPills(filterOptions);
    }
    const filtercnt = totalFilterCount(filterOptions?.filters);
    setFilterCount(filtercnt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);
  
  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };
  return (
    <>
    {!isLoading ? (
      <>
        {data?.length > 0 ? (
      <Stack flexDirection={"row-reverse"} paddingTop={"4"} alignItems={"flex-start"}> 
            {!showFilterBox && (
              <FilterButton marginRight={"-65px"} marginTop="24px" masterlistType={"generic"} onClick={handleFilterClick} />
            )}
            <Stack py="4" flexDirection={"row"} gap={8} justifyContent="center">
              <HStack  flexDirection={"column"} alignItems={"flex-start"}>
                {(filterPills?.filters || filterOptions.search !== "") && (
                  <FilterPills
                    filters={filterPills}
                    setFilterOptions={setFilterOptions}
                    setApply={setApply}
                    setSearchTerm={setSearchTerm}
                    setFilterPills={setFilterPills}
                    filteredResultCount={filteredResultCount}
                  />
                )}
                <Box marginInlineStart={"0rem !important"}>
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    <Box textAlign="center" marginLeft={"50%"}>
                      <Stack marginTop={"20px"}>
                        <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                      </Stack>
                    </Box>
                  }
                  endMessage={<NoMoreToLoad />}
                >
                  <Grid
                    data={data}
                    handleImageClick={handleImageClick}
                    columns={{ base: 2, md: 3, lg: 3, xl: 4 }}
                    spacing="20px"
                    component={ImageWithCitation5}
                  />
                </InfiniteScroll>
                </Box>
              </HStack>
              {showFilterBox && (
                <HStack alignItems={"flex-start"} marginRight={"-65px!important"}>
                  <FilterBox
                    setShowFilterBox={setShowFilterBox}
                    setApply={setApply}
                    explore={explore}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSearch={handleSearch}
                    filterOptions={filterOptions}
                    filteroptionData={facets}
                    sort={sort}
                    setFilterOptions={setFilterOptions}
                    setSearchTerm={setSearchTerm}
                    filterCount={filterCount}
                    searchTerm={searchTerm}
                    handleReset={handleReset}
                    apply={apply}
                  />
                </HStack>
              )}
            </Stack>
      </Stack>
        ) : (
          <Stack onCopy={(e) => CopyPasteService(e, `${window.location.href}`)} alignItems={"center"}>
            <Box position={"absolute"} top="-70px" left={"0px"}>
              {filterPills?.filters && filterCount > 0 && (
                <FilterPills
                  filters={filterPills}
                  setFilterOptions={setFilterOptions}
                  setApply={setApply}
                  setFilterPills={setFilterPills}
                />
              )}
            </Box>
            <DataNotFound />
          </Stack>
        )}
      </>
    ) : (
      <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
        <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
      </HStack>
    )}

    {FullScreenImage && (
      <FullScreenView
      isOpen={FullScreenImage}
      onClose={() => showImages(null, false)}
      ImgData={ImgData}
      imageData={data}
      bannerFlag={true}
      title="banner"
      setReadMoreFlag={""}
      clickedImageIndex={clickedImageIndex}
      setClickedImageIndex={setClickedImageIndex}
    />
    )}
  </>
);
}
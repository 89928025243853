import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { indexTitle } from "../constants/constants";
import { getIndexData } from "../services/MasterlistIndexService";
import { CopyPasteService } from "../services/CopyPaste";
import H3 from "../Theme/Typography/H3";
import { MdHomeFilled, MdMap } from "react-icons/md";
import { RiLayoutGridFill } from "react-icons/ri";
import { IoMdCalendar } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import LeftVmenu from "../FunctionalComponents/LeftVmenu";
import { DataNotFound } from "../CommonComponents/DataNotFound";

export default function MasterlistsIndex() {
  const [tableData, setTableData] = React.useState([]);
  const [showLabel, setShowLabel] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getIndexData();
      if (result?.length > 0) {
        setTableData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);
  const [dummyData, setDummyData] = useState(false);
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  useEffect(() => {
    if (dummyDataEnabled.toLowerCase() === "yes") {
      setDummyData(true);
    } else {
      setDummyData(false);
    }
  }, [dummyDataEnabled]);
  return (
   <SidebarWithHeader>
      <Stack px={{base:"15px",md:"64px"}} py={{base:"15px",md:"32px"}}>
        <Box >
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <HStack marginTop={"0rem!important"} borderBottom={"2px"}>

              {dummyData ? (
                <H3 textStyle="secondary" lineHeight={"21.28px"} fontWeight="700">
                  1 to 345 {indexTitle.MASTERLISTS}
                </H3>
              ) : (
                <H3 textStyle="secondary" lineHeight={"21.28px"} fontWeight="700">
                  0 {indexTitle.MASTERLISTS}
                </H3>
              )}
            </HStack>
          </Stack>
        </Box>

        <Box>
          <HStack justifyContent={"center"}>
            <Stack>
            {showLabel ? (
                <DataNotFound/>
              ) : (
                <>
                <TableContainer  maxHeight="100%" overflowY="auto">
                <Table variant="ustyled" size="md">
                  <Thead position="sticky" top={0} zIndex="docked" bg="white">
                        <Tr mb="4">
                          <Th textTransform={"capitalize"} pl="0px">
                            <H3 fontWeight="700" width="150px">
                              {indexTitle.PUBLISHED_NUMBER}
                            </H3>
                          </Th>
                          <Th width={"50%"} textTransform={"capitalize"}>
                            <H3 fontWeight="700">
                              {indexTitle.MASTERLIST_NAME} <br />
                              {indexTitle.TIME_AND_DATE}
                            </H3>
                          </Th>
                          <Th textTransform={"capitalize"}>
                            <H3 fontWeight="700">
                              {indexTitle.MASTERLIST_TYPE}
                            </H3>
                          </Th>
                          <Th textTransform={"capitalize"}>
                            <H3 fontWeight="700">
                              {indexTitle.DATE_OF_PUBLISHING}
                            </H3>
                          </Th>
                          <Th textTransform={"capitalize"}>
                            <H3  fontWeight="700">
                              {indexTitle.LAST_UPDATED}
                            </H3>
          
                          </Th>
                        </Tr>
                      </Thead>
               
                      <Tbody
                        textAlign={"left"}
                        onCopy={(e) =>
                          CopyPasteService(e, window.location.href)
                        }
                      >
                        {tableData.map((item, index) => {
                          return (
                            <Tr fontSize="16px" key={index} fontWeight={"600"}>
                              <Td py="3" textAlign={"left"} pl="0px"><H3 textStyle="secondary" lineHeight={"21.28px"} fontWeight="500">{item?.id}</H3></Td>
                              <Td py="3" width={"50%"}><H3 textStyle="secondary" lineHeight={"21.28px"} fontWeight="500">{item?.name}</H3></Td>
                              <Td py="3"><H3 textStyle="secondary" lineHeight={"21.28px"} fontWeight="500">{item?.type}</H3></Td>
                              <Td py="3"><H3 textStyle="secondary" lineHeight={"21.28px"} fontWeight="500">{item?.date_of_publishing}</H3></Td>
                              <Td py="3"><H3 textStyle="secondary" lineHeight={"21.28px"} fontWeight="500">{item?.last_updated}</H3></Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    
                    </Table>
                  </TableContainer>
                </>
                   )}
            </Stack>
          </HStack>
        </Box>
        <Stack py="2"></Stack>
      </Stack>
      </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Container maxW="1440px" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>


        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};
const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      handleNavigation={handleNavigation}
      LeftVmenuData={LeftVmenuData}
    />
  );
};

import { Text } from "@chakra-ui/react";
import React from "react";

const H1 = ({ children,fontWeight="700", fontSize="24px",lineHeight="32px",textStyle="primary",textAlign,  ...props }) => {
  return (
    <Text 
    as="h1" {...props} 
    fontSize={fontSize}
    lineHeight={lineHeight} 
    fontWeight={fontWeight}
    textStyle={textStyle}
    textAlign={textAlign}
    >
      {children}
    </Text>
  );
};
export default React.memo(H1);
import api from "../services/api";
import { APIS } from "../endpoints";

// Get Essay Data
  /**
        This function return Essay tab data of Body of work section for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} alias - Masterlist alias.
        @returns {Promise} - Function return Essay tab data of Body of work section.
  */
export const essayData = async (masterlistType, masterlist, filterOptions, page) => {

      const response = await essayDataAPI(masterlistType, masterlist, filterOptions, page);
      try {
        if (response) {
          return response;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
}

export const essayDataAPI = async (masterlistType, masterlist, filterOptions, page) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.scholarshipEssays}`, {
    params: params
  })

  if (result) {
    return result?.data;
  } else {
    return [];
  }
};
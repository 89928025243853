import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { AiOutlinePicture } from '@react-icons/all-files/ai/AiOutlinePicture';
import React from 'react'
import {
    VerticalTimeline,
    VerticalTimelineElement,
  } from "react-vertical-timeline-component";
import { common } from '../../constants/constants';
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const dot = `${IMAGE_BASE_PATH}/ui/Chevron/TimelineEnd.png`
export default function AccordianTeaching(props) {
  return (
    <>
    {props.teachingData && Object.entries((props.teachingData)).length>0 ?
      Object.entries(props.teachingData).map(([category, items]) => {
        return (
          <Stack key={category} marginLeft={"30px"}>
            <Text
              fontSize={"18px"}
              fontWeight={500}
              pt={"16px"}
              pb={"12px"}
              lineHeight={"21px"}
            >
              {category}
            </Text>
            <VerticalTimeline layout={"1-column-left"}>
              {items &&
                items.map((item, index) => {
                  return (
                    <>
                      {item?.image_alias && (
                        <Box
                          pos={"relative"}
                          right={"20px"}
                          top={"23px"}
                        >
                          <AiOutlinePicture
                            onClick={() =>
                                props.showFamilyMemberImages(
                                item?.image_alias,
                                true
                              )
                            }
                            cursor={"pointer"}
                            color="#1E90FF"
                            size={18}
                          />
                        </Box>
                      )}

                      <VerticalTimelineElement
                        id="timeline1"
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                          background: "#fff",
                          color: "black",
                          fontSize: item?.display_date?.length > 4 ? "11px":"16px", // Dynamically set the font size
                          fontWeight: "700",
                          maxWidth: item?.display_date?.length > 4 ? "40px":"max-content",
                        }}
                        icon={
                          item.display_date === "NA" ? (
                            <Image
                              marginLeft={"13px"}
                              marginTop={"8px"}
                              src={dot}
                            ></Image>
                          ) : (
                            item.display_date
                          )
                        }
                      >
                        <Text cursor={"pointer"} color="black">
                          {item.data}
                        </Text>
                      </VerticalTimelineElement>
                    </>
                  );
                })}
            </VerticalTimeline>
          </Stack>
        );
      })
      :
      <><Text fontSize={"14px"}
          fontWeight={"500"}
          color="black"
          lineHeight={"16px"}
          textAlign={"justify"}
          width={"100%"}
          py={"0"}>
        {common.CONTENT_AWAITED}
        </Text></>
    }
  </>
  )
}
import { AspectRatio, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { virtualTourData } from '../../../services/VirtualTour';

export default function VirtualTour() {
  const [data, setData] = useState(null); 
  const {alias}=useParams();
  const masterlist=alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  async function fetchData() {
    try {
    const result = await virtualTourData(masterlistType, masterlist );  
    if (result && result?.data?.length > 0) {
      const materportLink =
      result?.data[0]?.find((nestedItem) => nestedItem?.key ==="materport_link")
          ?.value
        setData(materportLink)
    }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 
  console.log(data, "data")

  return (
    <Stack minHeight={"100vh"} py="25px" spacing={8}>
      {/* Main Tour */}

      {data && <AspectRatio ratio={16 / 9}>
        <iframe 
          src={data} 
          title='Virtual Tour' 
          allowFullScreen 
          style={{ border: 0 }} 
        />
      </AspectRatio>}

      {/* Video Grid */}
      {/* <Grid templateColumns="repeat(auto-fit, minmax(150px, 1fr))" gap={6}>
        {videos.map(video => (
          <Stack key={video.id} position="relative">
            <Image 
              src={video.thumbnail} 
              alt={`Thumbnail ${video.id}`} 
              cursor="pointer" 
              onClick={() => openVideoInModal(video.src)} 
            />
            <IconButton
              icon={<FaPlay />}
              aria-label={`Play video ${video.id}`}
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              colorScheme="whiteAlpha"
              isRound
              onClick={() => openVideoInModal(video.src)}
            />
          </Stack>
        ))}
      </Grid> */}

      {/* Video Modal */}
      {/* <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={0}>
            <AspectRatio ratio={16 / 9}>
              <iframe 
                src={selectedVideo} 
                title='Selected Video' 
                allowFullScreen 
                style={{ border: 0 }} 
              />
            </AspectRatio>
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </Stack>
  );
}



import {
  Box,
  Container,
  HStack,
  Stack,
  Text,
  Image,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useRef, useState } from "react";

//Service files
import * as getTopAuctionSales from "./../../../services/PersonArtist";
import { CopyPasteService } from "../../../services/CopyPaste";
import { FullScreenImage } from "../Component/FullScreenImage";
// import { FullScreenImage } from "../../../FunctionalComponents/FullScreenImage";

//Language files
import { explore } from "../../../constants/constants";
import ImageWithCitation2 from "../../../FunctionalComponents/SimpleGrid/ImageWithCitation2";
import Grid from "../../../FunctionalComponents/SimpleGrid/Grid";
import { FilterBox } from "../../../FunctionalComponents/Filter/FilterBox";
import { totalFilterCount, updateFacetsData } from "../../../util/helper";
import FilterPills from "../../../components/FilterPills/FilterPills";
// import NoMoreToLoad from "../../../CommonComponents/NoMoreToLoad";
// import InfiniteScroll from "react-infinite-scroll-component";
import { DataNotFound } from "../../../CommonComponents/DataNotFound";
import { useParams } from "react-router-dom";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
// const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

const Filter = `${IMAGE_BASE_PATH}/ui/Chevron/filter.png`

export default function TopAuctionSales() {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [filterOptions, setFilterOptions] = useState({ search: "", filters: {}, sortBy_v: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [apply, setApply] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);
  // const [setLoading] = useState(true);
  const [data, setData] = useState([]);
  // const [hasMore, setHasMore] = useState(true);
  const [facets, setFacets] = useState([]);
  // const [setSort] = useState([]);
  const [filterPills, setFilterPills] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [page, setPage] = useState(1);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1]
  const {alias}=useParams();
  const masterlist = alias;

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };


  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches", e.target.value)
  };

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }

    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }

    if (selectedString !== type) {
      selectedString = type;
    }

    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };

  useEffect(() => {
    setFilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setFilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  const initialLoadComplete = useRef(false);

  async function fetchData() {
    try {
      // setLoading(true);
      const result = await getTopAuctionSales.getTopAuctionSalesData(masterlistType, masterlist, filterOptions, page);

      // setLoading(false);
      if (result && result?.data.length > 0) {
        const processedData = citationFactor(result?.data);

        setData(processedData);
        if (!initialLoadComplete.current) {
          setPage((prevPage) => prevPage + 1);
          initialLoadComplete.current = true;
        }
        // if (result?.total <= result?.data.length) {
        //   setHasMore(false)
        // }

        if (result?.facetsHeaders) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
          if (formattedFacets && formattedFacets.length > 0) {
            setFilterOptions(prevFilterOptions => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                ...formattedFacets.reduce((acc, facet) => {
                  acc[facet.key] = [];
                  return acc;
                }, {})
              }
            }));
          }
        }
        // const sortedFacets = result?.sortByHeader?.map(obj => {
        //   const key = Object.keys(obj)[0];
        //   return { key: key, display: obj[key] };
        // });
        // setSort(sortedFacets);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {

    fetchData();
    setFilterPills(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const fetchMoreData = async () => {
  //   try {
  //     const result = await getTopAuctionSales.getTopAuctionSalesData(masterlistType, masterlist, filterOptions, page);

  //     if (result) {

  //       const processedData = citationFactor(result?.data);
  //       setData(prevData => [...prevData, ...processedData]);
  //       setPage(prevPage => prevPage + 1);

  //       // const sortedFacets = result?.sortByHeader?.map(obj => {
  //       //   const key = Object.keys(obj)[0];
  //       //   return { key: key, display: obj[key] };
  //       // });
  //       // setSort(sortedFacets);

  //       if (data && data.length >= result?.total) {
  //         setHasMore(false);
  //       }
  //     }

  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }

  async function fetchFilterData(page = 1) {

    try {
      const result = await getTopAuctionSales.getTopAuctionSalesData(masterlistType, masterlist, filterOptions, page);

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);

        // const sortedFacets = result?.sortByHeader?.map(obj => {
        //   const key = Object.keys(obj)[0];
        //   return { key: key, display: obj[key] };
        // });
        // setSort(sortedFacets);
        // if (data && data.length >= result?.total) {
        //   setHasMore(false);
        // }
        const filtercnt= totalFilterCount(filterOptions?.filters)
        
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {

    if (apply) {
      setApply(false);
      fetchFilterData();
      setFilterPills(filterOptions)
      const filtercnt = totalFilterCount(filterOptions?.filters)
      setFilterCount(filtercnt)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);


  const handleReset = () => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setFilterCount(0)
    setSearchTerm("");
  }

  const citationFactor = (data) => {
    const findItem = (items, key) => items.find(it => it.key === key) || {};
    const getValue = (item, defaultValue = "") => item?.value || defaultValue;
    const getDisplay = (item, defaultValue = "") => item?.display || defaultValue;
  

    const formatSize = (length, base, unit) => {
      if (length && base) return `${parseFloat(length).toFixed(2)} x ${parseFloat(base).toFixed(2)} ${unit}`;
      if (length) return `${parseFloat(length).toFixed(2)} ${unit}`;
      if (base) return `${parseFloat(base).toFixed(2)} ${unit}`;
      return '';
    };

    return data.map((item) => {
      const mediumArtworkItem = findItem(item, "medium_artwork");

      const lotNumber = findItem(item, "lot_number");
      const auctionHouseName = findItem(item, "auction_house_name");
      const venue = findItem(item, "venue");
      const auctionDate = findItem(item, "auction_end_date");
      const auctionType = venue?.value === "Online" ? venue?.value : "Offline";
      const typeOfAuction = {
        display: "Type of auction",
        value: auctionType
      }

      const lowerEstimate = findItem(item, "le_inr");
      const lowerEstimateUsd = findItem(item, "le_usd");
      const higherEstimate = findItem(item, "he_inr");
      const higherEstimateUsd = findItem(item, "he_inr");
      const estimationInr = `${lowerEstimate?.value} - ${higherEstimate?.value}`;
      const estimationUsd = `${lowerEstimateUsd?.value} - ${higherEstimateUsd?.value}`;
      const estimates = {
        display :  getDisplay(lowerEstimate),
        value_in_inr: `${estimationInr}`,
        valuein_dollar: `${estimationUsd}`
      }

      const sellingPriceINR = findItem(item, "sp_inr");
      const sellingPriceUSD = findItem(item, "sp_usd");
      const sellingPriceInr = sellingPriceINR?.value;
      const sellingPriceUsd = sellingPriceUSD?.value;

      const winningBid = {
        display :  getDisplay(sellingPriceINR),
        value_in_inr: `${sellingPriceInr}`,
        valuein_dollar: `${sellingPriceUsd}` 
      }

      const artWorkRate = findItem(item, "square_inch_rate_in_inr_terms");
      const mediumArtwork = getValue(mediumArtworkItem) ? mediumArtworkItem : null;
      const actualDateItem = findItem(item, "actual_date");
      const actualDateValue = actualDateItem ? getValue(actualDateItem) : null;

      // Construct the mediumDate string
      const mediumDate = `${mediumArtwork?.value || ''}${actualDateValue ? `, ${actualDateValue}` : ''}`;

      // Update mediumArtwork if it is not null or undefined
      if (mediumArtwork && mediumDate) {
          mediumArtwork.value = mediumDate;
      }

      // Find the size items
      const sizeItem = findItem(item, "length_cm");
      const sizeItemIn = findItem(item, "length_in");
      const baseItem = findItem(item, "base_cm");
      const baseItemIn = findItem(item, "base_in");

      // Get values for length and base in both cm and in
      const lengthVal = getValue(sizeItem);
      const lengthValIn = getValue(sizeItemIn);
      const baseVal = getValue(baseItem);
      const baseValIn = getValue(baseItemIn);

      // Construct the size value for inches and centimeters
      const sizeValueIn = formatSize(lengthValIn, baseValIn, 'in');
      const sizeValueCm = formatSize(lengthVal, baseVal, 'cm');

      // Combine size values, ensuring no extra spaces if one value is missing
      const sizeValue = [sizeValueIn, sizeValueCm].filter(Boolean).join(" (") + (sizeValueCm ? ")" : "");

      let sizeData = {};

      if (sizeValue)
      {
        sizeData = {
          display: getDisplay(sizeItem), // Provide fallback for display
          value: sizeValue // Provide fallback for value
        };
      }
      
      const titleItem = findItem(item, "title");
      const titleOfArtwork = getValue(titleItem) ? titleItem : null;
      const fullImage = getValue(findItem(item, "direct_url_of_preview_image"));
      const year = getValue(findItem(item, "sortable_year"));
  
      return {
        image: fullImage,
        desc: getValue(findItem(item, "bibliographic_citation")),
        listDesc: getValue(findItem(item, "bibliographic_caption")),
        content: findItem(item, "quotations_and_other_text"),
        title: getValue(findItem(item, "creator_name")),
        fullImg: fullImage,
        essayistData: [titleOfArtwork, mediumArtwork, sizeData, lotNumber, auctionHouseName, venue, auctionDate, typeOfAuction],
        auctionData: [artWorkRate],
        auction_estimates: [estimates, winningBid],
        link: item?.link,
        year
      };
    });
  };

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };
  return (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      {!showFilterBox && <FilterButton marginRight={"-30px"} onClick={handleFilterClick} />}
      <Container
        maxW="auto"
        className="songs"
        pr={{ base: "4", md: "0px" }}
        pl={{ base: "4", md: "40px" }}
        pb="8"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {data && data?.length > 0 ? (
            <Stack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
              {((filterPills &&  filterPills!==undefined && filterCount>0) || filterOptions?.search) &&
                <FilterPills
                filters={filterPills}
                setFilterOptions={setFilterOptions}
                setApply={setApply}
                setSearchTerm={setSearchTerm}
                setFilterPills={setFilterPills}
                filteredResultCount={filteredResultCount}
                />
              }
                {/* <InfiniteScroll
                 dataLength={data.length}
                 next={fetchMoreData}
                 hasMore={hasMore}
                  loader={
                    <Box textAlign="center" marginLeft={"50%"}>
                      <Stack marginTop={"20px"}>
                        <img
                          src={loaderImg}
                          width={"100px"}
                          height={"100px"}
                          alt="Loading"
                        />
                      </Stack>
                    </Box>
                  }
                  endMessage={<NoMoreToLoad />}
                > */}
              <Grid
                data={data}
                columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                spacing={{ base: "5px", md: "24px" }}
                handleImageClick={handleImageClick}
                component={ImageWithCitation2}
              />
              {/* </InfiniteScroll> */}
            </Stack>
          ) 
          : (
            <>
              <Stack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                margin={"auto"}
              >
                <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                {filterPills &&  filterPills!==undefined && filterCount>0&&
                  <FilterPills
                  filters={filterPills}
                  setFilterOptions={setFilterOptions}
                  setApply={setApply}
                  setFilterPills={setFilterPills}
                  />
                }
              </Box>
                <DataNotFound />
              </Stack>
            </>
          )}
          <HStack
            marginLeft={"4"}
            marginRight={"0px!important"}
            className="mt-0"
            alignItems={"flex-start"}
          >
          {showFilterBox && (
            <FilterBox
            setShowFilterBox={setShowFilterBox}
            setApply={setApply}
            explore={explore}
            apply={apply}
            handleCheckboxChange={handleCheckboxChange}
            handleSearch={handleSearch}
            filterOptions={filterOptions}
            filteroptionData={facets}
            setFilterOptions={setFilterOptions}
            setSearchTerm={setSearchTerm}
            filterCount={filterCount}
            searchTerm={searchTerm}
            handleReset={handleReset}
            className="common-box-filter"
          />
          )}
          </HStack>
        </Stack>
      </Container>
      {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={data}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="TopAuctionSales"
        />
      )}
    </Stack>
  );
}

export const FilterButton = ({ onClick, marginRight, paddingTop }) => {
  return (
    <>
      <Stack
        position={"relative"}
        pos={"fixed"}
        marginTop={"100px"}
        marginRight={marginRight}
        paddingTop={paddingTop}
        zIndex={"99!important"}
        display={{ base: "none", md: "flex" }}
      >
        <Stack
          id="filterstickywbtn"
          marginRight={"0px!important"}
          onClick={onClick}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap="10px"
          >
            <Image transform={"rotate(360deg)"} src={Filter} />
            <Text
              as="button"
              transform={"rotate(90deg)"}
              fontSize={"14px"}
              fontWeight={"700"}
              marginTop={"4px"}
            >
              {explore.FILTER}
            </Text>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

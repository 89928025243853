import React, { useEffect, useState } from "react";
import { explore } from "../../../constants/constants";
import Essay from "./Scholarship/Essay";
import Publications from "./Scholarship/Publications";
import LetterScribbles from "./Scholarship/LetterScribbles";
import TabMain from "../../../FunctionalComponents/TabComponents/TabMain";
import { useLocation, useParams } from "react-router-dom";

export default function Scholarship({ masterlistType, masterlist }) {
  const [attr, setAttr] = useState(0)
  const [essayCount,setEssayCount]=useState(0)
  const [letterCount,setLetterCount]=useState(0)

  const {innerTab}=useParams();
  const pathToIndex = {
    'essays': 0,
    'letters-scribbles-poetry': 1,
    'publications':2

  };
  const location = useLocation();  
  useEffect(() => {
    setAttr(pathToIndex[innerTab] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);

  function formatData() {
    return [
      {
        name: `${explore.ESSAYS}`,
        count:`${essayCount}`,
        component:<Essay masterlist={masterlist} setEssayCount={setEssayCount}/>,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/scholarship/essays`,
      },
      {
        name: `${explore.LETTERS_SCRIBBLES_POETRY}`,
        count:`${letterCount}`,
        component:<LetterScribbles masterlist={masterlist} setLetterCount={setLetterCount}/>,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/scholarship/letters-scribbles-poetry`,
      },
      {
        name: `${explore.PUBLICATIONS}`,
        component:<Publications masterlist={masterlist} masterlistType={masterlistType}/>,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/scholarship/publications/illustrated-book`,
      }
    ];
}

const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(masterlist){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist,essayCount,letterCount])
  return (
    <>
      {tabPanelData && tabPanelData.length>0 && <TabMain data={tabPanelData} attr={attr} setAttr={setAttr}/>}
    </>
  );
}

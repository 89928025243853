// Core Files
import { Box, Image } from "@chakra-ui/react";
import React from "react";

// Subsection Files
import H4 from "../../Theme/Typography/H4";
import Small from "../../Theme/Typography/Small";
import { getImageURL } from "../../util/getImage";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

export default function ImageGridList({
  data,
  text,
  columns,
  spacing,
  handleImageClick,
  handleImage,
  width,
  height,
  transition,
  className,
  archive,
  showArchiveImages,
  ...rest
}) {
  return (
    <>
        {data && data?.map((item, index) => (
          <Box
            height="100%"
            justifyItems="center"
            justifyContent="space-around"
            width={width && width}
          >
            <Box height={height ? height : "100%"} width={width && width} className={className && className}>
              <Image
                src={getImageURL(item?.image, 260, 200)}
                fallbackSrc={placeholderImg}
                width={"100%"}
                height={height && "100%"}
                objectFit={"contain"}
                cursor={"pointer"}
                transition={transition && transition}
                onClick={(e) =>
                    showArchiveImages(item?.image,item, true, index)
                  }
              />
            </Box>
            {text && (
              <Box py="2">
                <H4
                  color="color.blue"
                  lineHeight={"18.78px"}
                  cursor="pointer"
                  style={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                  }}
                  onClick={(e) =>
                    showArchiveImages(item?.image,item, true, index)
                  }
                >
                  {item?.title}
                </H4>
                {item?.subTitle && (
                  <Small
                    py="1"
                    marginInlineStart={"0.0rem !important"}
                  >{`${item?.subTitle}`}</Small>
                )}
              </Box>
            )}
          </Box>
        ))}
    </>
  );
}

import { Box, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { common } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`

export default function ImageOverlayWithCitation({
  data,
  handleImageClick
}) {
  return (
    <>
      {data.length > 0 ? (
        data?.map((item, index) => (
          <Box width={"100%"} height="inherit" key={index}>
            <Box
              justifyItems="center"
              display="flex"
              width={"100%"}
              height={"370px"}
              bg="white"
              justifyContent="space-around"
            >
              {item.link ? (
                <Image
                  src={item?.imagePath ? item?.imagePath : item?.image}
                  fallbackSrc={placeholderImg}
                  alt=""
                  cursor={"pointer"}
                  transition="1s ease"
                  width={"100%"}
                  height={"inherit"}
                  objectFit={"cover"}
                  className={"img-border-radius"}
                  onClick={() => handleImageClick(item, index)}
                />
              ) : (
                <Image
                  src={item?.image}
                  fallbackSrc={placeholderImg}
                  alt=""
                  cursor={"pointer"}
                  transition="1s ease"
                  width={"100%"}
                  height={"inherit"}
                  objectFit={"cover"}
                  className={"img-border-radius"}
                  onClick={() => handleImageClick(item, index)}
                />
              )}
            </Box>
            <Text
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"24px"}
              textAlign="left"
              color={item?.image?"white":"black"}
              px="32px"
              marginTop={"-90px"}
            >
              <Text as="span">{item?.citation}</Text><br/>
               <Text as="span">{item?.festivalSection}</Text><br/>
               <Text as="span">{item?.date}</Text>
            </Text>
          </Box>
        ))
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text
            py={"40"}
            fontSize={"45px"}
            fontWeight="700"
            textAlign="center"
          >
            {common.NO_DATA}
          </Text>
        </Stack>
      )}
    </>
  );
}

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, Flex, HStack, Input, InputGroup, InputRightElement, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import SmallBold from '../../components/layouts/Typography/SmallBold';
import { explore, filterAttribute } from '../../constants/constants';
import { RiCloseCircleFill } from '@react-icons/all-files/ri/RiCloseCircleFill';
import { BiSearch } from '@react-icons/all-files/bi/BiSearch';
import FilterBody from './FilterBody';
import Accordian from '../Accordion/Accordian';
import H3 from '../../Theme/Typography/H3';

export const FilterBox = ({
    setShowFilterBox,
    setApply,
    handleCheckboxChange,
    handleSearch,
    filteroptionData,
    filterOptions,
    searchTerm,
    filterCount,
    handleReset,
    className,
    position,
    marginRight,
    sort,
  }) => {
  const [accordianData,setAccordianData]=useState([]);
  const gates=["AND","OR","NOT"];

  function formatData() {
    return filteroptionData.map(element => {
      return {
        name: element.display,
        component: <FilterBody data={element.value ? element.value:''} Ukey={element.key} filterOptions={filterOptions} handleCheckboxChange={handleCheckboxChange} element={element} setApply={setApply}/>,
      };
    });
  }
  useEffect(()=>{
    if(filteroptionData && filteroptionData.length>0){
      const formattedData = formatData();
      setAccordianData(formattedData)
  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filteroptionData,filterOptions])
  
    return (
      <Box position={position && position} className={className?className:"common-box-filter"} marginRight={marginRight&& marginRight}>
        <Stack pt="15px" px="15px" flexDirection={"row"} justifyContent={"space-between"}>
          <HStack>
            <SmallBold
              as="u"
              color={"color.primary"}
              onClick={()=>{
                if((filterCount && filterCount>0) || filterOptions?.search!==''){
                handleReset();
                setApply((prev) => !prev);
                }
                // setShowFilterBox(false);
              }}
              cursor="pointer"
            >
              {explore.RESET}
              <span style={{ color: "color.blue" }}>
                {" "}
                {filterCount > 0 && ` (${filterCount})`}
              </span>
            </SmallBold>
          </HStack>
          <HStack className="mt-0">
            <RiCloseCircleFill
              size={"22px"}
              cursor="pointer"
              onClick={(e) => setShowFilterBox(false)}
            />
          </HStack>
        </Stack>
        <HStack px="15px" alignItems={"left"} flexDirection={"column"}>
          <InputGroup className="ml-0" py="2">
            <Input
              placeholder="Search"
              onChange={handleSearch}
              value={searchTerm}
              onKeyDown={(event)=>{
                if (event.key === "Enter") {
                handleSearch(event);
                setApply((prev) => !prev);
                // setShowFilterBox(false);
              }
            }
              }
            />
            <InputRightElement top={"auto"}>
            <BiSearch color="green.500" onClick={()=>{
                 handleCheckboxChange("searches",searchTerm);
                 setApply((prev) => !prev);
                 setShowFilterBox(false);
              }}/>
            </InputRightElement>
          </InputGroup>
        </HStack>
      
        <Stack pl="15px" maxH={{base:"500px",lg:"375px",'2xl':"375px"}} className="bodyscrollbar" overflowY={"scroll"}
      overflowX={"hidden"}>
        {sort && sort?.length>0 && <Accordion paddingRight="5px" allowToggle>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                        <Text
                    fontSize={"14px"}
                    fontWeight={"700"}
                    lineHeight={"24px"}
                    color={"color.primary"}
                  >{filterAttribute.SORT_BY}</Text>
                        </Box>
                        <AccordionIcon width={"32px"} height={"32px"} />
                      </AccordionButton>
                      <Box maxH="200px" className="accordian-filter-body-scrollbar" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {sort?.length>0 &&
                            sort?.map((it) => {
                              return (
                                <Flex borderBottom={'1px'} borderBottomColor={"#f1f1f1"}>
                                  <Checkbox
                                    borderColor={"black"}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "black",
                                      },
                                    }}
                                    iconColor="white"
                                    cursor="pointer"
                                    marginRight={"5px"}
                                    isChecked={filterOptions.sortBy_v===it?.key
                                    }
                                    onChange={() => {
                                      handleCheckboxChange("sort", it.key);
                                    }}
                                  />
                                  <Box p="1">
                                    <Text fontSize={"14px"}>
                                      {it.display}
                                    </Text>
                                  </Box>
                                </Flex>
                              );
                            })}
                        </AccordionPanel>
                      </Box>
                    </AccordionItem> 
                    {filterOptions?.gate && <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                        <Text
                    fontSize={"14px"}
                    fontWeight={"700"}
                    lineHeight={"24px"}
                    color={"color.primary"}
                  >{filterAttribute.GATE}</Text>
                        </Box>
                        <AccordionIcon width={"32px"} height={"32px"} />
                      </AccordionButton>
                      <Box maxH="200px" className="accordian-filter-body-scrollbar" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {gates?.length>0 &&
                            gates?.map((it) => {
                              return (
                                <Flex borderBottom={'1px'} borderBottomColor={"#f1f1f1"}>
                                  <Checkbox
                                    borderColor={"black"}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "black",
                                      },
                                    }}
                                    iconColor="white"
                                    cursor="pointer"
                                    marginRight={"5px"}
                                    isChecked={filterOptions.gate===it
                                    }
                                    onChange={() => {
                                      handleCheckboxChange("gate", it);
                                    }}
                                  />
                                  <Box p="1">
                                    <Text fontSize={"14px"}>
                                      {it}
                                    </Text>
                                  </Box>
                                </Flex>
                              );
                            })}
                        </AccordionPanel>
                      </Box>
                    </AccordionItem> }
                  </Accordion>}
        {accordianData && accordianData.length>0 ? <Accordian paddingRight="5px" fontSize={"14px"} maxH={"160px"} data={accordianData}/>: <H3>{explore.NO_FILTERS}</H3>}
        </Stack>
        <HStack justifyContent={{base:'center',md:'center'}} px="15px" pb="15" marginTop={"10px"}>
          <Button
            color={"color.secondary"}
            onClick={() => {
              setApply((prev) => !prev);
              if (window.innerWidth <= 768) { 
                setShowFilterBox(false);
              }
            }}
            width={"199px"}
            height={"36px"}
            bg={"color.primary"}
            fontSize={"16px"}
            fontWeight={"400"}
            variant={"unstyled"}
          >
            {explore.APPLY}
          </Button>
        </HStack>
       
      </Box>
    );
  };
import { Box, Divider, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react'
import H4 from '../../components/layouts/Typography/H4';
import { isArray } from 'lodash';
import { AiOutlinePicture } from '@react-icons/all-files/ai/AiOutlinePicture';
import dateFormat from "dateformat";

export default function AccordianText4(props) {
  return (
    <VStack align="start" spacing={2}>
    <Flex align="center" py={0}>
      <Text fontSize={"16px"} fontWeight={"500"} color={"#000000"}>
        {props.personalia &&
          props.personalia.map((item, index) => {
            return (
              <Stack key={index} marginLeft={"0px"}>
               {item?.value?.length>0 && <HStack
                  py={"0px"}
                  flexDirection={"row"}
                  marginTop={"0px !important"}
                >
                  <Stack  marginLeft="25px">
                    <H4 py="10px" fontWeight="600" >{item?.display}</H4>
                    {isArray(item?.value) ? (
                      item?.value?.map((member, memberIndex) => {
                        const imageUrl =
                          member?.image_alias !== "undefined" &&
                          member?.image_alias !== null
                            ? member?.image_alias
                            : null;

                        return (
                          <React.Fragment key={memberIndex}>
                            <HStack
                              marginLeft={
                                imageUrl
                                  ? "-25px!important"
                                  : "0px!important"
                              }
                              alignItems={"flex-start"}
                              className='mt-0'
                              py="10px"
                            >
                              {imageUrl && (
                                <Box pt="1">
                                  <AiOutlinePicture
                                    onClick={() =>
                                      props.showFamilyMemberImages(
                                        imageUrl,
                                        true
                                      )
                                    }
                                    cursor={"pointer"}
                                    color="#1E90FF"
                                    size={18}
                                  />
                                </Box>
                              )}
                              <Text
                                fontSize={"16px"}
                                fontWeight={"400"}
                              >
                                {(member?.full_name 
                                    ? member.full_name.charAt(0).toUpperCase() + member.full_name.slice(1) 
                                    : member?.name?.charAt(0).toUpperCase() + member?.name?.slice(1))
                                }
                                <br />
                                {member?.birth_date &&
                                    dateFormat(member?.birth_date,"d mmm yyyy")} {" "}
                                {member?.birth_date && member?.death_date && (
                                    <>
                                      -{" "}
                                    </>
                                  )}
                                  {member?.death_date && (
                                    <>
                                      {dateFormat(
                                        member?.death_date,
                                        "d mmm yyyy"
                                      )}
                                    </>
                                  )}
                              </Text>
                            </HStack>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <>
                        <HStack
                          pb={"2"}
                          pt="0"
                          flexDirection={"row"}
                          marginTop={"0px !important"}
                        >
                          <Stack>
                            <Text
                              fontSize={"16px"}
                              fontWeight={"400"}
                            >
                              {item.key === "date_of_birth" ||
                              item.key === "date_of_death" ||
                              item.key === "marriageregistrationdate"
                                ? item.value === ""
                                  ? ""
                                  : dateFormat(
                                      item?.value,
                                      "d mmm yyyy"
                                    )
                                : item?.value}
                            </Text>
                          </Stack>
                        </HStack>
                      </>
                    )}
                  </Stack>
                </HStack>}
              </Stack>
            );
          })}
      </Text>
    </Flex>
    <Divider pt="2" color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
  </VStack>
  )
}

import { Box, HStack, Image, Stack, Tag, TagLabel } from "@chakra-ui/react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";
import _, { isArray } from "lodash";
import Small from "../../../components/layouts/Typography/Small";
import { Link } from "react-router-dom";
import { bibliography } from "../../../constants/constants";
import { getImageURL } from "../../../util/getImage";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

export default function DrawerBody({
  setSwiper,
  data,
  handleBookClick,
  bibliographyObject,
  handlePagination,
}) {
  return (
    <>
      <Stack position={"relative"} id="allsliderskkArchive">
       {data?.sliderImage &&  <Swiper
          effect={"fade"}
          grabCursor={true}
          navigation={true}
          modules={[EffectFade, Navigation]}
          style={{ marginTop: "10px" }}
          onSwiper={setSwiper}
        >
          {data?.sliderImage && isArray(data?.sliderImage) &&
            data?.sliderImage.map((it) => (
              <SwiperSlide>
                <Box
                  justifyItems="center"
                  display="flex"
                  height={"455px"}
                  bg="white"
                  justifyContent="space-around"
                  onClick={()=> handleBookClick(true, data)}

                >
                  <Image
                    src={getImageURL(it, 345, 385)}
                    alt=""
                    cursor={"pointer"}
                    transition="1s ease"
                    width={"auto"}
                    height={"inherit"}
                    objectFit={"contain"}
                    fallbackSrc={placeholderImg}
                    // cursor={"pointer"}
                  />
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>}
      </Stack>
      <HStack pt={"25px"} pb="15px">
        <Small className="autherlink">
          <>
            {data?.fullname && (
              <>
                {data?.typeCreator && `${data?.typeCreator}: `}
                <Link to={`/generic/${data?.fullname}/research-centre/library`} color="color.blue">
                  {data?.fullname}{" "}
                </Link>
              </>
            )}
            {data?.book && (
              <>
                {bibliography.BOOK + ": "}
                <Link to={`/generic/${data?.book}/research-centre/library`} color="color.blue">
                  {/* {data?.book}{" "} */}
                  {`${data?.book} ( ${data?.docTypeFull} | ${data?.docBookType} )`}{" "}
                </Link>
              </>
            )}
            {data?.publisher && (
              <>
                {bibliography.PUBLISHER + ": "}
                <Link to={`/generic/${data?.publisher}/research-centre/library`} color="color.blue">
                  {data?.publisher}{" "}
                </Link>
              </>
            )}
            {data?.publicationYear && (
              <>
              {data?.publicationYear === +data?.firstEdition || data?.firstEdition===null  ?
              <>
                {bibliography.YEAR_OF_PUBLICATION + ": "}
                <Link to={`/generic/${data?.publicationYear}/research-centre/library`} color="color.blue">
                  {data?.publicationYear}{" "}
                </Link>
                </>
                :<>
                {bibliography.YEAR_OF_PUBLICATION + ": "}
                <Link to={`/generic/${data?.publicationYear}/research-centre/library`} color="color.blue">
                  {`${data?.publicationYear} (F.E : ${+data?.firstEdition})`}
                </Link>
                </>
                }
              </>
            )}
          </>
        </Small>
        
      </HStack>
      <HStack flexWrap={"wrap"} justifyContent={"flex-start"} gap={"5px"}>
        {data?.paginationValues && data?.paginationValues?.length>0 && data?.paginationValues.map((value, index) => (
          <>
           {value?.label.length>0 && value?.indexRange!==`0` && <Stack marginInlineStart={"0rem!important"}> 
          <Tag py="3px" className="m-0" minH={"20px"} key={index} size="md" color="color.gray" variant={"outline"} borderColor={"color.yellow"}>
            <TagLabel title={value?.indexRange} onClick={()=>handlePagination(value?.indexRange)} cursor={"pointer"} textStyle={"secondary"} color={"black"} fontSize={"12px"}>{_.startCase(value?.label.replace(/-/g, ' '))}</TagLabel>
          </Tag>
          </Stack>}
          </>
         
        ))}
      </HStack>
    </>
  );
}
import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { BiSort } from "react-icons/bi";
import { useParams } from "react-router-dom";

export default function SortBy({
  sort,
  filterOptions,
  handleCheckboxChange,
  setApply,
  masterlistType,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { tabValue } = useParams();

  // Utility function to determine positioning styles
  const calculateStyles = () => {
    const commonMarginLeft =
      masterlistType === "search"
        ? tabValue === "all"
          ? "73pc"
          : "65pc"
        : masterlistType === "generic"
        ? tabValue === "all"
          ? "71pc"
          : "63pc"
          : masterlistType === "research-categories"
        ? tabValue === "all"
          ? "71pc"
          : "63pc"
        : tabValue === "all"
        ? "65pc"
        : "58pc";

    const commonMarginTop =
      masterlistType === "search" || masterlistType === "generic" || masterlistType === "research-categories" ? "-30px" : "-25px";

    const position =
      masterlistType === "search" || masterlistType === "generic" || masterlistType === "research-categories" ? "sticky" : "fixed";

    const topPosition =
      masterlistType === "film"
        ? "240px"
        : masterlistType === "generic"
        ? "80px"
        : masterlistType === "search"
        ? "75px"
        : masterlistType === "research-categories"
        ? "0px"
        : "115px";

    return {
      marginLeft: commonMarginLeft,
      marginTop: commonMarginTop,
      position,
      top: topPosition,
    };
  };

  const styles = calculateStyles();

  return (
    <>
    <Stack display={{base:'none',md:'flex'}} gap={0} zIndex={99} width="max-content" {...styles}>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <HStack cursor="pointer" marginTop="0px!important" position="relative">
            <Text>Sort By</Text> <BiSort />
          </HStack>
        </PopoverTrigger>
        <PopoverContent
          width="14rem"
          position="absolute"
          right="-40px"
          top="0px"
          borderRadius="0"
          zIndex={99999}
        >
          <PopoverBody p="10px" zIndex="999!important">
            {sort?.map((it, index) => (
              <Flex key={index} borderBottom="1px" borderBottomColor="#f1f1f1">
                <Checkbox
                  borderColor="black"
                  _checked={{ "& .chakra-checkbox__control": { background: "black" } }}
                  iconColor="white"
                  cursor="pointer"
                  mr="5px"
                  isChecked={filterOptions.sortBy_v === it?.key}
                  onChange={() => {
                    handleCheckboxChange("sort", it.key);
                    setApply(true);
                  }}
                />
                <Box p="1">
                  <Text fontSize="14px">{it.display}</Text>
                </Box>
              </Flex>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Stack>

    {/* For mobile view */}

    <Stack alignItems={"flex-end"} position={"relative"}  top="-65px" right={"15%"}  display={{base:'flex',md:'none'}} gap={0} zIndex={99} width="auto" >
      <Popover  isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <HStack cursor="pointer" marginTop="0px!important">
            <BiSort />
          </HStack>
        </PopoverTrigger>
        <PopoverContent
          width="14rem"
          position="relative"
          borderRadius="0"
          zIndex={99999}
        >
          <PopoverBody p="10px" zIndex="999!important">
            {sort?.map((it, index) => (
              <Flex key={index} borderBottom="1px" borderBottomColor="#f1f1f1">
                <Checkbox
                  borderColor="black"
                  _checked={{ "& .chakra-checkbox__control": { background: "black" } }}
                  iconColor="white"
                  cursor="pointer"
                  mr="5px"
                  isChecked={filterOptions.sortBy_v === it?.key}
                  onChange={() => {
                    handleCheckboxChange("sort", it.key);
                    setApply(true);
                  }}
                />
                <Box p="1">
                  <Text fontSize="14px">{it.display}</Text>
                </Box>
              </Flex>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Stack>
    </>
  );
}

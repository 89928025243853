//Core files
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

//Component files
import { LyricsSoundtrack } from "../../pages/Film/BodyOfWork/Soundtrack";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";

//Language files
import { explore } from "../../constants/constants";
import Synopsis from "./Synopsis";


function BookletSongs({masterlistType,masterlist,bookletAlias}) {
  const{innerTab}=useParams();
  const [attr, setAttr] = useState(0)
  const pathToIndex = {
    'synopsis': 0,
    'lyrics-n-soundtrack': 1,

  };
  const location = useLocation();  
  useEffect(() => {
    setAttr(pathToIndex[innerTab] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);
  function formatData() {
    return [
      {
        name: `${explore.SYNOPSIS}`,          
        component:<Synopsis masterlistType={masterlistType} filmAlias={bookletAlias} masterlist={masterlist}/>
        ,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/filmography/${bookletAlias}/synopsis`
      },
      {
        name: `${explore.LYRICS_AND_SOUNDTRACK}`,          
        component:<LyricsSoundtrack winnerName={bookletAlias} />
        ,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/filmography/${bookletAlias}/lyrics-n-soundtrack`
      }
    ];
}
const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(masterlist){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist,bookletAlias])
  return (
    <TabMain  paddingTop="10px" gap="50px" width={"1080px"} cla pos={"fixed"} attr={attr} setAttr={setAttr}
    panelTop={{ base: "65px", sm: "85px", md: "65px" }} topTogleIcon="25px" listId={"listgridbutton6"} data={tabPanelData} />
  );
}

export default BookletSongs;
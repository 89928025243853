import React, { useState } from "react";
import {
  Box,
  CloseButton,
  Flex,
  HStack,
  useColorModeValue,
  Stack,
  Image,
  Divider,
  Text,
} from "@chakra-ui/react";
import { filmography } from "../../constants/constants";
import { Link, useParams } from "react-router-dom";
import { HorisLink } from "../HorisLink";
import { VerticalMenu } from "../VerticalMenu";
import { ObjectsMenu } from "../ObjectsMenu";
import FilmReleaseDetails from "../../pages/Film/FilmReleaseDetails";
import JournalDetails from "../../pages/ObjectBookJou/JournalDetails";
import ArtistLinks from "./ArtistLinks";
import { SubscribeCircle } from "../../CommonComponents/SubscribeCircle";
import CommonDrawer from "../../pages/Film/BodyOfWork/Components/CommonDrawer";
import H2 from "../../components/layouts/Typography/H2";
import Large from "../../components/layouts/Typography/Large";
import dateFormat from "dateformat";
import { getImageURL } from "../../util/getImage";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const BackArrow = `${IMAGE_BASE_PATH}/ui/Chevron/backarrow.png`


const CommonLeftSidebar =
  ({
    onClose,
    castTabFlag,
    researchCentreFlag,
    readmoreNavigation,
    setReadmoreNavigation,
    bookFlag,
    setBookFlag,
    openDrawer,
    sections,
    oSections,
    hMenu,
    LogoLink,
    filmReleaseDetails,
    journalDetails,
    trailerLink,
    artistLinks,
    artistLinksData,
    backTo,
    handleBackNavigation,
    handleNavigation,
    leftFlag,
    drawerImage,
    drawerData,
    hidden,
    setHidden,
    artistDetails,
    bookletSong,
    readMoreText,
    setDrawerName,
    drawerName,
    aliasAPI,
    width,
    ...rest
  }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isActive1 = window.location.href.includes("watch-film-trailer");
    const isActive2 = window.location.href.includes("suresh-jindal");

    return (
      <>
        <Box
          bg={useColorModeValue("white", "gray.900")}
          borderRight="1px"
          borderRightColor={useColorModeValue("gray.200", "gray.700")}
          w={{ base: "none", md: 52 }}
          display={{ base: "none", md: "flex" }}
          pos="fixed"
          h="100%"
          {...rest}
          color={"white"}
          overflow={"scroll"}
          flexDirection={"column"}
          className="verticalscroll"
          zIndex={9999}
        >
          <Stack >
            <Flex
              h="auto"
              my="3"
              justifyContent="center"
              flexDirection="row-reverse"
              px="17px"
            >
              <CloseButton
                display={{ base: "flex", md: "none" }}
                onClick={onClose}
              />
              <Stack width={"175px"}>
                  {LogoLink?.logoImage && (
                      <HStack justifyContent="center" pt="1" pb="2">
                      <Link to={LogoLink?.logoLink}>
                        <Image
                          src={
                            getImageURL(LogoLink?.logoImage) 
                          }
                          height={"168px"}
                          width={"168px"}
                        />
                      </Link>
                      </HStack>
                    )}
                <HorisLink aliasAPI={aliasAPI} flexDirection={"column"} textStyle="primary" alignItems={"flex-start"}  fontSize={"14px"} fontWeight="700" marginLeft="-8px !important" links={hMenu} />
                {filmReleaseDetails && (
                  <FilmReleaseDetails filmReleaseDetails={filmReleaseDetails} />
                )}
                {journalDetails && (
                  <JournalDetails journalDetails={journalDetails} />
                )}
                {artistDetails && (
                  <ArtistDetails artistDetails={artistDetails} width={width} />
                )}
              </Stack>
            </Flex>
            <Divider mt="3" color={"#909090"} />
            <Box mb="80px" className="mt-0">
              {trailerLink && (
                <WatchFilmTrailer
                  isActive1={isActive1}
                  trailerLink={trailerLink}
                />
              )}
              <Divider mt="3" color={"#909090"} />
              <VerticalMenu sections={sections} />
              <HStack py="2">
                <Divider color={"#909090"} />
              </HStack>
              <ObjectsMenu links={oSections} />
              <HStack py="2">
                <Divider color={"#909090"} />
              </HStack>
              <HStack justifyContent="left" px="4" marginBottom={"25px"}>
                <Link to="/tris" px="4" py="1">
                  <SubscribeCircle />
                </Link>
              </HStack>
              <Box bottom={"110px"}>
                {artistLinksData && (
                  <ArtistLinks
                    setDrawerOpen={setDrawerOpen}
                    isActive2={isActive2}
                    artistLinksData={artistLinksData}
                    setDrawerName={setDrawerName}

                  />
                )}
              </Box>
              <BackArrowNav
                readmoreNavigation={readmoreNavigation}
                handleBackNavigation={handleBackNavigation}
                handleNavigation={handleNavigation}
                backTo={backTo}
                bookFlag={bookFlag}
                readMoreText={readMoreText}
                bookletSong={bookletSong}
              />
            </Box>
           {drawerData && drawerName!=="" &&<CommonDrawer
              setDrawerOpen={setDrawerOpen}
              drawerOpen={drawerOpen}
              data={drawerData.find((it)=>it.title===drawerName)}
              drawerImage={drawerImage}
            />} 
          </Stack>
        </Box>
      </>
    );
  };

export default CommonLeftSidebar;

const WatchFilmTrailer = ({ isActive1, trailerLink }) => {
  return (
    <>
      <HStack
        px="4"
        flexDirection={"row"}
        bg={isActive1 === true ? "#f6f6f6" : "white"}
        borderLeft={isActive1 === true ? "4px" : ""}
        borderColor={isActive1 === true ? "#FF4E4E" : ""}
        borderRadius={isActive1 === true ? "0px" : ""}
      >
        <Link to={trailerLink.link}>
          <Flex alignItems={"center"}>
            <Text
              fontSize={"14px"}
              fontWeight={isActive1 ? "700" : "500"}
              py="2"
              color={"black"}
              mr={isActive1 ? "27px" : "36px"}
            >
              {filmography.WATCH_FILM_TRAILER}
            </Text>
            <Image src={trailerLink.image} />
          </Flex>
        </Link>
      </HStack>
    </>
  );
};

const BackArrowNav = ({
  readmoreNavigation,
  handleBackNavigation,
  handleNavigation,
  readMoreText,
  backTo,
  bookFlag,
  bookletSong,
}) => {
  return (
    <HStack bottom={"15px"} px="4" pt="10" justifyContent="left">
      (
        <div style={{ position: "absolute", bottom: "9.5%",cursor:"pointer" }} color={"black"} fontSize={"xl"} onClick={handleNavigation && handleNavigation}>
          <Image src={BackArrow} />
        </div>
      ) 
    </HStack>
  );
};
export function ArtistDetails({ artistDetails, width="185px" }) {
let { alias } = useParams();
const currentEndpoint = window.location.pathname;
const parts = currentEndpoint.split("/");
const masterlistType = parts[1];
  return (
    <>
      <HStack justifyContent="left"marginTop="0.0rem">
        <H2 width={width}><Link to={`/${masterlistType}/${alias}`}>{artistDetails?.name}</Link></H2>
      </HStack>
      <HStack justifyContent="left">
        <Large color={"black"}>
        {`${artistDetails?.date_of_birth ? dateFormat(artistDetails.date_of_birth, "d mmm yyyy") : ""}
        ${artistDetails?.date_of_death ? " - " + dateFormat(artistDetails.date_of_death, "d mmm yyyy") : ""}`}
        </Large>
      </HStack>
    </>
  );
}
import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { getImageURL } from "../../util/getImage";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

export default function ImageWithCitation4({
  data,
  handleImage
}) {

  return (
    <>
      {data && data?.length > 0 &&
        data.map((item, index) => {
          return (
            <Box
              height="auto"
              display={"flex"}
              flexDirection={"column"}
              alignItems={"start"}
              key={index}
              alignContent={"center"}
            >
                <>
                  <Box
                    justifyItems="center"
                    display="flex"
                    height={"252px"}
                    bg="white"
                    width={"100%"}
                    justifyContent="space-around"
                    cursor={"pointer"}
                    onClick={() => handleImage(item?.monumentAlias)}
                    border={"1px"}
                    borderColor={"#f1f1f1"}
                    borderRadius={"50px"}
                  >
                    <Image
                      src={getImageURL(item.image)}
                      alt=""
                      width={"100%"}
                      fallbackSrc={placeholderImg}
                      height="100%"
                      objectFit={"cover"}
                      borderRadius={"10px"}
                    />
                  </Box>
                  <Link to={item.link} cursor={"pointer"}>
                    <Text
                      textAlign="left"
                      color="#0066B3"
                      py="4"
                      fontSize={"16px"}
                      fontWeight={"400"}
                    >
                    {item.desc}
                    </Text>
                  </Link>
                </>
            </Box>
          );
        })}
    </>
  );
}

import React, { useEffect, useRef, useState } from 'react'
import { totalFilterCount, updateFacetsData } from '../../util/helper';
import { getMasterlistData } from "../../services/MasterlistService";
import { Box, Container, HStack, Image, Stack } from '@chakra-ui/react';
import FilterPills from '../../components/FilterPills/FilterPills';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoMoreToLoad from '../../CommonComponents/NoMoreToLoad';
import Grid from '../../FunctionalComponents/SimpleGrid/Grid';
import ImageTextGrid from '../../FunctionalComponents/SimpleGrid/ImageTextGrid';
import { CopyPasteService } from '../../services/CopyPaste';
import { DataNotFound } from '../../CommonComponents/DataNotFound';
import { FilterButton } from '../../CommonComponents/FilterButton';
import { FilterBox } from '../../FunctionalComponents/Filter/FilterBox';
import { explore } from '../../constants/constants';
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export default function MasterlistTab() {
    const [facets, setFacets] = useState([]);
    const [sort, setSort] = useState([]);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const initialLoadComplete = useRef(false);
    const [apply, setApply] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showFilterBox, setShowFilterBox] = useState(false);
    const [filteredResultCount, setFilteredResultCount] = useState(0);
    const handleCheckboxChange = (value, type) => {
        let selectedArray = [];
        let selectedString = "";  
        switch (value) {
          case "sort":
            selectedString = filterOptions.sortBy_v;
            break;
            case "gate":
              selectedString = filterOptions.gate;
              break;
          case "searches":
            selectedString = filterOptions.search;
            break;
          default:
            if (value in filterOptions.filters) {
              selectedArray = filterOptions.filters[value];
            }
            break;
        }
      
        if (selectedArray.includes(type)) {
          selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
        } else {
          selectedArray = [...selectedArray, type];
        }
      
        if (selectedString !== type) {
          selectedString = type;
        }
      
        switch (value) {
          case "sort":
            setFilterOptions(prevFilterOptions => ({
              ...prevFilterOptions,
              sortBy_v: selectedString,
            }));
            break;
            case "gate":
            setFilterOptions(prevFilterOptions => ({
              ...prevFilterOptions,
              gate: selectedString,
            }));
            break;
          case "searches":
            setFilterOptions(prevFilterOptions => ({
              ...prevFilterOptions,
              search: selectedString,
            }));
            break;
          default:
            if (value in filterOptions.filters) {
              setFilterOptions(prevFilterOptions => ({
                ...prevFilterOptions,
                filters: {
                  ...prevFilterOptions.filters,
                  [value]: selectedArray,
                },
              }));
            }
            break;
        }
      };
      const handleReset = () => {
        setFilteredResultCount(0);
        const initializedFilters = facets.reduce((acc, facet) => {
          acc[facet.key] = [];
          return acc;
        }, {});
        setFilterOptions({
          search: "",
          filters: initializedFilters
        });
        setFilterCount(0)
        setSearchTerm("");
      }
      const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        // handleCheckboxChange("searches",e.target.value)
      };

    const [filterOptions, setFilterOptions] = useState({
        search: "",
        filters: {},
        sortBy_v:"image_desc"
      });
    
    const [filterPills, setFilterPills] = useState(null);
    const handleFilterClick = () => {
        setShowFilterBox(!showFilterBox);
    };
    async function fetchData() {
        if (!navigator.onLine) {
          alert("No network connection. Please check your internet connection and try again.");
          return;
        }
        try {
          setIsLoading(true);

          const result = await getMasterlistData(filterOptions,page);
          setIsLoading(false);
          if (result && result?.data.length > 0) {
            const processedData = result?.data;
            setData(processedData);
            if (!initialLoadComplete.current) {
              setPage((prevPage) => prevPage + 1);
              initialLoadComplete.current = true;
            }
            if (result?.facetsHeaders && result?.facets) {
              const formattedFacets = updateFacetsData(result);
              setFacets(formattedFacets);
            
              if (formattedFacets && formattedFacets.length > 0) {
                setFilterOptions((prevFilterOptions) => ({
                  ...prevFilterOptions,
                  filters: {
                    ...prevFilterOptions.filters,
                    ...formattedFacets.reduce((acc, facet) => {
                      acc[facet.key] = [];
                      return acc;
                    }, {}),
                  },
                }));
              }
            }
            
            if(result.total===result?.data.length){
              setHasMore(false)
            }
    
            const sortedFacets = result?.sortByHeader?.map((obj) => {
              const key = Object.keys(obj)[0];
              return { key: key, display: obj[key] };
            });
            setSort(sortedFacets);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
      useEffect(() => {
        fetchData();
        setFilterPills(filterOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      const fetchMoreData = async () => {
        if (!navigator.onLine) {
          alert("No network connection. Please check your internet connection and try again.");
          return;
        }
        try {
        const result = await getMasterlistData(filterOptions,page);
          if (result) {
            const processedData = result?.data;
            setData((prevData) => [...prevData, ...processedData]);
            setPage((prevPage) => prevPage + 1);
            const sortedFacets = result?.sortByHeader?.map((obj) => {
              const key = Object.keys(obj)[0];
              return { key: key, display: obj[key] };
            });
            setSort(sortedFacets);
            if (data?.length === result?.total) {
              setHasMore(false);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      async function fetchFilterData(page=1) {
        try {
          setIsLoading(true);
        //   const result = await getPostData.getPosts(
        //     filterOptions,
        //     page
        //   );
          const result = await getMasterlistData(filterOptions,page);
          setIsLoading(false);
    
          if (result) {
            const processedData = result?.data;
            setData(processedData);
            setPage(prev=>prev+1)
            const sortedFacets = result?.sortByHeader?.map((obj) => {
              const key = Object.keys(obj)[0];
              return { key: key, display: obj[key] };
            });
            setSort(sortedFacets);
            const filtercnt= totalFilterCount(filterOptions?.filters)
            if(filtercnt>0 || filterOptions?.search){
              setFilteredResultCount(result?.total)
            }else{
              setFilteredResultCount(0);
            }
            if (result?.data?.length === result?.total) {
              setHasMore(false);
            }
    
            if (result?.facetsHeaders && result?.facets) {
              const formattedFacets = updateFacetsData(result);
              setFacets(formattedFacets);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
    
        }
      }
      useEffect(() => {
        if (apply) {
          setApply(false);
          setHasMore(true);
          setPage(1)
          fetchFilterData();
          setFilterPills(filterOptions)
        }
        const filtercnt= totalFilterCount(filterOptions?.filters)
        setFilterCount(filtercnt)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [apply]);
  return (
    <Stack p="0" pb={{base:"60px",md:"0px"}}>

    {!isLoading ? (
      <>
      <Stack flexDirection={"row-reverse"}>
    {!showFilterBox && <FilterButton marginRight={"0px"} marginTop={"20px"} onClick={handleFilterClick} />}
      <Container
           maxW="auto"
           className="songs"
           px={{ base: "4", md: "0px" }}
           py={"10px"}
           position={"relative"}
         >
           <Stack flexDirection={"row"} justifyContent="center">
        {data.length > 0 ? (
          
          <Stack
          onCopy={(e) =>
            CopyPasteService(e, `${window.location.href}/0/1`)
          }
          marginRight={showFilterBox ? "0px" : "30px"}
          pr={showFilterBox ? "15px" : "30px"}
          alignItems={"flex-start"}
        >
        {((filterPills &&  filterPills!==undefined && filterCount>0) || filterOptions?.search) &&
                 <FilterPills
                 filters={filterPills}
                 setFilterOptions={setFilterOptions}
                 setApply={setApply}
                 setSearchTerm={setSearchTerm}
                 setFilterPills={setFilterPills}
                 filteredResultCount={filteredResultCount}
                 />
               }
                 <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                   loader={
                     <Box textAlign="center" marginLeft={"50%"}>
                       <Stack marginTop={"20px"}>
                         <img
                           src={loaderImg}
                           width={"100px"}
                           height={"100px"}
                           alt="Loading"
                         />
                       </Stack>
                     </Box>
                   }
                   endMessage={<NoMoreToLoad />}
                 >
                  <Grid
                    data={data}
                    columns={{ base: 2, md: 3, lg: 5, xl: 6 }}
                    textStyle={"secondary"} fontSize={{base:"14px",md:"14px"}} fontWeight="400" height={"130px"}  spacing="16px"
                    component={ImageTextGrid} />
                 </InfiniteScroll>
        </Stack>
        ):
        (
            <>
              <Stack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                margin={"auto"}
              >
                <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                {filterPills &&  filterPills!==undefined && filterCount>0&&
                  <FilterPills
                  filters={filterPills}
                  setFilterOptions={setFilterOptions}
                  setApply={setApply}
                  setFilterPills={setFilterPills}
                  />
                }
              </Box>
                <DataNotFound />
              </Stack>
            </>
          )
    }
             <HStack
               marginLeft={"4"}
               marginRight={"0px!important"}
               className="mt-0"
               alignItems={"flex-start"}
               pos={"relative"}
               pt="1px"
             >
               {showFilterBox && (
                 <FilterBox
                 setShowFilterBox={setShowFilterBox}
                 setApply={setApply}
                 explore={explore}
                 apply={apply}
                 handleCheckboxChange={handleCheckboxChange}
                 handleSearch={handleSearch}
                 filterOptions={filterOptions}
                 filteroptionData={facets}
                 sort={sort}
                 setFilterOptions={setFilterOptions}
                 setSearchTerm={setSearchTerm}
                 filterCount={filterCount}
                 searchTerm={searchTerm}
                 handleReset={handleReset}
                 className={"box-filter-film3"}
                 />
               )}
             </HStack>
    </Stack>
    </Container>
    </Stack>
       </>
       ) : (
        <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
            <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
        </HStack>
      )}
      
  </Stack>
  )
}

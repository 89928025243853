import { HStack, Image, Stack } from '@chakra-ui/react'
import React from 'react'
import H2 from '../../components/layouts/Typography/H2'
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`
export default function DrawerHeader({drawerImage,setDrawerOpen,data}) {
  return (
    <HStack mt={"12px"} justifyContent={"space-between"}>
              <Stack width={"375px"}>
                <HStack>
                  {drawerImage && (
                    <>
                      <Image
                        src={drawerImage}
                        width={"275px"}
                        height={"auto"}
                      />
                      <H2> & </H2>
                    </>
                  )}
                </HStack>
                <H2 width={!drawerImage ? "265px" : "auto"}> {data.name}</H2>
              </Stack>
              <Image
                src={closeIcon}
                paddingLeft="20px"
                paddingBottom={"30px"}
                cursor={"pointer"}
                onClick={() => setDrawerOpen()}
              />
            </HStack>
  )
}

import React, { useState } from "react";
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  HStack,
  Image,
  Container,
} from "@chakra-ui/react";

// Core files
import YouTube from "react-youtube";
import { useNavigate, useParams } from "react-router-dom";

// Service Files
import * as getFlimographyData from "../../services/Filmography";
import * as getFilmData from "../../services/Film";
import * as getLandingMasterlistData from "../../services/SectionService";

// Subsection Files
import { explore } from "../../constants/constants";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { isArray } from "lodash";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
import { getImageURL } from "../../util/getImage";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const WatchTrailer = `${IMAGE_BASE_PATH}/ui/Chevron/watchTrailer.png`
const SatyajitRayImg = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/5.png`
const SureshJindal = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

// const artistLinksData = [
//   {
//     name: "Satyajit Ray",

//   },
//   {
//     name: "Suresh Jindal",
//   },
// ];
export default function MainTrailer({ children }) {
  let { alias } = useParams();
  const { isOpen, onClose } = useDisclosure();
  const [filmographyData, setFilmographyData] = React.useState([]);
  const [introData, setIntroData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([{}]);
  const [filmTrailer, setFilmTrailer] = useState({});
  const [filmMainImage, setfilmMainImage] = useState("");
  const [filmDetails, setFilmDetails] = useState({});
  const [watchTrailer, setWatchTrailer] = useState("");
  const winnerName = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getData = async () => {
      const result = await getFlimographyData.filmographyByAlias(masterlistType, winnerName);
      if (result) {
        const data = result;
        setFilmographyData(data);
      }
    };
    getData();
  }, [winnerName, masterlistType]);

  React.useEffect(() => {
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(masterlistType,winnerName);
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it.find((item) => item.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item.key === "masterlist_alias").value}/${
              it.find((item) => item.key === "landing_page_abbreviation").value
            }/${it.find(
              (item) =>
                item.key === "landing_page_abbreviation"
            )?.value==="research-centre" ?`library`:``}
             `,
            title: it.find((item) => item.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    // Get film intro data
    const getFilmIntroData = async () => {
      try {
        const result = await getFilmData.filmByAlias(masterlistType, winnerName);
        if (result) {
          setName(
            () =>
              result?.find((item) => item?.key === "film_title_image_alias")?.value &&
              result?.find((item) => item?.key === "film_title_image_alias")?.value
          );
          setAgraphyLink([
            {
              link: `/research-categories/${
                result?.find((item) => item?.key === "researchCategories")
                  ?.value[0]?.alias
              }`, // replace with the actual link
              title: result?.find((item) => item?.key === "researchCategories")
                ?.value[0]?.value,
            },
            // {
            //   link: `/categories/indian-cinema`,
            //   title: result?.find((item) => item?.key === "subject")?.value,
            // },
          ]);
          setfilmMainImage(
            {
               logoImage:result?.find((item) => item?.key === "ssb_image_alias")?.value,
                 logoLink:`/${masterlistType}/${alias}/introduction/title-image`
               }
           );
           const loc=result?.find((item) => item?.key === "first_release_location")
           ?.value
          setFilmDetails({
            date: result?.find((item) => item?.key === "release_date")?.value,
            location: isArray(
              result?.find((item) => item?.key === "first_release_location")
                ?.value
            )
              ? result?.find((item) => item?.key === "first_release_location")
                  ?.value[0]?.value
              : result?.find((item) => item?.key === "first_release_location")
                  ?.value,
                  link:`location/${loc.toLowerCase()}`
          });
          setFilmTrailer({
            link: `/${masterlistType}/${winnerName}/watch-film-trailer`,
            display: result?.find((item) => item?.key === "trailer_link")
              ?.display,
            image: WatchTrailer,
          });
          setWatchTrailer(
            result?.find((item) => item?.key === "trailer_link")?.value
          );
        }
      } catch (error) {
        console.error("Error fetching flimography data", error);
      }
    };
    getLandingData();
    getFilmIntroData();
    window.scrollTo(0, 0);
  }, [masterlistType, winnerName, alias]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        filmographyData={filmographyData}
        winnerName={winnerName}
        introData={introData}
        name={name}
        filmDetails={filmDetails}
        filmTrailer={filmTrailer}
        agraphyLinks={agraphyLinks}
        filmMainImage={filmMainImage}
        masterlistType={masterlistType}
      />

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        {watchTrailer ? (
          <Trailer name={name} watchTrailer={watchTrailer} />
        ) : (
         <WebpageComingSoon />
        )}
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  filmographyData,
  winnerName,
  onClose,
  introData,
  filmTrailer,
  agraphyLinks,
  filmMainImage,
  filmDetails,
  masterlistType,
  ...rest
}) => {
  const [drawerName, setDrawerName] = useState("")
  const navigate=useNavigate();
  const drawerData = [
    {
    name: "Satyajit Ray",
    title:"Satyajit Ray",
    links: [
      { link_name: "Working Still", link: "" },
      { link_name: "Film Stills", link: "" },
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
    ],
    Image: SatyajitRayImg,
    desc: "",
    masterlist: {
      text: "Visit Satyajit Ray Masterlist →",
      link: "/person-poly/satyajit-ray",
    },
  },{
    name: "Suresh Jindal",
    title:"Suresh Jindal",
    links: [
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
      { link_name: "Interviews", link: "" },
      { link_name: "Newspaper Clippings", link: "" },
    ],
    Image: SureshJindal,
    desc: "",
    masterlist: {
      text: "Visit Suresh Jindal Masterlist →",
      link: "",
    },
  }];

  const oSections = [
    {
      to:`/${masterlistType}/${winnerName}/posts`,
      title: "",
      text: `${explore.POSTS} | ${explore.INSIGHTS}`,
    },
  ];
  
  const trailerLink = {
    link: ``,
    image: WatchTrailer,
  };

  const handleNavigation = () => {
    navigate(-1);
  };

  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        hMenu={agraphyLinks}
        LogoLink={filmMainImage}
        filmReleaseDetails={filmDetails}
        trailerLink={trailerLink}
        // artistLinksData={artistLinksData}
        handleNavigation={handleNavigation}
        setDrawerName={setDrawerName}
        drawerName={drawerName}
        drawerData={drawerData}
        drawerImage={SKK1}
      />
    </>
  );
};

export const Trailer = ({ name, watchTrailer }) => {
  const opts = {
    height: "630px",
    width: "100%",
    playerVars: {
      autoplay: 2, // Change 2 to 1 for boolean value
      rel: 0,
    },
  };
  const onReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <Stack
      width={{ base: "100%", md: "1160px" }}
      bg="white"
      height={"calc(100vh - 64px)"}
    >
      <Container
        minW={"100%"}
        pb="4"
        paddingX={{ base: "4", md: "0px" }}
        marginX={{ base: "0px", md: "30px" }}
      >
        <Box
          position={"fixed"}
          zIndex={999}
          bg="white"
          width={{ base: "auto", md: "1160px" }}
        >
         <HStack paddingTop={{ base: "20px", md: "25px" }} paddingBottom={{ base: "20px", md: "14px" }}>
              <Box paddingRight={{base:"4",md:"0"}} width={{ base: "100%", md: "1130px" }}
              height={{ base: "65px", sm: "85px", md: "105px" }}>
                <Image src={getImageURL(name)} width={"100%"} height={"inherit"} objectFit={"contain"} objectPosition={"left"}></Image>
              </Box>
            </HStack>
        </Box>
        <Box
          paddingY={{ base: "80px", md: "160px" }}
          marginRight={{ base: "0px", md: "30px" }}
        >
          <YouTube
            videoId={watchTrailer.slice(-11)}
            opts={opts}
            onReady={onReady}
          />
        </Box>
      </Container>
    </Stack>
  );
};

import { Box, Container, HStack, Stack } from '@chakra-ui/react'
import React from 'react'
import { FilterButton } from '../../../../../../CommonComponents/FilterButton'
import { CopyPasteService } from '../../../../../../services/CopyPaste'
import InfiniteScroll from 'react-infinite-scroll-component'
import NoMoreToLoad from '../../../../../../CommonComponents/NoMoreToLoad'
import Grid from '../../../../../../FunctionalComponents/SimpleGrid/Grid'
import { DataNotFound } from '../../../../../../CommonComponents/DataNotFound'
import { FilterBox } from '../../../../../../FunctionalComponents/Filter/FilterBox'
import ImageOverlayWithCitation from '../../../../../../FunctionalComponents/SimpleGrid/ImageOverlayWithCitation'
import FilterPills from '../../../../../../components/FilterPills/FilterPills'
//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export default function GridView({
    showFilterBox,
    masterlistType,
    handleFilterClick,
    data,
    fetchMoreData,
    hasMore,
    setShowFilterBox,
    setApply,
    handleCheckboxChange,
    handleSearch,
    filterOptions,
    filteroptionData,
    setFilterOptions,
    setSearchTerm,
    filterCount,
    searchTerm,
    handleReset,
    handleScreeningData,
    filterPills,
    setFilterPills,
    filteredResultCount,
}) {
  
  return (
    <Stack flexDirection={"row-reverse"}>

        {!showFilterBox && <FilterButton marginRight={"-62px"} masterlistType={masterlistType} onClick={handleFilterClick} />}
        <Container
          maxW="auto"
          className="songs"
          px={{ base: "4", md: "0px" }}
          py={"25px"}
          position={"relative"}
        >
          <Stack flexDirection={"row"} gap={4} justifyContent="flex-start">
            {data && data.length > 0 ? (
            <Stack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}`)
                }
                marginRight={{ base: "0px", md: "55px" }}
                alignItems={"flex-start"}
              >
                  <Box bg="white" width={"100%"} position={"sticky"} top={"125px"}>
                  {filterPills && filterPills !== undefined &&
            (filterPills?.search.trim() !== "" || 
            Object.values(filterPills?.filters).some(arr => arr?.length > 0)) && (
              <FilterPills
                filters={filterPills}
                setFilterOptions={setFilterOptions}
                setApply={setApply}
                setFilterPills={setFilterPills}
                filteredResultCount={filteredResultCount}
              />
            )
          }

          </Box>
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    <Box textAlign="center" marginLeft={"50%"}>
                      <Stack marginTop={"20px"}>
                        <img
                          src={loaderImg}
                          width={"100px"}
                          height={"100px"}
                          alt="Loading"
                        />
                      </Stack>
                    </Box>
                  }
                  endMessage={
                  <HStack mt={8}>
                    <NoMoreToLoad />
                  </HStack>
                  }
                >
                  <Grid
                    data={data}
                    handleImageClick={handleScreeningData}
                    spacing={"60px"}
                    columns={{ base: 2, md: 3, lg: 4, xl: 2 }}
                    component={ImageOverlayWithCitation}
                    border={true}
                  />
                </InfiniteScroll>
              </Stack>
            ) 
            : (
              <>
                <Stack
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/1`)
                  }
                  margin={"auto"}
                >
                  <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                  
          </Box>
                  <DataNotFound />
                </Stack>
              </>
            )}
            <HStack
              marginLeft={"4"}
              marginRight={"-62px!important"}
              className="mt-0"
              alignItems={"flex-start"}
              pos={masterlistType === "generic" && "relative"}
              right={masterlistType === "generic" && "-5px"}
            >
              {showFilterBox && (
                <FilterBox
                  setShowFilterBox={setShowFilterBox}
                  setApply={setApply}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                  filteroptionData={filteroptionData}
                  setFilterOptions={setFilterOptions}
                  setSearchTerm={setSearchTerm}
                  filterCount={filterCount}
                  searchTerm={searchTerm}
                  handleReset={handleReset}
                  className={"box-filter-film4"}
                />
              )}
            </HStack>
          </Stack>
        </Container>
      </Stack>
  )
}

import api from "./api";
import { APIS } from "../endpoints";
export const virtualTourData = async (masterlistType, masterlist) => {
      const response = await virtualTourDataAPI(masterlistType, masterlist);
      try {
        if (response) {
          return response;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
}
export const virtualTourDataAPI = async (masterlistType, masterlist) => {
 
  
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.virtualtour}`)
  // const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.econSeminars}`)
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};
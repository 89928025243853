import {
  key_value_amitabh_bachchan_filmography_data,
  key_value_raj_kapoor_filmography_data,
  key_value_satyajit_filmography_data,
  satyajit_filter_options,
} from "../MockData/FilmographyMockup";
import _ from "lodash";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";

// To do need to confirm with hitesh and remove this
export const filmography = async () => {
  const result = await api.get(`${APIS.BASE_URL}${APIS.filmography}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// Get Filmography data of skk but check it once and not required remove it
export const filmographyByAlias = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.filmography}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// Get filmography data of Personality like Satyajit Ray, S.H. Raza
export const getFilmographyDataByMasterlistAPI = async (
  masterlistType,masterlist,filterOptions, page
) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.filmographyPerson}`,{
    params: params
})
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};

export const getFilmographyDataByMasterlist = async (
  masterlistType,masterlist,filterOptions, page
) => {
  if (isTransformationNotAllowed() === true) {
    const { mockData, filterMockData } =
      await getFilmographyDataByMasterlistDummy(masterlist);
    return { mockData, filterMockData };
  } else {
    const response = await getFilmographyDataByMasterlistAPI(
      masterlistType,masterlist,filterOptions, page
    );

    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getFilmographyDataByMasterlistDummy = async (masterlist) => {
    const filmographyDataMap = {
      "satyajit-ray":key_value_satyajit_filmography_data,
      "raj-kapoor":key_value_raj_kapoor_filmography_data,
      "amitabh-bachchan":key_value_amitabh_bachchan_filmography_data
  
      // Add more cases as needed
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const mockData = filmographyDataMap[masterlist] || [];
  const filterMockData = (filterCategories, searchTerm) => {
    if (
      filterCategories.languages.length > 0 ||
      filterCategories.film_type.length > 0 ||
      filterCategories.film_length.length > 0 ||
      searchTerm !== "" ||
      searchTerm !== undefined
    ) {
      const filteredData = _.filter(mockData, (entry) => {
        const languageCheck =
          filterCategories.languages.length > 0
            ? entry
                .find((item) => item.key === "language")
                .value.map((it) => it.name)
                .some((lang) => filterCategories.languages.includes(lang))
            : true;
        const filmTypeCheck =
          filterCategories.film_type.length > 0
            ? filterCategories.film_type.includes(
                entry.find((item) => item.key === "film_type").value
              )
            : true;

        const filmLengthCheck =
          filterCategories.film_length.length > 0
            ? filterCategories.film_length.includes(
                entry.find((item) => item.key === "film_length").value
              )
            : true;

        const searchTermCheck =
          searchTerm !== ""
            ? entry
                .find((item) => item.key === "language")
                .value.map((it) => it.name)
                .some((lang) =>
                  lang.toLowerCase().includes(searchTerm.toLowerCase())
                ) ||
              entry
                .find((item) => item.key === "film_type")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              entry
                .find((item) => item.key === "film_length")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase())
            : true;
        return (
          languageCheck && filmTypeCheck && filmLengthCheck && searchTermCheck
        );
      });
      return filteredData;
    } else {
      return mockData;
    }
  };

  return {
    mockData,
    filterMockData,
  };
};

export const getFilmographyFilterOptionsAPI = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.filmographyFilter}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

export const getFilmographyFilterOptions = async (masterlistType, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const FilterOptions = await getFilmographyFilterOptionsDummyData(
      masterlist
    );
    return FilterOptions;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getFilmographyFilterOptionsAPI(
      masterlistType,
      masterlist
    );
    //for dev site
    // const FilterOptions = {
    //   filmTypeFilter: [
    //     ...new Set(response.map((item) => item.film_type.trim())),
    //   ].filter((value) => value !== ""),
    //   filmLengthFilter: [
    //     ...new Set(response.map((item) => item.film_length.trim())),
    //   ].filter((value) => value !== ""),
    //   languageFilter: [
    //     ...new Set(
    //       response.flatMap((item) =>
    //         item.language.map((lang) => lang.name.trim())
    //       )
    //     ),
    //   ].filter((value) => value !== ""),
    // };

    return response;
  }
};

const getFilmographyFilterOptionsDummyData = (masterlist) => {
  switch (masterlist) {
    case "satyajit-ray":
      return satyajit_filter_options;
    default:
      // Handle the default case if needed
      return satyajit_filter_options;
  }
};

// filmography's Booklet synopsis Data.

export const filmographySynopsisDataAPI = async (masterlistType, alias,filmAlias) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${alias}/body-of-work/filmography/person/${filmAlias}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

export const filmographySynopsisData = async (masterlistType, alias,filmAlias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmographySynopsisDataDummy(alias);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmographySynopsisDataAPI(masterlistType, alias,filmAlias);
    try {
      if (response) {
        return response[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmographySynopsisDataDummy = async (alias) => {
  // Check if the masterlist exists in the map, if not, use a default value
  const filmographySynopsisData =  [];
  return filmographySynopsisData;
};
import {
  Box,
  useColorModeValue,
  useDisclosure,
  Stack,
  Container,
  Text,
  HStack,
  Image,
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";

//Service file
import * as getLandingMasterlistData from "../../services/Location";
import * as getGeographicalMasterlistData from "../../services/Location";
import * as getIntroductionData from "../../services/Location";
import * as getGeographicalBarGraphData from "../../services/Location";
import * as getPostData from "../../services/Post";
import * as getInsightData from "../../services/Insight";

//Component file
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import CountryMap from "./Introduction/CountryMap";

//Language file
import { common, explore, location } from "../../constants/constants";
import H2 from "../../Theme/Typography/H2";
import BarChartSwiper from "./Introduction/BarChartSwiper";
import { LibraryAndArchive } from "../../components/ResearchCentre/LibraryAndArchive";
import { IntegratedTimelineBody } from "../../FunctionalComponents/IntegratedTimeline/IntegratedTimeline";
import { BodyOfWorkBody } from "./BodyOfWork";
import { EconomicsOfCulture } from "./EconomicsOfCulture";
import Accordian from "../../FunctionalComponents/Accordion/Accordian";
import LocationAccordion from "../../FunctionalComponents/Accordion/LocationAccordion";
import AccordianText5 from "../../FunctionalComponents/Accordion/AccordianText5";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`;

export default function Introduction({ children }) {
  const { onClose } = useDisclosure();
  const [introData, setIntroData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const { alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [bookFlag, setBookFlag] = useState(false);
  const [setleftFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bookReadmore, setBookReadmore] = useState(false);
  const [modalcard, setModalcard] = useState(false);
  const [setResearchCentreFlag] = React.useState(true);
  const [horislinkData, setHorislinkData] = useState([]);
  const [postsCount, setPostsCount] = useState(0);
  const [insightsCount, setInsightsCount] = useState(0);

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        alias
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.alias
              }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title:
              result[0].find((item) => item.key === "subject").value +
              "." +
              result[0].find((item) => item.key === "masterlist_type").value,
          },
        ]);
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);
        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLocationLandingPage(
        masterlistType,
        alias
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist_alias")?.value
              }/agraphy/${it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value === "body-of-work"
                ? it?.find((item) => item?.key === "landing_page_abbreviation")
                  ?.value + `/man-made-heritage`
                : it?.find((item) => item?.key === "landing_page_abbreviation")
                  ?.value
              }/${it.find((item) => item.key === "landing_page_abbreviation")
                ?.value === "research-centre"
                ? `all`
                : ``
              }
             `,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };

    const getPostsCount = async () => {
      const result = await getPostData.getPostsByMasterlist(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setPostsCount(result?.data?.length)
      }
    };

    const getInsightsCount = async () => {
      const result = await getInsightData.getInsightsByMasterlist(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setInsightsCount(result?.data?.length)
      }
    };

    getPostsCount();
    getInsightsCount();
    getIntroData();
    getLandingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlistType, aliasAPI]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxW={"1400px"} px="0px">
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          zIndex={99999}
          height="calc(100vh - 64px)"
          aliasAPI={aliasAPI}
          journalDetails={journalDetails}
          introData={introData}
          horislinkData={horislinkData}
          masterlistType={masterlistType}
          name={name}
          postsCount={postsCount}
          insightsCount={insightsCount}
        />
        <Box ml={{ base: 0, md: 52 }} bg="white">
          {children}
          {introData ? (
            <Routes>
              <Route
                path="/introduction"
                element={
                  <IntroductionBody
                    aliasAPI={aliasAPI}
                    masterlistType={masterlistType}
                  />
                }
              />
              <Route
                path="/body-of-work/:tabValue?/:monumentAlias?"
                element={
                  <BodyOfWorkBody
                    masterlist={aliasAPI}
                    masterlistType={masterlistType}
                    aliasAPI={alias}
                  />
                }
              />
              <Route
                path="/economics-of-culture/:tabValue?"
                element={
                  <EconomicsOfCulture
                    masterlist={aliasAPI}
                    masterlistType={masterlistType}
                  />
                }
              />
              <Route
                path="/research-centre/:tabValue?/:bookAlias?/:bookDetails?"
                element={
                  <LibraryAndArchive
                    masterlist={aliasAPI}
                    masterlistType={masterlistType}
                    setResearchCentreFlag={setResearchCentreFlag}
                    bookFlag={bookFlag}
                    setBookFlag={setBookFlag}
                    openDrawer={openDrawer}
                    setOpenDrawer={setOpenDrawer}
                    setleftFlag={setleftFlag}
                    bookReadmore={bookReadmore}
                    setBookReadmore={setBookReadmore}
                    className={"top-to-btm4"}
                  />
                }
              />
              <Route
                path="/integrated-timeline/:tabIndex?"
                element={
                  <IntegratedTimelineBody
                    modalcard={modalcard}
                    setModalcard={setModalcard}
                    aliasAPI={aliasAPI}
                    masterlistType={masterlistType}
                  />
                }
              />
            </Routes>
          ) : (
            <Text
              py={"40"}
              fontSize={"45px"}
              fontWeight="700"
              textAlign="center"
            >
              {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
          {/* <IntroductionBody aliasAPI={aliasAPI} /> */}
        </Box>
      </Box>
    </Container>
  );
}
const SidebarContent = ({
  onClose,
  aliasAPI,
  journalDetails,
  introData,
  masterlistType,
  horislinkData,
  insightsCount,
  postsCount,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };

  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/posts`,
      title: "",
      text: postsCount > 0 ? `${explore.POSTS} (${postsCount})` : explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/insights`,
      title: "",
      text: insightsCount > 0 ? `${explore.INSIGHTS} (${insightsCount})` : explore.INSIGHTS,
    },
  ];

  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        leftFlag={true}
        aliasAPI={aliasAPI}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        handleNavigation={handleNavigation}
      />
    </>
  );
};
const IntroductionBody = ({ aliasAPI, masterlistType }) => {
  const [geographicaldetail, setGeographicaldetail] = useState([false]);
  const [landmark, setLandmark] = useState([]);
  const [accordianData, setAccordianData] = useState([]);
  const [populationBarData, setPopulationBarData] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [graphKey, setGraphKey] = useState("");

  React.useEffect(() => {
    const getGeographicalData = async () => {
      const result =
        await getGeographicalMasterlistData.getGeographicalDataByAlias(
          "geographical",
          aliasAPI
        );
      result.map(
        (item) =>
          item.key === "geographicaldata" && setGeographicaldetail(item.value)
      );
      result.map((item) => item.key === "landmark" && setLandmark(item.value));
    };
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        aliasAPI
      );
      if (result && result?.length > 0) {
        const archLandMark = result[0]?.find(
          (nestedItem) => nestedItem?.key === "architectural_landmarks"
        );
        const otherLandMark = result[0]?.find(
          (nestedItem) => nestedItem?.key === "other_landmarks"
        );
        const state = result[0]?.find(
          (nestedItem) => nestedItem?.key === "states"
        );
        const is_capital_city = result[0]?.find(
          (nestedItem) => nestedItem?.key === "is_capital_city"
        );
        const longitude = result[0]?.find(
          (nestedItem) => nestedItem?.key === "longitude"
        );
        const latitude = result[0]?.find(
          (nestedItem) => nestedItem?.key === "latitude"
        );
        const population = result[0]?.find(
          (nestedItem) => nestedItem?.key === "population"
        );
        const currentArea = result[0]?.find(
          (nestedItem) => nestedItem?.key === "current_area"
        );
        const languages = result[0]?.find(
          (nestedItem) => nestedItem?.key === "languages"
        );
        const rivers = result[0]?.find(
          (nestedItem) => nestedItem?.key === "rivers"
        );
        const lakes = result[0]?.find(
          (nestedItem) => nestedItem?.key === "lakes"
        );
        const geoDetails = [
          state,
          is_capital_city,
          longitude,
          latitude,
          population,
          currentArea,
          languages,
          rivers,
          lakes,
        ];
        const formattedData = formatData(
          geoDetails,
          archLandMark,
          otherLandMark
        );
        setAccordianData(formattedData);
      }
    };
    getIntroData();
    getGeographicalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlistType, aliasAPI]);

  const showGraph = (graphKey) => {
    if (graphKey === "population") {
      getBarGraphData("gender-demography");
      setGraphKey(graphKey)
      //need to be dynamic
      setDrawerTitle("Population Demographics of Lucknow");
    } else if (graphKey === "languages") {
      setGraphKey(graphKey)
      getBarGraphData("language-demography");
      //need to be dynamic
      setDrawerTitle("Spoken Language of Lucknow");
    }
  };
  const getBarGraphData = async (barAlias) => {
    const result =
      await getGeographicalBarGraphData.getGeographicalBarDataByAlias(
        barAlias,
        masterlistType,
        aliasAPI
      );
    setPopulationBarData([result]);
  };
  function formatData(geoDetails, archLandMark, otherLandMark) {
    const components = [
      {
        name: `${location?.GEOGRAPHICAL_DETAILS}`,
        data: archLandMark,
        component: (
          <LocationAccordion
            personalia={
              geoDetails?.length > 0 ? geoDetails : common.CONTENT_AWAITED
            }
            showGraph={showGraph}
          />
        ),
      },
      {
        name: `${archLandMark?.display}`,
        data: archLandMark,
        component: (
          <AccordianText5
            data={
              archLandMark?.value?.length > 0
                ? archLandMark?.value.map((item) => item.name)
                : common.CONTENT_AWAITED
            }
          />
        ),
      },
      {
        name: `${otherLandMark?.display}`,
        data: otherLandMark,
        component: (
          <AccordianText5
            data={
              otherLandMark?.value?.length > 0
                ? otherLandMark?.value.map((item) => item.name)
                : common.CONTENT_AWAITED
            }
          />
        ),
      },
    ];

    return components;
  }
  const [showAccordian, setShowAccordian] = useState(true);

  //future Use
  // const chartData = [
  //   {
  //     title: 'Gender Demography',
  //     xAxisData: ['Male', 'Female'],
  //     seriesData: {
  //       population2001: [2000000, 1600000],
  //       population2011: [2300000, 2100000],
  //     },
  //   },
  //   {
  //     title: 'Religious Demography',
  //     xAxisData: [
  //       'Hindu', 'Muslim', 'Christian', 'Sikh', 'Buddhist',
  //       'Jain', 'Others', 'No stated'
  //     ],
  //     seriesData: {
  //       population2001: [1900000, 300000, 100000, 50000, 20000, 10000, 5000, 1000],
  //       population2011: [3500000, 350000, 150000, 60000, 25000, 12000, 7000, 1500],
  //     },
  //   },
  //   {
  //     title: 'Caste Demography',
  //     xAxisData: ['Scheduled Caste', 'Scheduled Tribe'],
  //     seriesData: {
  //       population2001: [800000, 50000],
  //       population2011: [950000, 55000],
  //     },
  //   },
  // ];

  return (
    <>
      <Container maxW={"100%"} position={"relative"} px="0px">
        <CountryMap setShowAccordian={setShowAccordian} landmark={landmark} geographicaldetail={geographicaldetail} />
        <HStack alignItems={"flex-start"}>
          {drawerTitle !== "" && (
            <Stack
              minHeight="100vh"
              width={{ base: "100%", md: "425px" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Stack
                zIndex={"99"}
                alignItems="flex-start"
                bg={"#ffffffa3"}
                p="35px"
              >
                <HStack
                  justifyContent={"space-between"} width={"100%"} alignItems={"flex-start"}>
                  <H2 width="75%" fontWeight={"700"} lineHeight={"23.48px"}>{drawerTitle}</H2>
                  <Image
                    src={closeIcon}
                    cursor={"pointer"}
                    onClick={() => {
                      setDrawerTitle("");
                    }}
                    width={"20px"}
                    height={"20px"}
                    objectFit={"contain"}
                  />
                </HStack>
                <Stack>
                  <HStack>
                    <BarChartSwiper data={populationBarData}  graphKey={graphKey} />
                  </HStack>
                </Stack>
              </Stack>
            </Stack>
          )}
          {showAccordian && (
            <Stack
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={"flex-end"}
              width={{ base: "100%", md: "100%" }}
              px={{ base: 4, md: 0 }}
              height={{ base: "auto", md: "calc(100vh - 75px)" }}
              pb={4}
            >
              <Stack
                position={"fixed"}
                bg={"#ffffffa3"}
                zIndex={9999}
                marginTop="4"
                marginRight={"8"}
              >
                <Box
                  width={{ base: "100%", md: "296px" }}
                  pl={{ base: 4, md: 4 }}
                >
                  {accordianData.length > 0 && (
                    <Accordian data={accordianData} />
                  )}
                </Box>
              </Stack>
            </Stack>
          )}
        </HStack>
      </Container>
    </>
  );
};

import { Box, Container, HStack, Text, Stack, Image } from "@chakra-ui/react";

//Core file
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

//Service files
import * as getMonumentDetailData from "../../../../services/Location";

//Language file
import { common, explore, location } from "../../../../constants/constants";
import AccordianText from "../../../../FunctionalComponents/Accordion/AccordianText";
import Accordian from "../../../../FunctionalComponents/Accordion/Accordian";
import RelatedArtWork from "./RelatedArtWork";
import { EffectFade, Navigation } from "swiper";
import { getImageURL } from "../../../../util/getImage";
//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`;

export default function DetailPage() {
  return (
    <Container maxW={"1400px"} px="0px">
      <DetailPageBody />
    </Container>
  );
}

const DetailPageBody = () => {
  const { alias, monumentAlias } = useParams();
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [accordianData, setAccordianData] = useState([]);
  const [monumentImages, setMonumentImage] = useState([]);

  console.log(monumentImages, "monumentImages");
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getMonumentDetailData.getMonumentDataByAlias(
          masterlistType,
          alias,
          monumentAlias
        );
        if (result) {
          const preface = result[0]?.find(
            (nestedItem) => nestedItem?.key === "preface"
          )?.value;
          const patron = result[0]?.find(
            (nestedItem) => nestedItem?.key === "patron"
          );
          const architectStyle = result[0]?.find(
            (nestedItem) => nestedItem?.key === "architectural_style"
          );
          const architectStructure = result[0]?.find(
            (nestedItem) => nestedItem?.key === "structure"
          );
          const layout = result[0]?.find(
            (nestedItem) => nestedItem?.key === "layout"
          );
          const materialUsed = result[0]?.find(
            (nestedItem) => nestedItem?.key === "materials_used"
          );
          const event = result[0]?.find(
            (nestedItem) => nestedItem?.key === "event"
          );
          const status = result[0]?.find(
            (nestedItem) => nestedItem?.key === "status"
          );
          const image = result[0]?.find(
            (nestedItem) => nestedItem?.key === "image_alias"
          )?.value;
          const formattedData = formatData(
            preface,
            patron,
            architectStyle,
            architectStructure,
            layout,
            materialUsed,
            event,
            status
          );
          setMonumentImage([...monumentImages, image]);
          setAccordianData(formattedData);
        }
      } catch (error) {
        console.error("Error fetching monument data:", error);
      }
    };

    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monumentAlias]);
  function formatData(
    introData,
    patron,
    architectStyle,
    architectStructure,
    layout,
    materialUsed,
    event,
    status
  ) {
    const components = [
      {
        name: `${explore.PREFACE}`,
        data: introData,
        component: (
          <AccordianText
            data={introData !== null ? introData : common.CONTENT_AWAITED}
          />
        ),
      },
      {
        name: `${patron.display}`,
        data: introData,
        component: (
          <AccordianText
            data={
              patron.value !== null && patron.value !== ""
                ? patron.value
                : common.CONTENT_AWAITED
            }
          />
        ),
      },
      {
        name: `${architectStyle.display}`,
        component: (
          <AccordianText
            data={
              architectStyle.value !== null && architectStyle.value !== ""
                ? architectStyle.value
                : common.CONTENT_AWAITED
            }
          />
        ),
      },
      {
        name: `${architectStructure.display}`,
        component: (
          <AccordianText
            data={
              architectStructure.value !== null &&
              architectStructure.value !== ""
                ? architectStructure.value
                : common.CONTENT_AWAITED
            }
          />
        ),
      },
      {
        name: `${layout.display}`,
        component: (
          <AccordianText
            data={
              layout.value !== null && layout.value !== ""
                ? layout.value
                : common.CONTENT_AWAITED
            }
          />
        ),
      },
      {
        name: `${materialUsed.display}`,
        component: (
          <AccordianText
            data={
              materialUsed.value !== null && materialUsed.value !== ""
                ? materialUsed.value
                : common.CONTENT_AWAITED
            }
          />
        ),
      },
      {
        name: `${event.display}`,
        component: (
          <AccordianText
            data={
              event.value !== null && event.value !== ""
                ? event.value
                : common.CONTENT_AWAITED
            }
          />
        ),
      },
      {
        name: `${status.display}`,
        component: (
          <AccordianText
            data={
              status.value !== null && status.value !== ""
                ? status.value
                : common.CONTENT_AWAITED
            }
          />
        ),
      },
    ];

    return components;
  }

  return (
    <>
      {/* Read More back navigation Remaining */}
      <>
        <Container maxW="auto" px="0" className="songs">
          <Stack
            height="calc(100vh - 64px)"
            position={"relative"}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Stack
              justifyContent={"space-evenly"}
              width={{ base: "100%", md: "775px" }}
              alignItems={"flex-start"}
              // marginTop={"64px"}
              id="biblographysliderskkdrawer"
              height="calc(100vh - 64px)"
              flexDirection={"row"}
            >
              <Swiper
                effect={"fade"}
                grabCursor={true}
                pointerEvents="auto"
                navigation={true}
                modules={[EffectFade, Navigation]}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {monumentImages &&
                  monumentImages?.length > 0 &&
                  monumentImages.map((it, index) => {
                    return (
                      <SwiperSlide>
                        <Image
                          src={getImageURL(it)}
                          width={"100%"}
                          height={"100%"}
                          objectFit={"contain"}
                          fallbackSrc={placeholderImg}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Stack>
            <HStack
              zIndex={"99"}
              alignItems="flex-start"
              overflowY={"scroll"}
              borderLeft={"1px"}
              borderColor={"#e8e8e8"}
              className="mt-0"
            >
              <Box px="5" width={{ base: "100%", md: "425px" }}>
                {accordianData?.length > 0 && (
                  <Accordian data={accordianData} />
                )}
              </Box>
            </HStack>
          </Stack>
        </Container>
        <Container
          maxW="auto"
          px={{ base: "4", md: "16" }}
          py={{ base: "4", md: "8" }}
          mx={"0"}
        >
          <Stack>
            <Box>
              <Text
                color={"#333333"}
                textAlign={"left"}
                fontSize={"16px"}
                fontWeight={"700"}
                lineHeight={"18px"}
              >
                {location.RELATED_ARTWORKS}
              </Text>
            </Box>
            <RelatedArtWork />
          </Stack>
        </Container>
      </>
    </>
  );
};

import { HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Small from '../../Theme/Typography/Small';
import { filmography } from '../../constants/constants';
import H4 from '../../Theme/Typography/H4';

const TimelineWithSubText = ({ data, setOpenDrawer, handleBooklet,handleDrawer }) => {
    return (
        <VerticalTimeline
            layout={"1-column-left"}
            className="layout"
        >
            {data?.map((item, index) => (
                <VerticalTimelineElement
                    id="satyajit_timeline"
                    key={index}
                    contentStyle={{ fontSize: "sm" }}
                    iconStyle={{
                        background: "#fff",
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "700",
                    }}
                    icon={item.year}
                >
                    <Stack
                        p="0"
                        flexDirection={"inherit"}
                        flexWrap="wrap"
                        gap="1"
                        pb="4"
                    >
                        <HStack>
                            {item.link ? (
                                <H4
                                    color="color.blue"
                                    className="m-0"
                                    width={{ base: "100%", md: "750px" }}
                                    cursor={"pointer"}
                                    onClick={
                                        handleDrawer(item)
                                    }
                                >
                                    {`${item?.desc} `}
                                </H4>
                            ) : (
                                <H4
                                color="color.blue"
                                className="m-0"
                                width={{ base: "100%", md: "750px" }}
                                cursor={"pointer"}
                                onClick={
                                 ()=>handleDrawer(item)
                                }>
                                    {`${item?.desc} `}
                                    
                                </H4>
                            )}
                        </HStack>
                        <HStack mt={"0px !important"}>
                            {item.link ? (
                                <H4
                                    className="mt-0"
                                    width={{ base: "100%", md: "750px" }}
                                    onClick={() => setOpenDrawer(true)}
                                    cursor={"pointer"}
                                >
                                    {/* {item?.shortdesc} */}
                                    <Small fontWeight={500} onClick={() => handleBooklet(`${item?.bookletAlias}/synopsis`)} color={"color.blue"} cursor={"pointer"}>
                                        {` | `}{filmography.BOOKLET}
                                    </Small>
                                </H4>
                            ) : (
                                <Small
                                    className="mt-0"
                                    width={{ base: "100%", md: "750px" }}
                                >
                                    {item?.shortdesc}
                                   {item?.bookletAlias && <Small as="span" onClick={() => handleBooklet(`${item?.bookletAlias}/synopsis`)} color={"color.blue"} cursor={"pointer"}>
                                        {` | `}{filmography.BOOKLET}
                                    </Small>}
                                </Small>
                            )}
                        </HStack>
                    </Stack>
                </VerticalTimelineElement>
            ))}
        </VerticalTimeline>
    );
};

export default TimelineWithSubText;
import { masterlistsData } from "../MockData/MastrlistsData";
import { APIS } from "../endpoints";
import api from "../services/api";

export const getMasterlistData = async (filterOptions,page) => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return masterlistsData;
  } else {
    const response = await getMasterlistDataAPI(filterOptions,page);
    try{
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e){
      console.error("Something is wrong", e)
    }
  }
};

export const getMasterlistDataAPI = async (filterOptions,page) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);
  const result = await api.get(`${APIS.BASE_URL}${APIS.explore}/${APIS.masterlists}`,{
    params: params
})
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};

export const fetchMasterlistAccess = async (alias) => {
  const response = await api.get(`${APIS.BASE_URL}${APIS.hasAccess}/${alias}/hasAccess`,); // Adjust based on your API endpoint
  return response.data.hasAccess; // Adjust based on your API response structure
};
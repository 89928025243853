import {
  Box,
  HStack,
  Image,
  List,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import H1 from "../../Theme/Typography/H1";
import H4 from "../../Theme/Typography/H4";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`;
const profile = `${IMAGE_BASE_PATH}/ui/Chevron/profile.svg`;
const upload = `${IMAGE_BASE_PATH}/ui/Chevron/uploadSign.svg`;

export default function UserDetails({
  data,
  masterlistData,
  setMasterlistData,
  setShowBookmark,
  showMasterlists,
  setShowMasterlists,
  setSuccessCard,
  rcData,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Determine if the user is on a mobile view
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleRcMasterlist = (item) => {
    const masterlist = rcData.filter((it) => it.alias === item?.alias);
    setMasterlistData(masterlist);
    setShowMasterlists(true);
    setSuccessCard(false);
    setShowBookmark(false);
    if (isMobile) {
      onOpen(); // Open modal for mobile view
    }
  };

  const handleClosePopup = () => {
    setMasterlistData([]);
    setShowMasterlists(false);
    onClose(); // Close modal for mobile view
  };

  return (
    <Box width="100%">
      <HStack gap={{ base: "20px", md: "12px" }} alignItems={"flex-start"}>
        <Box width={{ base: "64px", md: "90px" }} height={{ base: "64px", md: "90px" }}>
          <Image width={"100%"} src={profile} fallbackSrc="" />
          <Box position={"relative"} top={"-28px"} left={{ base: "50px", md: "70px" }}>
            <Image cursor={"pointer"} width={"24px"} height={"24px"} src={upload} fallbackSrc="" />
          </Box>
        </Box>
        <Box className="ml-0">
          <H1
            fontSize={{ base: "24px", md: "34px" }}
            lineHeight={{ base: "30px", md: "39.92px" }}
            pb="5px"
            color={"color.primary"}
          >{`${data?.first_name} ${data?.last_name}`}</H1>
          <H1 fontSize={{ base: "16px", md: "24px" }} fontWeight="400" lineHeight={{ base: "18px", md: "28.18px" }}>
            {`${data.currentUserStage.stage.level}.${data.currentUserStage.stage.stage}`}
          </H1>
        </Box>
      </HStack>
      <HStack align={"flex-start"} gap={"10px"}>
        <VStack align="start" paddingTop={"10px"} spacing={2}>
          {rcData &&
            rcData.length > 0 &&
            rcData.map((item) => (
              <H1
                fontSize={{ base: "18px", md: "24px" }}
                lineHeight={{ base: "22px", md: "28.18px" }}
                className="mt-0"
                paddingTop={"12px"}
                onClick={() => handleRcMasterlist(item)}
                color={"color.blue"}
                cursor={"pointer"}
                fontWeight={item.alias === (masterlistData.length > 0 && masterlistData[0].alias) ? 700 : 500}
              >
                {`${item?.value} (${item?.masterlists?.length})`}
              </H1>
            ))}
        </VStack>

        {/* For desktop view */}
        {!isMobile && (
          <VStack align="start" paddingTop={"15px"}>
            {showMasterlists && (
              <Box border="1px solid" borderColor="color.primary" padding="30px" width="310px" position="absolute">
                <Image
                  src={closeIcon}
                  position="absolute"
                  top="5"
                  right="5"
                  cursor={"pointer"}
                  onClick={handleClosePopup}
                />
                <List spacing={2}>
                  {masterlistData[0]?.masterlists &&
                    masterlistData[0]?.masterlists.length > 0 &&
                    masterlistData[0]?.masterlists
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((item, index) => (
                        <HStack alignItems={"flex-start"}>
                        <H4 fontWeight={600} lineHeight={"18.78px"} key={index}>
                        {`${index + 1}. `}
                        </H4>
                        <H4 fontWeight={600} lineHeight={"18.78px"} key={index}>
                        {`${item?.name}`}
                        </H4>
                        </HStack>
                      ))}
                </List>
              </Box>
            )}
          </VStack>
        )}

        {/* For mobile view: Modal Popup */}
        {isMobile && (
          <Modal isOpen={isOpen} onClose={handleClosePopup} >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader py="2">Masterlist</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <List spacing={2}>
                  {masterlistData[0]?.masterlists &&
                    masterlistData[0]?.masterlists.length > 0 &&
                    masterlistData[0]?.masterlists
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((item, index) => (
                        <H4 fontWeight={600} lineHeight={"18.78px"} key={index}>
                          {`${index + 1}. ${item?.name}`}
                        </H4>
                      ))}
                </List>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </HStack>
    </Box>
  );
}

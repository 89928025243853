import { Stack } from "@chakra-ui/react";

//Core files
import React, { useEffect, useRef, useState } from "react";
import * as getExhibitData from "./../../../../services/EventExhibition";

//Component files
import GridView from "./Exhibits/GridView";
import TabularView from "./Exhibits/TabularView";
import {  useParams } from "react-router-dom";
import { totalFilterCount, updateFacetsData } from "../../../../util/helper";

export const Exhibits = ({
    winnerName,
    visible
}) => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState({ search: "", filters: {}, sortBy_v: "" });
  const [apply, setApply] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [ImgData, setImgData] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { alias } = useParams();
  const [filterCount, setFilterCount] = useState(0);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType=parts[1];
  const [filterPills, setFilterPills] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  // const navigate = useNavigate()
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches", e.target.value)
  };
  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }

    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }

    if (selectedString !== type) {
      selectedString = type;
    }

    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };

  const citationFactor = (data) => {
    const findItem = (items, key) => items.find(it => it.key === key) || {};
    const getValue = (item, defaultValue = "") => item?.value || defaultValue;
    const getDisplay = (item, defaultValue = "") => item?.display || defaultValue;
  

    const formatSize = (length, base, unit) => {
      if (length && base) return `${parseFloat(length).toFixed(2)} x ${parseFloat(base).toFixed(2)} ${unit}`;
      if (length) return `${parseFloat(length).toFixed(2)} ${unit}`;
      if (base) return `${parseFloat(base).toFixed(2)} ${unit}`;
      return '';
    };

    return data.map((item) => {
      const mediumArtworkItem = findItem(item, "medium_of_artwork");
      const mediumArtwork = getValue(mediumArtworkItem) ? mediumArtworkItem : null;
   
      const actualDateItem = findItem(item, "actual_date");
      const actualDateValue = actualDateItem ? getValue(actualDateItem) : null;

      // Construct the mediumDate string
      const mediumDate = `${mediumArtwork?.value || ''}${actualDateValue ? `, ${actualDateValue}` : ''}`;

      // Update mediumArtwork if it is not null or undefined
      if (mediumArtwork && mediumDate) {
          mediumArtwork.value = mediumDate;
      }

      // Find the size items
      const sizeItem = findItem(item, "length_cm");
      const sizeItemIn = findItem(item, "length_in");
      const baseItem = findItem(item, "base_cm");
      const baseItemIn = findItem(item, "base_in");

      // Get values for length and base in both cm and in
      const lengthVal = getValue(sizeItem);
      const lengthValIn = getValue(sizeItemIn);
      const baseVal = getValue(baseItem);
      const baseValIn = getValue(baseItemIn);

      // Construct the size value for inches and centimeters
      const sizeValueIn = formatSize(lengthValIn, baseValIn, 'in');
      const sizeValueCm = formatSize(lengthVal, baseVal, 'cm');

      // Combine size values, ensuring no extra spaces if one value is missing
      const sizeValue = [sizeValueIn, sizeValueCm].filter(Boolean).join(" (") + (sizeValueCm ? ")" : "");

      let sizeData = {};

      if (sizeValue)
      {
        sizeData = {
          display: getDisplay(sizeItem), // Provide fallback for display
          value: sizeValue // Provide fallback for value
        };
      }
      
      const titleItem = findItem(item, "title");
      const titleOfArtwork = getValue(titleItem) ? titleItem : null;
      const fullImage = getValue(findItem(item, "direct_url_of_cover_image"));
      const year = getValue(findItem(item, "sortable_year"));
  
      return {
        image: fullImage,
        desc: getValue(findItem(item, "bibliographic_citation")),
        listDesc: getValue(findItem(item, "bibliographic_caption")),
        content: getValue(findItem(item, "description")),
        title: getValue(findItem(item, "creator_name")),
        fullImg: fullImage,
        essayistData: [titleOfArtwork, mediumArtwork, sizeData],
        link: item?.link,
        year
      };
    });
  };

  const [facets, setFacets] = useState([]);
  const [sort, setSort] = useState([]);
  const initialLoadComplete = useRef(false);

  async function fetchData() {
    try {
      const result = await getExhibitData.exhibitData(masterlistType, alias, filterOptions, 1);
      if (result?.data.length > 0) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        if (!initialLoadComplete.current) {
          setPage((prevPage) => prevPage + 1);
          initialLoadComplete.current = true;
        }
        if (result?.total <= result?.data.length) {
          setHasMore(false)
        }

        if (result?.facetsHeaders) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
          if (formattedFacets && formattedFacets.length > 0) {
            setFilterOptions(prevFilterOptions => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                ...formattedFacets.reduce((acc, facet) => {
                  acc[facet.key] = [];
                  return acc;
                }, {})
              }
            }));
          }
        }
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {

    fetchData();
    setFilterPills(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = async () => {
    try {
      const result = await getExhibitData.exhibitData(masterlistType, alias, filterOptions, page);

      if (result) {

        const processedData = citationFactor(result?.data);
        setData(prevData => [...prevData, ...processedData]);
        setPage(prevPage => prevPage + 1);
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);

        if (data.length >= result?.total) {
          setHasMore(false);
        }

      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function fetchFilterData(page = 1) {

    try {
      const result = await getExhibitData.exhibitData(masterlistType, alias, filterOptions, page);

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        if (data.length >= result?.total) {
          setHasMore(false);
        }
        const filtercnt= totalFilterCount(filterOptions?.filters)
        
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {

    if (apply) {
      setApply(false);
      fetchFilterData();
      setFilterPills(filterOptions)
      const filtercnt= totalFilterCount(filterOptions?.filters)
      setFilterCount(filtercnt)
    }
    const filtercnt = totalFilterCount(filterOptions?.filters)
    setFilterCount(filtercnt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const handleReset = () => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setFilterCount(0)
    setSearchTerm("");
  }
  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  return (
    <Stack position={"relative"}>
      {visible ? (
        <>
          <GridView
            winnerName={winnerName}
            //data
            showFilterBox={showFilterBox}
            handleFilterClick={handleFilterClick}
            data={data}
            setFilterPills={setFilterPills}
            filterPills={filterPills}
            setImgData={setImgData}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            setShowFilterBox={setShowFilterBox}
            setApply={setApply}
            apply={apply}
            handleCheckboxChange={handleCheckboxChange}
            handleSearch={handleSearch}
            filterOptions={filterOptions}
            filteroptionData={facets}
            setFilterOptions={setFilterOptions}
            setSearchTerm={setSearchTerm}
            filterCount={filterCount}
            searchTerm={searchTerm}
            handleReset={handleReset}
            handleImageClick={handleImageClick}
            fullScreenImage={fullScreenImage}
            ImgData={ImgData}
            clickedImageIndex={clickedImageIndex}
            setClickedImageIndex={setClickedImageIndex}
            showImages={showImages}
            sort={sort}
            filteredResultCount={filteredResultCount}
          />
        </>
      ) : (
        <>
          <TabularView
            setFilterPills={setFilterPills}
            filterPills={filterPills}
            showFilterBox={showFilterBox}
            winnerName={winnerName}
            handleFilterClick={handleFilterClick}
            data={data}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            setShowFilterBox={setShowFilterBox}
            setApply={setApply}
            apply={apply}
            setImgData={setImgData}
            handleCheckboxChange={handleCheckboxChange}
            handleSearch={handleSearch}
            filterOptions={filterOptions}
            filteroptionData={facets}
            setFilterOptions={setFilterOptions}
            setSearchTerm={setSearchTerm}
            filterCount={filterCount}
            searchTerm={searchTerm}
            handleReset={handleReset}
            handleImageClick={handleImageClick}
            fullScreenImage={fullScreenImage}
            ImgData={ImgData}
            clickedImageIndex={clickedImageIndex}
            setClickedImageIndex={setClickedImageIndex}
            showImages={showImages}
            sort={sort}
            filteredResultCount={filteredResultCount}
          />
        </>
      )}
    </Stack>
  );
};

import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

// Core Files
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { EffectFade, EffectFlip, Navigation, Pagination } from "swiper";

// Service Files
import * as getFilmCastData from "../../../../services/Film";
import { getVerticalLayoutData } from "../../../../services/BoxOffice";

// Subsection Files
import H5 from "../../../../components/layouts/Typography/H5";
import { isArray } from "lodash";
import DrawerHeader from "../../../../FunctionalComponents/CommonDrawer/DrawerHeader.js";
import DrawerBody from "../../../../FunctionalComponents/CommonDrawer/DrawerBody.js";
import CommonDrawer from "../../../../FunctionalComponents/CommonDrawer/CommonDrawer.js";import { useNavigate, useParams } from "react-router-dom";
import { getImageURL } from "../../../../util/getImage";
import { common } from "../../../../constants/constants.js";
import { formatName } from "../../../../util/helper.js";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

function VerticalLayout({ winnerName, setCastTabFlag, masterlistType ,name}) {
  const [slider, setSlider] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {drawerId}=useParams();
  React.useEffect(() => {
    const getData = async () => {
      const result = await getVerticalLayoutData();
      if (result) {
        setSlider(result);
      }
    };
    getData();
  }, []);


  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [synopsisFullScreenImage, setSynopsisFullScreenImage] = useState(false);
  const [synopsisImgData, setsynopsisImgData] = useState(null);
  const [drawerData, setDrawerData] = useState(null);
  const navigate = useNavigate();

  const showSynopsisImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(image);
    } else {
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(null);
    }
  };
  //key value
  const [castData, setCastData] = useState([{}]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      const result = await getFilmCastData.filmCreditsData(
        masterlistType,
        winnerName
      );
      if (result && result?.length > 0) {
        setLoading(false);
        setCastData(
          result
            .map((item) => {
              if (item?.key !== "alias") {
                return {
                  display: item?.display,
                  anothervalue: item?.value,
                };
              }
              return null; // Skip the "alias" key
            })
            .filter(Boolean)
        );
        if(result && result?.length > 0 && drawerId){
          const record = result.find(item => 
            Array.isArray(item?.value) && item?.value.some(subItem => subItem.alias === drawerId)
        );

      if(record!==undefined){
        setDrawerData(() => drawerFormation(record.value[0]))
        setDrawerOpen(true)
      }
    }
        
      } else {
        setLoading(true);
      }
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlistType, winnerName]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const drawerFormation = (data) => {
    return {
      name: data.full_name || data.name, 
      links: [
        { link_name: common.WORKING_STILLS,
          link: `/${masterlistType}/${winnerName}/agraphy/research-centre/library`,
        },
        { link_name: common.FILM_STILLS,
          link: `/${masterlistType}/${winnerName}/agraphy/research-centre/library`,
        },
        {
          link_name: common.BOOKS,
          link: `/${masterlistType}/${winnerName}/agraphy/research-centre/library`,
        },
      ],
      Image: ``,
      masterlist: {
        text: `Visit ${data?.full_name || data.title} Masterlist →`,
        link: `/generic/${data?.alias}`,
      },
    };
  };
  const handleCastDrawer=(item)=>{
    navigate(item?.alias)
    setDrawerData(() => drawerFormation(item));
    setDrawerOpen(true)
  }
  const handleClose = () => {
    setDrawerOpen(false);
    navigate(`/${masterlistType}/${winnerName}/agraphy/body-of-work/credit-cast`);

  };
  return (
    <>
      <ImagePopupModal
        isOpen={synopsisFullScreenImage}
        onClose={() => showSynopsisImages(null, false)}
        imageData={synopsisImgData}
        data={slider}
        clickedImageIndex={clickedImageIndex}
        setCastTabFlag={setCastTabFlag}
      />
     { loading ?
     <Container maxW="auto" py="5" display={"flex"} justifyContent={"center"} >
     {" "}
     <Image src={loaderImg} />
   </Container>
   :
      <Stack
        flexDirection={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        paddingY={{ base: "26px", md: "" }}
        gap={10}
        bg={"white"}
        position={"relative"}
        // height={{xl:"100%", '2xl':"calc(100vh - 280px)"}}
      >
        {/* Drawer code start */}
        {drawerData !== undefined && drawerData!==null && (
          <CommonDrawer
            setDrawerOpen={handleClose}
            drawerOpen={drawerOpen}
            data={drawerData}
            drawerImage={getImageURL(name)}
            drawerHeader={<DrawerHeader drawerImage={getImageURL(name)} setDrawerOpen={handleClose} data={drawerData}/>}
            drawerBody={<DrawerBody data={drawerData}/>}
          />
        )}
        {/* Drawer code end */}

        <HStack
          marginTop="0.0rem!important"
          flexDirection={"column"}
          className="col-7"
          pl="0px"
          gap="10px"
          paddingTop={{ base: "28px", md: "1" }}
          alignItems={"flex-start"}
          bg={"white"}
        >
          {!loading && castData && castData?.length>0 &&
            castData.map((item) => {
              return (
                //need to worrk on Css
                <>
                { 
                  (item?.anothervalue!==undefined && item?.anothervalue!==null && item?.anothervalue.length>0) &&
                <Stack flexDirection={"row"} className="ml-0" gap="5px">
                  <HStack className="ml-0 px-0">
                    <H5 width="185px">{item?.display}</H5>
                  </HStack>
                  <HStack className="ml-0 mt-1 px-0" wrap={"wrap"}>
                    {item?.anothervalue && isArray(item?.anothervalue) ? (
                      item?.anothervalue.map((it, index) => {
                        return (
                          <React.Fragment key={index}>
                            {(it?.name || it?.full_name) && (
                              <>
                              <Text
                                onClick={() => handleCastDrawer(it)}
                                className="textWrap"
                                marginInlineStart={"0px!important"}
                                fontSize={"15px"}
                                fontWeight="400"
                                color="#035DA1"
                                cursor={"pointer"}
                              >
                                {it?.full_name ? it?.full_name : it?.name}
                                {index < item?.anothervalue.length - 1 &&
                                    <span style={{ marginRight: "4px" }}>,</span>
                                  } 
                              </Text>
                              </>
                            ) 
                            }
                          </React.Fragment>
                        );
                      })
                    ):(
                      <React.Fragment >
                            {(item?.anothervalue!==undefined ) && (
                              <>
                              <Text
                                // onClick={() => handleCastDrawer(item)}
                                className="textWrap"
                                marginInlineStart={"0px!important"}
                                fontSize={"15px"}
                                fontWeight="400"
                                color="color.primary"
                              >
                                {formatName(item?.anothervalue)}
                              </Text>
                              </>
                            ) 
                            }
                          </React.Fragment>
                    )
                     }
                  </HStack>
                </Stack>
              }
              </>

              );
            })}
        </HStack>
        <HStack
          id={"allsliderskkCast"}
          position={"sticky"}
          className="mt-0"
          top={"268px"}
          width={{ base: "100%", md: "400px" }}
        >
          <Stack className="col-12" pl="0px">
            <Swiper
              grabCursor={true}
              navigation={true}
              modules={[EffectFlip, Navigation]}
          
            >
              {slider.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <VStack>
                      <Image
                        height={"100%"}
                        cursor={"pointer"}
                        onClick={(e) => {
                          setCastTabFlag(false);
                          showSynopsisImages(item?.image, true, index);
                        }}
                        src={item?.image}
                        borderRadius={"10px"}
                        alt=""
                        width="auto"
                      />
                      <Text
                        pt={"8px"}
                        fontSize={"15px"}
                        fontWeight={400}
                        lineHeight={"17px"}
                        color="#035DA1"
                        textAlign={"left"}
                        width="auto"
                      >
                        {item?.desc}
                      </Text>
                    </VStack>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Stack>
        </HStack>
      </Stack>}
    </>
  );
}
export default VerticalLayout;

const ImagePopupModal = ({
  isOpen,
  onClose,
  imageData,
  data,
  clickedImageIndex,
  setCastTabFlag,
}) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent alignItems={"center"} zIndex={"999"}>
        <ModalBody>
          <Box bg="white" mx="10">
            <Stack width={"1400px"}>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {data.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                      >
                        <Box
                          width={"100%"}
                          height={{ base: "100%", md: "90vh" }}
                        >
                          <Image
                            src={item?.image}
                            width={"100%"}
                            height={"inherit"}
                            alt=""
                          />
                        </Box>
                        <HStack paddingTop="10px">
                          <Text
                            pt={"8px"}
                            fontSize={"15px"}
                            fontWeight={400}
                            lineHeight={"17px"}
                            color="#035DA1"
                          >
                            {item?.newDesc}
                          </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        position="absolute"
                        right="00"
                        top={{ base: "80%", md: "95%" }}
                        marginRight={{ base: "0px", md: "160px" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={{ base: "0px", md: "50px" }}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={"0.6"}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          transform={""}
                          onClick={() => {
                            onClose();
                            setCastTabFlag(true);
                          }}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

// EconomicsOfCinema
import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Image,
} from "@chakra-ui/react";

// Core files
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Service Files
import * as getFlimographyData from "../../services/Filmography";
import * as getFilmData from "../../services/Film";
import * as getLandingMasterlistData from "../../services/SectionService";

// Subsection Files
import {explore } from "../../constants/constants";
import Timeline from "../Film/EconomicsOfCinema/ScreeningsBoxOffice/Timeline";
// import AwardsHonours from "../Film/EconomicsOfCinema/AwardsHonours";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { isArray } from "lodash";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import { getImageURL } from "../../util/getImage";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
import AwardsHonours from "../../components/AwardsHonours/AwardsHonours";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const WatchTrailer = `${IMAGE_BASE_PATH}/ui/Chevron/watchTrailer.png`
const SatyajitRayImg = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/5.png`
const SureshJindal = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

// const artistLinksData = [
//   {
//     name: "Satyajit Ray",

//   },
//   {
//     name: "Suresh Jindal",
//   },
// ];
export default function MainEconomicsOfCinema({ children }) {
  let { alias } = useParams();
  const { isOpen, onClose } = useDisclosure();
  const winnerName = alias;
  const [filmographyData, setFilmographyData] = React.useState([]);
  const [introData, setIntroData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([{}]);
  const [filmTrailer, setFilmTrailer] = useState({});
  const [filmMainImage, setfilmMainImage] = useState("");
  const [filmDetails, setFilmDetails] = useState({});
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getData = async () => {
      const result = await getFlimographyData.filmographyByAlias(
        masterlistType,
        winnerName
      );
      if (result) {
        const data = result;
        setFilmographyData(data);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, [winnerName, masterlistType]);

  React.useEffect(() => {
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        masterlistType,
        winnerName
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it.find((item) => item.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item.key === "masterlist_alias").value}/${
              it.find((item) => item.key === "landing_page_abbreviation").value
            }/${it.find(
              (item) =>
                item.key === "landing_page_abbreviation"
            )?.value==="research-centre" ?`library`:``}
             `,
            title: it.find((item) => item.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    // Get film intro data
    const getFilmIntroData = async () => {
      try {
        const result = await getFilmData.filmByAlias(masterlistType, winnerName);
        if (result) {
          if (result) {
            setName(
              () =>
                result?.find((item) => item.key === "film_title_image_alias")?.value &&
                result?.find((item) => item.key === "film_title_image_alias")?.value
            );
            setAgraphyLink([
              {
                link: `/research-categories/${
                  result.find((item) => item.key === "researchCategories")
                    .value[0]?.alias
                }`, // replace with the actual link
                title: result.find((item) => item.key === "researchCategories")
                  .value[0]?.value,
              },
              // {
              //   link: `/categories/indian-cinema`,
              //   title: result.find((item) => item.key === "subject")?.value,
              // },
            ]);
            setfilmMainImage(
              {
                 logoImage:result?.find((item) => item?.key === "ssb_image_alias")?.value,
                  logoLink:`/${masterlistType}/${alias}/introduction/title-image`
                 }
             );
             const loc=result?.find((item) => item?.key === "first_release_location")
             ?.value
            setFilmDetails({
              date: result?.find((item) => item?.key === "release_date")?.value,
              location: isArray(
                result?.find((item) => item?.key === "first_release_location")
                  ?.value
              )
                ? result?.find((item) => item?.key === "first_release_location")
                    ?.value[0]?.value
                : result?.find((item) => item?.key === "first_release_location")
                    ?.value,
                    link:`location/${loc.toLowerCase()}`
            });
            setFilmTrailer({
              link: `/${masterlistType}/${winnerName}/watch-film-trailer`,
              display: result.find((item) => item.key === "trailer_link")
                .display,
              image: WatchTrailer,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching flimography data", error);
      }
    };
    getLandingData();
    getFilmIntroData();
  }, [masterlistType, winnerName, alias]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        filmographyData={filmographyData}
        winnerName={winnerName}
        introData={introData}
        name={name}
        filmDetails={filmDetails}
        filmTrailer={filmTrailer}
        agraphyLinks={agraphyLinks}
        filmMainImage={filmMainImage}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            filmographyData={filmographyData}
            winnerName={winnerName}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <EconomicsOfCinema
          filmographyData={filmographyData}
          winnerName={winnerName}
          name={name}
          masterlistType={masterlistType}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  filmographyData,
  winnerName,
  onClose,
  name,
  introData,
  filmTrailer,
  agraphyLinks,
  filmMainImage,
  filmDetails,
  masterlistType,
  ...rest
}) => {
  const [drawerName, setDrawerName] = useState("")

  const drawerData = [
    {
    name: "Satyajit Ray",
    title:"Satyajit Ray",
    links: [
      { link_name: "Working Still", link: "" },
      { link_name: "Film Stills", link: "" },
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
    ],
    Image: SatyajitRayImg,
    desc: "",
    masterlist: {
      text: "Visit Satyajit Ray Masterlist →",
      link: "/person-poly/satyajit-ray",
    },
  },{
    name: "Suresh Jindal",
    title:"Suresh Jindal",
    links: [
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
      { link_name: "Interviews", link: "" },
      { link_name: "Newspaper Clippings", link: "" },
    ],
    Image: SureshJindal,
    desc: "",
    masterlist: {
      text: "Visit Suresh Jindal Masterlist →",
      link: "",
    },
  }];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };

  const trailerLink = {
    link:`/${masterlistType}/${winnerName}/watch-film-trailer`,
    image: WatchTrailer,
  };

  const oSections = [
    {
      to:`/${masterlistType}/${winnerName}/posts`,
      title: "",
      text: `${explore.POSTS} | ${explore.INSIGHTS}`,
    },
  ];
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        hMenu={agraphyLinks}
        LogoLink={filmMainImage}
        filmReleaseDetails={filmDetails}
        trailerLink={trailerLink}
        handleNavigation={handleNavigation}
        // backTo={backTo}
        setDrawerName={setDrawerName}
        drawerName={drawerName}
        // artistLinksData={artistLinksData}
        drawerData={drawerData}
        drawerImage={SKK1}
      />
    </>
  );
};

export const EconomicsOfCinema = ({ winnerName, name,masterlistType }) => {
  const [screeningCount,setScreeningCount]=useState(0);
  const [awardCount,setAwardCount]=useState(0);

  let { alias } = useParams();
  const [attr, setAttr] = useState(1)

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pathToIndex = {
    'screenings': 0,
    'awards-and-honours': 1,
    'box-office': 2,
  };
  const location = useLocation();
  let { tabIndex } = useParams();

  useEffect(() => {
    setAttr(pathToIndex[tabIndex] ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);

  function formatData() {
    return [
      {
        name: `${explore.SCREENINGS} ${screeningCount >0 ? `(${screeningCount})`:''}`,          
        component:<Timeline masterlistType={masterlistType} masterlist = {winnerName} setScreeningCount={setScreeningCount} />,
        link:`/${masterlistType}/${winnerName}/agraphy/economics-of-cinema/screenings`,
      },
      {
        name: `${explore.AWARDS_AND_HONOURS} ${awardCount >0 ? `(${awardCount})`:''}`,          
        component:<AwardsHonours setAwardCount={setAwardCount} />,
        link:`/${masterlistType}/${winnerName}/agraphy/economics-of-cinema/awards-and-honours`,
      },
      {
        name: `${explore.BUDGET_BOX_OFFICE}`,
        component:
        <Stack minH={"100vh"} width={{ base: "100%", md: "1136px" }}>
          <WebpageComingSoon/>
        </Stack>,
         link:`/${masterlistType}/${winnerName}/agraphy/economics-of-cinema/box-office`,
      }
    ];
}

const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(alias){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[alias,screeningCount,awardCount])

  return (
    <Stack>
      <Container
        minW={"100%"}
        pb="4"
        px="0px"
        paddingLeft={{ base: "4", md: "30px" }}
      >
        <Stack>
          <Box
            position={"fixed"}
            zIndex={999}
            bg="white"
            width={{ base: "100%%", md: "1150px" }}
          >
           <HStack paddingTop={{ base: "20px", md: "25px" }} paddingBottom={{ base: "20px", md: "14px" }}>
              <Box paddingRight={{base:"4",md:"0"}} width={{ base: "100%", md: "1130px" }}
              height={{ base: "65px", sm: "85px", md: "auto" }}>
                <Image src={getImageURL(name)} width={"100%"} height={"inherit"}></Image>
              </Box>
            </HStack>
          </Box>

          <HStack>
            {tabPanelData && tabPanelData.length > 0 && (
            <TabMain  
            attr={attr}
            setAttr={setAttr}  
            pos={"fixed"} 
            data={tabPanelData} 
            tabTop={{ base: "20px", md: "120px" }}
            panelTop={{ base: "65px", sm: "85px", md: "185px" }}/>
          )}
          </HStack>
        </Stack>
      </Container>
    </Stack>
  );
};

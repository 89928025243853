import {
  Box,
  HStack,
  useColorModeValue,
  useDisclosure,
  Stack,
  Container,
  Image,
  Text,
  Drawer,
  DrawerContent,
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

//Service file
import * as getIntroductionData from "../../services/Journal";
import * as getLandingMasterlistData from "../../services/SectionService";
import * as getPostData from "../../services/Post";
import * as getInsightData from "../../services/Insight";

//Component file
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import { BodyOfWorkBody } from "./BodyOfWorkBody";
import { EconomicsArtworkBody } from "./EconomicsArt";
import { LibraryAndArchive } from "../../components/ResearchCentre/LibraryAndArchive";

import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";

//Language file
import { common, artheritage, explore } from "../../constants/constants";
import AccordianText from "../../FunctionalComponents/Accordion/AccordianText";
import AccordianText2 from "../../FunctionalComponents/Accordion/AccordianText2";
import ReadMore from "../PersonalityPolymath/ReadMore";
import Accordian from "../../FunctionalComponents/Accordion/Accordian";
import { getImageURL } from "../../util/getImage";
import DrawerFamilyDetails from "../../FunctionalComponents/Personality_Introduction/Drawer/Personalia/DrawerFamilyDetails";
import DrawerModalDetails from "../../FunctionalComponents/Personality_Introduction/Drawer/Personalia/DrawerModalDetails";
import AccordianText4 from "../../FunctionalComponents/Accordion/AccordianText4";
import Large from "../../Theme/Typography/Large";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const EbrahimImg = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/Introduction/drawer1.png`
//S3 Bucket osw-dev-images
const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`

export default function Introduction({ children }) {
  const [title] = React.useState();
  const [date] = React.useState();
  const [datedod] = React.useState();
  const { isOpen, onClose } = useDisclosure();
  const [readMoreText, setReadMore] = useState(false);
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias} = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  const [ setResearchCentreFlag] = React.useState(true);
  const [setBookFlag] = useState(false);
  const [setleftFlag] = useState(false);
  const [bookReadmore, setBookReadmore] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [visible, setVisible] = useState(true);
  const [introTitle, setIntroTitle] = useState();
  const [postsCount, setPostsCount] = useState(0);
  const [insightsCount, setInsightsCount] = useState(0);

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        aliasAPI
      );

      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                .value[0].link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              .value[0].value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item.key === "subject")?.value +"."+result[0].find((item) => item.key === "masterlist_type").value,
          },
        ]);

        setName(() => result[0]?.find((item) => item?.key === "name")?.value);

        setJournalDetails(
          result?.reduce((acc, it) => {
            const title = it?.find((item) => item?.key === "full_name")?.value;
            const startDate = it?.find((item) => item?.key === "start_date")?.value;
            const endDate = it?.find((item) => item?.key === "end_date")?.value;
        
            setIntroTitle(title);
            if (title) acc.title = title;
            if (startDate && endDate) acc.date_Range = startDate + ' - ' + endDate;
        
            return acc;
          }, {})
        );
      }
    };

    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        masterlistType,
        aliasAPI
      );

      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${
              it?.find((item) => item?.key === "masterlist_alias")?.value
            }/agraphy/${
              it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value
            }/${it?.find(
              (item) =>
                item.key === "landing_page_abbreviation"
            )?.value==="research-centre" ?`all`:``}
             `,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };

    const getPostsCount = async () => {
      const result = await getPostData.getPostsByMasterlist(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setPostsCount(result?.data?.length)
      }
    };

    const getInsightsCount = async () => {
      const result = await getInsightData.getInsightsByMasterlist(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setInsightsCount(result?.data?.length)
      }
    };

    getIntroData();
    getLandingData();
    getPostsCount();
    getInsightsCount();
  }, [masterlistType, aliasAPI]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh)"
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        journalDetails={journalDetails}
        introData={introData}
        masterlistType={masterlistType}
        postsCount={postsCount}
        insightsCount={insightsCount}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            title={title}
            date={date}
            datedod={datedod}
            readMoreText={readMoreText}
            setReadMore={setReadMore}
            aliasAPI={aliasAPI}
          />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        {introData ? (
        <Routes>
        <Route path="/introduction"
        element={
          <IntroductionBody   
            aliasAPI={aliasAPI}
            horislinkData={horislinkData}
            journalDetails={journalDetails}
            introData={introData}
            introTitle={introTitle}
            name={name}/>
      }
        />
          <Route path="/economics-of-art/:tabIndex?"
            element={
            <EconomicsArtworkBody
              winnerName={aliasAPI}
            />
            }/>
          <Route path="/body-of-work/:tabValue?/:innerTab?/:childTab?"
            element={
            <BodyOfWorkBody
              masterlist={aliasAPI}
              masterlistType={masterlistType}
              // setReadmoreNavigation={setReadmoreNavigation}
              // readmoreNavigation={readmoreNavigation}
              visible={visible}
              setVisible={setVisible}
            />
            }/>
            <Route path="/research-centre/:tabValue?/:bookAlias?/:bookDetails?"
            element={
              <LibraryAndArchive
              masterlist={aliasAPI}
              setReadmoreNavigation={setReadmoreNavigation}
              readmoreNavigation={readmoreNavigation}
              masterlistType={masterlistType}
              setResearchCentreFlag={setResearchCentreFlag}
              setBookFlag={setBookFlag}
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              setleftFlag={setleftFlag}
              bookReadmore={bookReadmore}
              setBookReadmore={setBookReadmore}
              className={"top-to-btm4"}
            />
            }/>
        </Routes>
        ) : (
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.WEBPAGE_COMING_SOON}
          </Text>
        )}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ 
  title, 
  date, 
  datedod, 
  onClose, 
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  name, 
  masterlistType, 
  insightsCount,
  postsCount,
  ...rest }) => {

    const drawerArtHeritage = [
    {
    name:"Art Heritage Annual & Ebrahim Alkazi",
    title: "Ebrahim Alkazi",
    links: [
      { link_name: "Exhibition Invitation Cards", link: "" },
      { link_name: "Newspaper Articles", link: "" },
      { link_name: "Research & Reference Material", link: "" },
    ],
    Image: EbrahimImg,
    desc: "Content Awaited",
    masterlist: {
      text: "Visit Ebrahim Alkazi Masterlist →",
      link: "/ebrahim-alkazi",
    },
  }];

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };


  const data = {
    oSections: [
      {
        to: `/${masterlistType}/${aliasAPI}/posts`,
        title: "",
        text: postsCount>0 ? `${explore.POSTS} (${postsCount})`:explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/insights`,
        title: "",
        text: insightsCount>0 ? `${explore.INSIGHTS} (${insightsCount})`:explore.INSIGHTS,
      },
    ],
    backNavigation: `/${masterlistType}/${aliasAPI}`,
  };

  const artistLinksData = [];
  const [drawerName, setDrawerName] = useState("")

  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        oSections={data.oSections}
        onClose={onClose}
        sections={introData}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        handleNavigation={handleNavigation}
        drawerData={drawerArtHeritage}
        masterlistType={masterlistType}
        artistLinksData={artistLinksData}
        setDrawerName={setDrawerName}
        drawerName={drawerName}
        aliasAPI={aliasAPI}
      />
    </>
  );
};

const IntroductionBody = ({
  setTitle,
  setDate,
  setDateDod,
  readMoreText,
  setReadMore,
  aliasAPI,
  masterlistType,
  introTitle

}) => {
  const [contentAwaited, setContentAwaited] = useState(false);
  const masterlistTitle = aliasAPI;
  const [masterlistImage, setMasterListImage] = useState("");
  const [accordianData,setAccordianData]=useState([]);
  const [familyImgData, setFamilyImgData] = useState(null);
  const [familyFullScreenImage, setFamilyFullScreenImage] = useState(false);

  const showFamilyMemberImages = (images, flag) => {
    if (images) {
      setFamilyImgData(images);
    } else {
      setFamilyFullScreenImage(flag);
      setFamilyImgData(null);
    }
  };

  const showFullImages = (flag) => {
    setFamilyFullScreenImage(flag);
  };

  React.useEffect(() => {
    const getIntroData = async () => {
      let preface=null;

      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        masterlistTitle
      );
      
      if (result) {
        setContentAwaited(false);
        setMasterListImage(
          result[0].find((item) => item?.key === "image_alias")?.value
        );
        preface=result[0].find((item) => item?.key === "description")
        ?.value
      } else {
        setContentAwaited(true);
      }

      const founders = result[0].find(item => item.key === "founders");
      const directors = result[0].find(item => item.key === "directors");
      const institution = result[0].find(item => item.key === "institution");

      const establishment = [];

      if (founders) {
        establishment.push({ key: founders.key, value: founders.value, display: founders.display });
      }

      if (directors) {
        establishment.push({ key: directors.key, value: directors.value, display: directors.display });
      }

      if (institution) {
        establishment.push({ key: institution.key, value: institution.value, display: institution.display });
      }

      const editors = result[0].find(item => item.key === "editors");
      const publishers = result[0].find(item => item.key === "publishers");

      const team = [];

      if (editors) {
        team.push({ key: editors.key, value: editors.value, display: editors.display });
      }

      if (publishers) {
        team.push({ key: publishers.key, value: publishers.value, display: publishers.display });
      }

      const highlights = [];

      const highlightItem = result[0].find((item) => item?.key === "highlights")?.value;

      if (highlightItem) {
        highlights.push({ key: highlights.key, value: highlights.value, display: highlights.display });
      }

      if (preface!==null)
      {
        const formattedData = formatData(preface, establishment, team, highlights)
        setAccordianData(formattedData)
      }
    };
    getIntroData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlistType, masterlistTitle]);

  function formatData(introData, establishment, team, highlights) {
    const components = [
      {
        name: `${artheritage.PREFACE}`,
        data: introData,
        component: <AccordianText fontWeight="400" lineHeight="16.44px" data={introData} />,
      },
      {
        name: `${artheritage.ESTABLISHMENT}`,
        data: establishment,
        component: <AccordianText4 personalia={establishment} showFamilyMemberImages={showFamilyMemberImages} />,
      },
      {
        name: `${artheritage.TEAM}`,
        data: team,
        component: <AccordianText4 personalia={team} showFamilyMemberImages={showFamilyMemberImages} />,
      },
      {
        name: `${artheritage.HIGHLIGHTS}`,
        data: team,
        component: <AccordianText2 personalia={highlights} showFamilyMemberImages={showFamilyMemberImages}/>,
      }
    ];
  
    return components.filter(component => component?.data || component?.data?.length > 0);
  }

  return (
    <>
      {readMoreText ? (
        <ReadMore />
      ) : (
        <>
          <Stack>
            {contentAwaited ? (
              <Text
                py={"370px"}
                fontSize={"45px"}
                fontWeight="700"
                textAlign="center"
              >
                Content Awaited
              </Text>
            ) : (
              <>
                <Container maxW="auto" px="0" className="songs">
                  <Stack
                    height="calc(100vh - 64px)"
                    position={"relative"}
                    flexDirection={{base:"column",md:"row"}}
                  >
                   
                    <Stack px="64px" py="32px" >
                    <Large textStyle="tertiary">{introTitle}</Large>
                    <Box width={{base:"100%",md:"643px"}}>
                    <Image
                        src={getImageURL(masterlistImage)}
                        fallbackSrc={placeholderImg}
                        width={"100%"}
                        height={"100%"}
                        objectFit={"contain"}
                      />
                      </Box>
                    </Stack>
                    <HStack zIndex={"99"} alignItems="flex-start"  overflowY={"scroll"} borderLeft={"1px"} borderColor={"#e8e8e8"} className="mt-0">
                    <Box px="5" width={{ base: "100%", md: "425px" }}>
                          {accordianData.length>0 && <Accordian data={accordianData}/>}
                  </Box>
                    </HStack>
                  </Stack>
                </Container>
                <DrawerFamilyDetails
                  familyImgData={familyImgData}
                  showFamilyMemberImages={showFamilyMemberImages}
                  showFullImages={showFullImages}
                  closeIcon={closeIcon}
                  width="766px"
                />
                <DrawerModalDetails
                  familyImgData={familyImgData}
                  showFullImages={showFullImages}
                  familyFullScreenImage={familyFullScreenImage}
                />
                <Stack display={{ base: "flex", md: "none" }}>
                  <BottomMainMenu />
                </Stack>
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";

export default function CommonDrawer({
  drawerOpen,
  setDrawerOpen,
  drawerHeader,
  drawerBody,
  drawerFooter,
  returnFocusOnClose
}) {
  return (
    <>
      <Drawer
        isOpen={drawerOpen}
        placement="right"
        onClose={() => setDrawerOpen()}
        id="rightsidebarsmall"
        // autoFocus={false} 
        returnFocusOnClose={returnFocusOnClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader px={{base:"15px",md:"35px"}} borderBottom={"1px"} borderBottomColor={"#f1f1f1"}>
            {/* place component props of IntroDrawerHeader here */}
            {drawerHeader}
          </DrawerHeader>
          <DrawerBody px={{base:"15px",md:"35px"}}>
            {/* place component props of IntroDrawerBody here */}
            {drawerBody}
          </DrawerBody>
          <DrawerFooter px={{base:"15px",md:"35px"}} justifyContent={"flex-start"}>
          {drawerFooter}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorModeValue,
  Stack,
  Container,
  Image,
} from "@chakra-ui/react";
import { explore } from "../../../../constants/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TabMain from "../../../../FunctionalComponents/TabComponents/TabMain";
import { getImageURL } from "../../../../util/getImage";
import ReadMore from "../../ReadMore";
import { All } from "../../../SearchPage/ResearchCentre/All";
import { Library } from "../../../SearchPage/ResearchCentre/Library";
import { Archive } from "../../../SearchPage/ResearchCentre/Archive";
import { Highlights } from "../../../SearchPage/ResearchCentre/Highlights";

export default function ResearchCentre({ children, rcAlias, main, filterbuttonStyle }) {
  const [bookFlag, setBookFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [researchCentreFlag, setResearchCentreFlag] = useState(true);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <Box bg="white">
        {children}
        <LibraryAndArchive
          rcAlias={rcAlias}
          masterlistType={"research-categories"}
          bookFlag={bookFlag}
          SearchTerm={rcAlias}
          setBookFlag={setBookFlag}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setResearchCentreFlag={setResearchCentreFlag}
          researchCentreFlag={researchCentreFlag}
          main={main}
          filterbuttonStyle
        />
      </Box>
    </Box>
  );
}

const LibraryAndArchive = ({
  filmographyData,
  rcAlias,
  setResearchCentreFlag,
  setleftFlag,
  bookFlag,
  masterlistType="research-categories",
  setBookFlag,
  main,
  name,
  marginX="30px",
  width="1160px",
  listId,
  top,
  right,
  bookReadmore
}) => {
  const [visible, setVisible] = useState(true);
  const [listGridIcon, setListGridIcon] = useState(false);
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  let { tabIndex, bookAlias,gridview } = useParams();
  let {tabValue,bookDetails}=useParams();
  const [attr, setAttr] = useState(1)
  const [allCount,setAllCount]=useState(0)
  const [libraryCount, setLibraryCount] = useState(0)
  const [archiveCount, setArchiveCount] = useState(0)
  const [highlightCount, setHighlightCount] = useState(0)

  // const width = { base: "100%", md: "1160px" };
  const pathToIndex = {
    'all': 0,
    'library': 1,
    'archive': 2,
    'highlights':3,

  };
  const location = useLocation();
  // const width = { base: "100%", md: "1160px" };

  useEffect(() => {
    setAttr(pathToIndex[tabValue] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);
  /* Need to check after dynamic book data */


  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${rcAlias}/research-centre`, { replace: true });


      if (parseInt(tabIndex) === 1) {
        setListGridIcon(true);
      }
      if (parseInt(gridview) === 0) {
        setVisible(false);
      }
      if (parseInt(gridview) === 1) {
        setVisible(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex, navigate, gridview, masterlistType, rcAlias]);

  const handleBookClick = (flag, data) => {
    navigate(`bookDetails`)
    setBookFlag(flag);
    setResearchCentreFlag(false);
  };
  const [tabPanelData, setTabPanelData] = useState([]);
  function formatData() {
    if(tabValue==="all" || tabValue==="highlights"){
      setListGridIcon(false)
    }
    return [
      {
        name: `${explore.ALL}`,
        count:`${allCount}`,
        component:<All 
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        SearchTerm={rcAlias}
        visible={visible} 
        masterlistType={masterlistType} 
        masterlist={rcAlias} 
        setAllCount={setAllCount} 
        handleBookClick={handleBookClick}
        filterbuttonStyle={"rcfilterstickywbtn"}
        />,
        list:false,
        link:`/${masterlistType}/${rcAlias}/research-centre/all`,
      },
      {
        name: `${explore.LIBRARY}`,
        count:`${libraryCount}`,
        component: <Library
        visible={visible}
        handleBookClick={handleBookClick}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setLibraryCount={setLibraryCount}
        setleftFlag={setleftFlag}
        tableName={"bibliographies"}
        masterlist={rcAlias}
        masterlistType={masterlistType}
        SearchTerm={rcAlias}
        filterbuttonStyle={"rcfilterstickywbtn"}
      />,
      list:true,
      link:`/${masterlistType}/${rcAlias}/research-centre/library`
    },
      {
        name: `${explore.ARCHIVE}`,
        count:`${archiveCount}`,
        component: <Archive 
        SearchTerm={rcAlias} 
        visible={visible} 
        masterlistType={masterlistType} 
        masterlist={rcAlias} 
        setArchiveCount={setArchiveCount}
        filterbuttonStyle={"rcfilterstickywbtn"}
/>,
        list:true,
        link:`/${masterlistType}/${rcAlias}/research-centre/archive`
      },
      {
        name: `${explore.HIGHLIGHTS}`,
        count:`${highlightCount}`,
        component:<Highlights 
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        SearchTerm={rcAlias}
        visible={visible} 
        masterlistType={masterlistType} 
        masterlist={rcAlias} 
        setHighlightCount={setHighlightCount} 
        handleBookClick={handleBookClick}
        filterbuttonStyle={"rcfilterstickywbtn"}
        />,
        list:false,
        link:`/${masterlistType}/${rcAlias}/research-centre/highlights`,
      },
    ];
  }
  useEffect(() => {
    if (rcAlias) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rcAlias,visible,openDrawer,bookAlias,archiveCount,libraryCount,allCount,highlightCount]);
  const tabHandle=(item)=>{
    setListGridIcon(item)
  }
  return (
    <Stack position={"relative"}>
        <Container minW={"100%"} pb="4" px={{base:"0px",md:"0px"}} marginX={"0px"}>
          <Stack>
          {name && <Box
            position={"fixed"}
            zIndex={999}
            bg="white"
            width={{ base: "100%", md: "1150px" }}
          >
            <HStack paddingTop={{ base: "20px", md: "30px" }} paddingBottom={{ base: "20px", md: "14px" }}>
              <Box paddingRight={{base:"4",md:""}} width={{ base: "100%", md: "656px" }}>
                <Image src={getImageURL(name)} width={"100%"} height={"60px"}></Image>
              </Box>
            </HStack>
          </Box>}

          {bookDetails==="bookDetails" ? (
              <>
                {bookReadmore ? (
                  <ReadMore />
                ) : (
                  <>
                  </>
                )}
              </>
            ) : (
              <HStack position={"relative"}>
                {tabPanelData && tabPanelData.length > 0 && (
              <TabMain top="0px" paddingTop={{base:"8",md:"2"}} gap={{base:"30px",md:"50px"}} width={{base:"100%",md:"1245px"}} pos={name && "fixed"} tabTop={name && { base: "55px", sm: "75px", md: "100px" }} 
              panelTop={name && { base: "65px", sm: "85px", md: "140px" }} topTogleIcon="0px" data={tabPanelData} listId={"listgridbutton4"} right={right} listGridIcon={listGridIcon} tabHandle={tabHandle} attr={attr} setAttr={setAttr} setVisible={setVisible} visible={visible}/>
            )}
              </HStack>
            )}
          </Stack>
        </Container>
      </Stack>
  );
};
import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Image,
  Text,
} from "@chakra-ui/react";

// Core files
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";

// Service Files
import * as getFilmData from "../../services/Film";
import * as getLandingMasterlistData from "../../services/SectionService";
import * as getPostData from "../../services/Post";
import * as getInsightData from "../../services/Insight";

// Subsection Files
import { common, explore } from "../../constants/constants";
import Preface from "./Introduction/Preface";
import Specifications from "./Introduction/Specifications";
import ReadMore from "./ReadMore";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { isArray } from "lodash";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import { getImageURL } from "../../util/getImage";
import { BodyOfWork } from "./BodyOfWork";
import { EconomicsOfCinema } from "./EconomicsOfCinema";
import { LibraryAndArchive } from "../../components/ResearchCentre/LibraryAndArchive";
import { IntroductionImageView } from "./Introduction/IntroductionImageView";
import { Trailer } from "./Trailer";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const WatchTrailer = `${IMAGE_BASE_PATH}/ui/Chevron/watchTrailer.png`
const SatyajitRayImg = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/5.png`
const SureshJindal = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

// const artistLinksData = [
//   {
//     name: "Satyajit Ray",

//   },
//   {
//     name: "Suresh Jindal",
//   },
// ];
export default function MainIntroduction({ children }) {
  let { alias } = useParams();
  const { isOpen, onClose } = useDisclosure();
  const winnerName = alias;
  const tableName = "films";
  const [introData, setIntroData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([{}]);
  const [filmTrailer, setFilmTrailer] = useState({});
  const [filmMainImage, setfilmMainImage] = useState({});
  const [filmDetails, setFilmDetails] = useState({});
  const [castTabFlag, setCastTabFlag] = useState(false);
  const [watchFilm, setWatchFilm] = useState("");
  const [researchCentreFlag, setResearchCentreFlag] = React.useState(true);
  const [bookFlag, setBookFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [leftFlag, setleftFlag] = useState(false);
  const [watchTrailer, setWatchTrailer] = useState("");

  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [postsCount, setPostsCount] = useState(0);
  const [insightsCount, setInsightsCount] = useState(0);

  React.useEffect(() => {
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        masterlistType,
        winnerName
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it.find((item) => item.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${
              it?.find((item) => item?.key === "masterlist_alias")?.value
            }/agraphy/${
              it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value
            }/${it.find(
              (item) =>
                item.key === "landing_page_abbreviation"
            )?.value==="research-centre" ?`all`:``}`,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    // Get film intro data
    const getFilmIntroData = async () => {
      try {
        const result = await getFilmData.filmByAlias(masterlistType, winnerName);
        if (result) {
          setName(
            () =>
              result?.find((item) => item?.key === "film_title_image_alias")?.value &&
              result?.find((item) => item?.key === "film_title_image_alias")?.value
          );
          setAgraphyLink([
            {
              link: `/research-categories/${
                result?.find((item) => item?.key === "researchCategories")
                  ?.value[0]?.alias
              }`, // replace with the actual link
              title: result?.find((item) => item?.key === "researchCategories")
                ?.value[0]?.value,
            },
            // {
            //   link: `/categories/indian-cinema`,
            //   title: result?.find((item) => item?.key === "subject")?.value,
            // },
          ]);
          setWatchFilm(
            result?.find((item) => item?.key === "full_movie_link")?.value
          );
          setfilmMainImage(
            {
               logoImage:result?.find((item) => item?.key === "ssb_image_alias")?.value,
                logoLink:`/${masterlistType}/${alias}/agraphy/introduction/title-image`
               }
           );
           const loc=result?.find((item) => item?.key === "first_release_location")
           ?.value
          setFilmDetails({
            date: result?.find((item) => item?.key === "release_date")?.value,
            location: isArray(
              result?.find((item) => item?.key === "first_release_location")
                ?.value
            )
              ? result?.find((item) => item?.key === "first_release_location")
                  ?.value[0]?.value
              : result?.find((item) => item?.key === "first_release_location")
                  ?.value,
                  link:`location/${loc.toLowerCase()}`
          });
          setFilmTrailer({
            link: `/${masterlistType}/${winnerName}/agraphy/watch-film-trailer`,
            display: result?.find((item) => item?.key === "trailer_link")
              .display,
            image: WatchTrailer,
          });
          setWatchTrailer(
            result?.find((item) => item?.key === "trailer_link")?.value
          );
        }
      } catch (error) {
        console.error("Error fetching flimography data", error);
      }
    };

    const getPostsCount = async () => {
      const result = await getPostData.getPostsByMasterlist(
        masterlistType,
        winnerName
      );
      if (result) {
        setPostsCount(result?.data?.length)
      }
    };

    const getInsightsCount = async () => {
      const result = await getInsightData.getInsightsByMasterlist(
        masterlistType,
        winnerName
      );

      if (result) {
        setInsightsCount(result?.data?.length)
      }
    };

    getLandingData();
    getFilmIntroData();
    getPostsCount();
    getInsightsCount();
    window.scrollTo(0, 0);
  }, [masterlistType, winnerName, alias]);

  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  const [hidden, setHidden] = useState(true);
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - -64px)"
        winnerName={winnerName}
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        hidden={hidden}
        setHidden={setHidden}
        introData={introData}
        castTabFlag={castTabFlag}
        name={name}
        filmDetails={filmDetails}
        filmTrailer={filmTrailer}
        agraphyLinks={agraphyLinks}
        filmMainImage={filmMainImage}
        masterlistType={masterlistType}
        researchCentreFlag={researchCentreFlag}
        leftFlag={leftFlag}
        postsCount={postsCount}
        insightsCount={insightsCount}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            winnerName={winnerName}
            readmoreNavigation={readmoreNavigation}
            setReadmoreNavigation={setReadmoreNavigation}
            hidden={hidden}
            setHidden={setHidden}
          />
        </DrawerContent>
      </Drawer>
      {/* <Routes>
      <Route path="/foo-one" element={<FooOne />} /> 
    </Routes> */}
      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        {introData ? (
        <Routes>
        <Route path="/introduction/:tabIndex?/"
            element={  <Introduction
            winnerName={winnerName}
            setReadmoreNavigation={setReadmoreNavigation}
            readmoreNavigation={readmoreNavigation}
            hidden={hidden}
            setHidden={setHidden}
            tableName={tableName}
            name={name}
            masterlistType={masterlistType}
          />} />
          <Route path="/body-of-work/:tabValue?/:drawerId?"
            element={  <BodyOfWork
              winnerName={winnerName}
              setCastTabFlag={setCastTabFlag}
              setReadmoreNavigation={setReadmoreNavigation}
              readmoreNavigation={readmoreNavigation}
              name={name}
              watchFilm={watchFilm}
              masterlistType={masterlistType}
            />}/>
            <Route path="/economics-of-cinema/:tabIndex?"
            element={  <EconomicsOfCinema
              winnerName={winnerName}
              name={name}
              masterlistType={masterlistType}
            />}/>
            <Route path="/research-centre/:tabValue?/:bookAlias?/:bookDetails?"
            element={  
            <LibraryAndArchive
              masterlist={winnerName}
              setResearchCentreFlag={setResearchCentreFlag}
              bookFlag={bookFlag}
              setleftFlag={setleftFlag}
              setBookFlag={setBookFlag}
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              name={name}
              masterlistType={masterlistType}
              listId={"listgridviewbtnskk"}
              right="253px!important"
              className={"top-to-btm4"}
              filerStyle="box-filter-film1"
            />}/>
            <Route path="/introduction/title-image"
            element={  
              <IntroductionImageView
              winnerName={winnerName}
              filmMainImage={filmMainImage}
              masterlistType={masterlistType}
            />}/>
            <Route path="/watch-film-trailer"
            element={  
              <Trailer name={name} watchTrailer={watchTrailer} />}/>
          </Routes>
          
          
        ) : (
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.WEBPAGE_COMING_SOON}
          </Text>
        )}
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  winnerName,
  onClose,
  display,
  readmoreNavigation,
  setReadmoreNavigation,
  hidden,
  setHidden,
  name,
  member,
  introData,
  filmTrailer,
  agraphyLinks,
  filmMainImage,
  filmDetails,
  masterlistType,
  castTabFlag,
  researchCentreFlag,
  leftFlag,
  insightsCount,
  postsCount,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    if (readmoreNavigation?.isReadMore) {
      setReadmoreNavigation({ ...readmoreNavigation, isReadMore: false });
      return;
    } else {
      navigate(-1);
    }
  };
  const [drawerName, setDrawerName] = useState("")

  const drawerData = [
    {
    name: "Satyajit Ray",
    title:"Satyajit Ray",
    links: [
      { link_name: "Working Still", link: "" },
      { link_name: "Film Stills", link: "" },
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
    ],
    Image: SatyajitRayImg,
    desc: "",
    masterlist: {
      text: "Visit Satyajit Ray Masterlist →",
      link: "/person-poly/satyajit-ray",
    },
  },{
    name: "Suresh Jindal",
    title:"Suresh Jindal",
    links: [
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
      { link_name: "Interviews", link: "" },
      { link_name: "Newspaper Clippings", link: "" },
    ],
    Image: SureshJindal,
    desc: "",
    masterlist: {
      text: "Visit Suresh Jindal Masterlist →",
      link: "",
    },
  }];
  const trailerLink = {
    link:`/${masterlistType}/${winnerName}/agraphy/watch-film-trailer`,
    image: WatchTrailer,
  };

  const oSections = [
    {
      to: `/${masterlistType}/${winnerName}/posts`,
      title: "",
      text: postsCount>0 ? `${explore.POSTS} (${postsCount})`:explore.POSTS,
    },
    {
      to: `/${masterlistType}/${winnerName}/insights`,
      title: "",
      text: insightsCount>0 ? `${explore.INSIGHTS} (${insightsCount})`:explore.INSIGHTS,
    },
  ];

  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        hidden={hidden}
        castTabFlag={castTabFlag}
        setHidden={setHidden}
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        sections={introData}
        researchCentreFlag={researchCentreFlag}
        oSections={oSections}
        hMenu={agraphyLinks}
        LogoLink={filmMainImage}
        filmReleaseDetails={filmDetails}
        trailerLink={trailerLink}
        handleNavigation={handleNavigation}
        setDrawerName={setDrawerName}
        drawerName={drawerName}
        // artistLinksData={artistLinksData}
        drawerData={drawerData}
        drawerImage={SKK1}
        leftFlag={leftFlag}
      />
    </>
  );
};

const Introduction = ({
  winnerName,
  setReadmoreNavigation,
  readmoreNavigation,
  hidden,
  setHidden,
  tableName,
  name,
  masterlistType
}) => {
  
  const handleReadMoreNavigation = (flag, isOpen, index, bannerFlag, title) => {
    setReadmoreNavigation({
      isReadMore: flag,
      isOpen: isOpen,
      clickedImageIndex: index,
      bannerFlag: bannerFlag,
      title: title,
    });
  };
  const [attr, setAttr] = useState(1)

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pathToIndex = {
    'preface': 0,
    'specifications': 1,
  };
  const location = useLocation();
  let { tabIndex } = useParams();

  useEffect(() => {
    setAttr(pathToIndex[tabIndex] ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);

  const [tabPanelData, setTabPanelData] = useState([]);

  function formatData() {
    return [
      {
        name: `${explore.PREFACE}`,
        component:<Preface
        winnerName={winnerName}
        hidden={hidden}
        setReadMoreFlag={handleReadMoreNavigation}
        readmoreNavigation={readmoreNavigation}
        masterlistType={masterlistType}
      />,
      link:`/${masterlistType}/${winnerName}/agraphy/introduction/preface`,
      },
      {
        name: `${explore.SPECIFICATIONS}`,
        component: <Specifications
        winnerName={winnerName}
        hidden={hidden}
        masterlistType={masterlistType}
      />,
      link:`/${masterlistType}/${winnerName}/agraphy/introduction/specifications`,
      },

    ];
  }
  useEffect(() => {
    if (winnerName) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winnerName]);

  return (
    <Stack>
      {readmoreNavigation?.isReadMore ? (
        <ReadMore />
      ) : (
        <Container
          minW={"100%"}
          pb="4"
          paddingX={{ base: "4", md: "0px" }}
          marginLeft={{ base: "0px", md: "30px" }}
        >
          <Stack>
            <Box
              position={"fixed"}
              zIndex={999}
              bg="white"
              width={{ base: "100%", md: "1130px" }}
              height={{ base: "65px", sm: "85px", md: "156px" }}
            >
              <HStack paddingY={{ base: "20px", md: "25px" }}>
                <Box
                  marginRight={"0px!important"}
                  width={{ base: "93%", md: "1136px" }}
                  height={{ base: "100%", md: "106px" }}
                  display={"flex"}
                >
                  <Image
                    src={getImageURL(name)}
                    width={"100%"}
                    // fallbackSrc={placeholderImg}
                    objectFit={"contain"}
                    height={"inherit"}
                    objectPosition={"left"}
                  />
                  {!name && <Text pos={"absolute"} top={"45%"} left={"45%"} >{`Image coming soon...`}</Text>}

                </Box>
              </HStack>
            </Box>
            <HStack width={{ base: "100%", md: "1136px" }}>
              {tabPanelData && tabPanelData.length > 0 && (
            <TabMain 
            attr={attr}
            setAttr={setAttr} 
            width="1135px" 
            pos={"fixed"} 
            data={tabPanelData} 
            tabTop={{ base: "55px", sm: "75px", md: "120px" }} 
            panelTop={{ base: "65px", sm: "85px", md: "175px" }}/>
          )}
            </HStack>
          </Stack>
        </Container>
      )}
    </Stack>
  );
};


// Core files
import { SimpleGrid, Box, Stack, Text, Image, HStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Navigation, Pagination } from "swiper";
import parse, { domToReact } from 'html-react-parser';

// Service Files
import * as getFilmPrefaceData from "../../../services/Film";

// Subsection Files
import { FullScreenImage } from "../../../FunctionalComponents/FullScreenImage";
import ImageSlider from "../../../FunctionalComponents/ImageSlider/ImageSlider";
import { ChakraLink } from "../../../CommonComponents/TextLink/TextLinks";
import { explore } from "../../../constants/constants";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

function Preface({
  winnerName,
  setReadMoreFlag,
  readmoreNavigation,
  masterlistType,
}) {
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [bannerFullScreenImage, setBannerFullScreenImage] = useState(false);
  const [bannerImgData, setBannerImgData] = useState(null);
  const [posterFullScreenImage, setPosterFullScreenImage] = useState(false);
  const [posterImgData, setposterImgData] = useState(null);
  const [description, setDescription] = useState("");
  const [posterData, setPosterData] = useState([]);
  const [publicityData, setPublicityData] = useState([]);
  const [isLoading,setIsLoading]=useState(true);
  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
    }
  }, [readmoreNavigation]);

  const showBannerImages = (image, flag) => {
    if (image) {
      setBannerFullScreenImage(flag);
      setBannerImgData(image);
    } else {
      setBannerFullScreenImage(flag);
      setBannerImgData(null);
    }
  };

  const handleBannerImageClick = (item, index) => {
    setClickedImageIndex(index);
    setBannerFullScreenImage(true);
  };

  const showPosterImages = (image, flag) => {
    if (image) {
      setPosterFullScreenImage(flag);
      setposterImgData(image);
    } else {
      setPosterFullScreenImage(flag);
      setposterImgData(null);
    }
  };

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setPosterFullScreenImage(true);
  };

  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
      if (readmoreNavigation?.title === "poster") {
        setPosterFullScreenImage(true);
      }
      if (readmoreNavigation?.title === "banner") {
        setBannerFullScreenImage(true);
      }
    }
  }, [readmoreNavigation]);

  //Future use
  // const createDataObject = (dataArray) => ({
  //   display: dataArray[0]?.display,
  //   value: dataArray[0]?.value,
  // });

    //Future use
  // const getDesigner = (designer) => {
  //   if (designer !== undefined && designer.length > 0) {
  //     return createDataObject(designer);
  //   }

  //   return { display: "Designer", value: "Unknown" }; // Default value if no designer is found
  // };
  const citationFactor = (data) => {
    return data.map((item, index) => {
      return {
        desc:
          `${item?.bibliographic_citation}`,
        designerdesc: `${item?.bibliographic_caption}`,
        imgPath: item?.direct_url_of_cover_image,
        fullImg: item?.direct_url_of_cover_image,
        designerData:[{key:"year",display:"Date of Artwork",value:item?.year},
          {key:"creator_name",display:"Creator Name",value:item?.creator_name}
        ]
        // designerData: [dateofArt,designer, printer, artDirector, stillPhotographer],
      };
    });
  };

  useEffect(() => {
  
    const getData = async () => {
      try {
        setIsLoading(true);
      const result = await getFilmPrefaceData.filmPrefaceData(
        masterlistType,
        winnerName
      );
      setIsLoading(false);
      if (result && result.length > 0) {
        setDescription(
          result.find((nestedItem) => nestedItem.key === "short_description_tag")
            ?.value ? result.find((nestedItem) => nestedItem.key === "short_description_tag")
            ?.value :result.find((nestedItem) => nestedItem.key === "short_description")
            ?.value || ''
        );
    
        const posterData=result.find((nestedItem) => nestedItem.key === "poster_image_carousel_alias")?.value
        setPosterData(citationFactor(posterData))
        const publicityData=result.find((nestedItem) => nestedItem.key === "other_publicity_image_carousel_alias")?.value
        setPublicityData(citationFactor(publicityData))

      }else{
        setDescription(explore?.DATA_COMING_SOON)

      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
      setDescription(explore?.DATA_COMING_SOON)
    }
    };
  
    window.scrollTo(0, 0);
    getData();
    // eslint-disable-next-line
  }, []);

  const renderText = (htmlString) => {
    return parse(htmlString, {
      replace: (domNode) => {
        if (domNode.name === 'a') {
          const { href, children } = domNode.attribs;
          return (
            <ChakraLink href={href} children={children}>
              {domToReact(domNode.children)}
            </ChakraLink>
          );
        }
      },
    });
  };
  return (
    <>

      {!isLoading ? <Stack width={{ base: "100%", md: "1100px" }}>
        <Stack
          pb={"50px"}
        >
          <SimpleGrid
            columns={[1, null, 3]}
            spacing={{ base: "40px", md: "16" }}
            pt={"16px"}
          >
            <Stack>
              <Text 
              fontSize={"16px"}
              fontWeight="400"
              textAlign="justify"
              lineHeight={"18.78px"}
              color={"color.gray"}>
                {renderText(description)}
              </Text>
            </Stack>
            <Stack position={"relative"} id="allsliderskkPre">
              {posterData && (
                <ImageSlider
                  sliderData={posterData}
                  handleImageClick={handleImageClick}
                  modules={[EffectFlip, Navigation, Pagination]}
                  cursor={"pointer"}
                />
              )}
            </Stack>
            <Stack position={"relative"} id="allsliderskkPre">
              {publicityData && (
                <ImageSlider
                  sliderData={publicityData}
                  handleImageClick={handleBannerImageClick}
                  modules={[EffectFlip, Navigation, Pagination]}
                  cursor={"pointer"}
                />
              )}
            </Stack>
          </SimpleGrid>
          <Stack py={{ base: 10, md: 20 }} alignItems={"center"}>
            <Swiper
              grabCursor={true}
              modules={[EffectFlip, Navigation]}
            >
              {[0]?.map((item, key) => {
                return (
                  <>
                    <SwiperSlide>
                      <Box
                        height={{ base: "275px", md: "480px" }}
                        width={{ base: "100%", md: "900px" }}
                        border={"1px solid #C4C4C4"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        display={"flex"}
                        bg={"#C4C4C4"}
                      >
                        <Image src={placeholderImg} width="100%" height="auto" objectFit={"cover"}/>
                      </Box>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </Stack>
        </Stack>
      </Stack>:
      (
        <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
            <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
        </HStack>
      )
      }
      {/* <ImagePopupModal */}
      {posterFullScreenImage && (
        <FullScreenImage
          isOpen={posterFullScreenImage}
          onClose={() => showPosterImages(null, false)}
          imageData={posterImgData}
          data={posterData}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          setReadMoreFlag={setReadMoreFlag}
          bannerFlag={false}
          title="poster"
        />
      )}
      {bannerFullScreenImage && (
        <FullScreenImage
          isOpen={bannerFullScreenImage}
          onClose={() => showBannerImages(null, false)}
          imageData={bannerImgData}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          setReadMoreFlag={setReadMoreFlag}
          bannerFlag={true}
          title="banner"
          data={publicityData}
        />
      )}
    </>
  );
}
export default Preface;

import {
  Box,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { explore } from "../../../constants/constants";
import * as getCatalougeData from "./../../../services/PersonArtist";
import Catalouge from "./Catalouge/Catalouge";
import Exhibits from "./Catalouge/Exhibits";
import ExhibitionsPhotographs from "./Catalouge/ExhibitionsPhotographs";
import ArtCriticismMediaReportage from "./Catalouge/ArtCriticismMediaReportage";
import TabMain from "../../../FunctionalComponents/TabComponents/TabMain";
import * as getExhibitions from "../../../services/Journal";
import { BiUser } from "react-icons/bi";
import H3 from "../../../Theme/Typography/H3";

export default function PersonArtistCatlouge({
  data,
  masterlist,
  innerTab,
  galleryName,
  masterlistType,
}) {
  const [exhibitionTitle1, setExhibitionTitle1] = useState(null);
  const [exhibitionTitle2, setExhibitionTitle2] = useState(null);
  const [group, setGroup] = useState(null);
  async function fetchTitle() {
    try {
      const result = await getExhibitions.getExhibitionTitleAPI(masterlistType, masterlist, innerTab);
      const processedData = citationFactor(result?.data);
      setExhibitionTitle1(processedData[0]?.exhibitionTitle1);
      setExhibitionTitle2(processedData[0]?.exhibitionTitle2);
      setGroup(processedData[0]?.group);
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const [catalougeData, setCatalougeData] = useState([]);
  const [exhibitsData, setExhibitsData] = useState([]);
  const [exhibitionPhotoData, setExhibitionPhotoData] = useState([]);
  const [artCriticismData, setArtCriticismData] = useState([]);

  //future use
  // const createDataObject = (dataArray) => {
  //   const dataObject = {};
  //   if (dataArray.length > 0) {
  //     const dataItem = dataArray[0];
  //     for (const key in dataItem) {
  //       dataObject[key] = dataItem[key];
  //     }
  //   }
  //   return dataObject;
  // };
  const citationFactor = (data) => {
    const findAndGetValue = (items, key, defaultValue = "") => 
      items.find(it => it.key === key)?.value || defaultValue;
  
    const formatTitle = (title, subTitle, datePublished) => {
      let formattedTitle = title;
      if (subTitle) {
        formattedTitle += `: ${subTitle}`;
      }
      if (datePublished) {
        formattedTitle += `, ${datePublished}`;
      }
      return formattedTitle;
    };
  
    const formatCreatorLocation = (creatorName, cityOfEvent) => {
      let formattedLocation = creatorName;
      if (cityOfEvent) {
        formattedLocation += `, ${cityOfEvent}`;
      }
      return formattedLocation;
    };
  
    return data.map(item => {
      const title = findAndGetValue(item, "title");
      const subTitle = findAndGetValue(item, "sub_title");
      const datePublished = findAndGetValue(item, "date_of_published_edition_in_library");
      const creatorName = findAndGetValue(item, "creator_name");
      const cityOfEvent = findAndGetValue(item, "city_of_event");
      const eventType = findAndGetValue(item, "type_of_event");
  
      return {
        exhibitionTitle1: formatTitle(title, subTitle, datePublished),
        exhibitionTitle2: formatCreatorLocation(creatorName, cityOfEvent),
        group: eventType
      };
    });
  };

  //In future use
  // const citationFactor = (data) => {
  //   return data.map((item, index) => {
  //     const author = createDataObject(item.artist);
  //     const title_of_artwork = createDataObject(item.title_of_artwork);
  //     const medium_artwork = createDataObject(item.medium_artwork);
  //     return {
  //       desc:
  //         `${author?.name}.` +
  //         `${title_of_artwork?.value},` +
  //         ` ${medium_artwork?.value}  ${medium_artwork?.date_of_artwork}`,
  //         image: item.image,
  //       medium_artwork,
  //       author,
  //       title_of_artwork,
  //       content: item.content !== "" ? item.content : "Content Awaited",
  //       title: `${author?.name}`,
  //       fullImg: item?.fullImage,
  //       essayistData: [title_of_artwork, author, medium_artwork],
  //       link: item?.link,
  //     };
  //   });
  // };
  useEffect(() => {
    const getData = async () => {
      const result = await getCatalougeData.catalougeDatabyExhibition(
        "catalouge",
        masterlist,
        data?.exhibition_centre
      );
      result.map(
        (item) => item.key === "catalogue" && setCatalougeData(item.value)
      );
      result.map(
        (item) =>
          item.key === "exhibits" && setExhibitsData(citationFactor(item.value))
      );
      result.map(
        (item) =>
          item.key === "exhibition-photographs" &&
          setExhibitionPhotoData(item.value)
      );
      result.map(
        (item) =>
          item.key === "art-criticism-media-reportage" &&
          setArtCriticismData(item.value)
      );
    };

    getData();
    fetchTitle()
    // eslint-disable-next-line
  }, []);

  const [tabPanelData, setTabPanelData] = useState([])
  function formatData() {
    return [
      {
        name: `${explore.CATALOGUE}`,
        component:<Catalouge data={catalougeData} />,
      },
      {
        name: `${explore.EXHIBITS}`,
        component:<Exhibits data={exhibitsData} />,
      },
      {
        name: `${explore.EXHIBITION_PHOTOGRAPHS}`,
        component: <ExhibitionsPhotographs data={exhibitionPhotoData} />,

      },
      {
        name: `${explore.ART_CRITICISM_MEDIA_REPORTAGE}`,
        component: <ArtCriticismMediaReportage data={artCriticismData} />,

      },

    ];
  }
  useEffect(()=>{
    if(masterlist!==undefined){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist,exhibitsData])

  return (
    <>
  <HStack pt={6}>
  {group && group === 'Group' ? (
    <BiUser
    color="black"
    display="inline"
    fontSize="16px"
    />
    ) : group === 'Triennale'?
    (
    <BiUser
        color="black"
        display="inline"
        fontSize="11px"
    />
    )
    : (
    <BiUser
        color="black"
        display="inline"
        fontSize="16px"
    />
    )} 
  <Box>
    <H3 fontWeight="500" color="color.blue">{exhibitionTitle1}</H3>
    <H3 fontWeight="500" color="color.blue">{exhibitionTitle2}</H3>
  </Box>
  </HStack>
      {tabPanelData && tabPanelData.length > 0 && (
            <TabMain data={tabPanelData}/>
          )}
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import * as getEssayData from "./../../../../services/Essays.js";
import { Box, Container, HStack, Stack } from "@chakra-ui/react";
import { CopyPasteService } from "../../../../services/CopyPaste";
// import { FullScreenImage } from "../../Component/FullScreenImage";
import Grid from "../../../../FunctionalComponents/SimpleGrid/Grid";
import ImageWithCitation2 from "../../../../FunctionalComponents/SimpleGrid/ImageWithCitation2";
import { FullScreenImage } from "../../../../FunctionalComponents/FullScreenImage";
import { totalFilterCount, updateFacetsData } from "../../../../util/helper";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../../CommonComponents/NoMoreToLoad.js";
import { FilterButton } from "../../../../CommonComponents/FilterButton.js";
import FilterPills from "../../../../components/FilterPills/FilterPills.js";
import { DataNotFound } from "../../../../CommonComponents/DataNotFound.js";
import { FilterBox } from "../../../../FunctionalComponents/Filter/FilterBox.js";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

export default function Essay({ masterlist,setEssayCount }) {
  const [essayData, setEssayData] = useState([]);

  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState({ search: "", filters: {}, sortBy_v: "" });
  const [apply, setApply] = useState(false);
  const [facets, setFacets] = useState([]);
  const [setSort] = useState([]);
  const [filterPills, setFilterPills] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const initialLoadComplete = useRef(false);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType=parts[1];


  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches", e.target.value)
  };

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }

    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }

    if (selectedString !== type) {
      selectedString = type;
    }

    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
 
  const findItem = (items, key) => items.find(it => it.key === key) || {};

  const citationFactor = (data) => {
    return data.map((item) => {
      const fullImage = findItem(item, "direct_url_of_cover_image").value || "";
      const titleItem = findItem(item, "title");
      const author = findItem(item, "creator_name");
      const dateofArt = findItem(item, "date_of_published_edition_in_library");
      const title_of_essay = titleItem.value ? titleItem : "";
  
      return {
        desc: item.find((it) => it.key === "bibliographic_citation")?.value ? item.find((it) => it.key === "bibliographic_citation")?.value : "",
        image: fullImage ? fullImage : "",
        designerdesc: item.find((it) => it.key === "bibliographic_caption")?.value ? item.find((it) => it.key === "bibliographic_caption")?.value : "",
        content: item.find((it) => it.key === "description")?.value ? item.find((it) => it.key === "description")?.value : "",
        fullImg: fullImage ? fullImage : "",
        designerData: [title_of_essay, author, dateofArt],
      };
    });
  };

  const getData = async () => {
    try {
    const result = await getEssayData.essayData(
      masterlistType,
      masterlist,
      filterOptions,
      page
    );

    if (result && result?.data.length > 0) {
        const processedData = citationFactor(result?.data);
        setEssayData(processedData);
        setEssayCount(result?.total)
        if (!initialLoadComplete.current) {
          setPage((prevPage) => prevPage + 1);
          initialLoadComplete.current = true;
        }
        if (processedData?.length >= result?.total) {
          setHasMore(false);
        }

        if (result?.facetsHeaders) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
          if (formattedFacets && formattedFacets.length > 0) {
            setFilterOptions(prevFilterOptions => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                ...formattedFacets.reduce((acc, facet) => {
                  acc[facet.key] = [];
                  return acc;
                }, {})
              }
            }));
          }
        }

        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
      }
    } 
    catch (error) 
    {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const fetchMoreData = async () => {
    try {
          const result = await getEssayData.essayData(
            masterlistType,
            masterlist,
            filterOptions,
            page
          );

          if (result && result?.data) 
          {
            const processedData = citationFactor(result?.data);
            setEssayData((prevData) => [...prevData, ...processedData]);
            setPage(prevPage => prevPage + 1);
            const sortedFacets = result?.sortByHeader?.map(obj => {
              const key = Object.keys(obj)[0];
              return { key: key, display: obj[key] };
            });
            setSort(sortedFacets);

            if (essayData.length >= result?.total) {
              setHasMore(false);
            }
          }
        }
        catch (error) 
        {
          console.error('Error fetching data:', error);
        }
  }

  async function fetchFilterData(page = 1) {

    try {
      const result = await getEssayData.essayData(
        masterlistType,
        masterlist,
        filterOptions,
        page
      );

      if (result) {
        const processedData = citationFactor(result?.data);
        setEssayData(processedData);
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);

        if (essayData.length >= result?.total) {
          setHasMore(false);
        }

        const filtercnt= totalFilterCount(filterOptions?.filters)
        
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {

    if (apply) {
      setApply(false);
      fetchFilterData();
      setFilterPills(filterOptions)
      const filtercnt = totalFilterCount(filterOptions?.filters)
      setFilterCount(filtercnt)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const handleReset = () => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setFilterCount(0)
    setSearchTerm("");
  }

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };

  return (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      {!showFilterBox && <FilterButton marginRight={"-65px"} onClick={handleFilterClick} />}
      <Container
        maxW="auto"
        className="songs"
        px={{ base: "4", md: "0px" }}
        pb="8"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {essayData.length > 0 ? (
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
              {((filterPills &&  filterPills!==undefined && filterCount>0) || filterOptions?.search) &&
                <FilterPills
                filters={filterPills}
                setFilterOptions={setFilterOptions}
                setApply={setApply}
                setSearchTerm={setSearchTerm}
                setFilterPills={setFilterPills}
                filteredResultCount={filteredResultCount}
                />
              }
            <InfiniteScroll
              dataLength={essayData.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<Box textAlign="center" marginLeft={"50%"}>
              <Stack marginTop={"20px"}>
                  <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
              </Stack>
            </Box>}
              endMessage={<NoMoreToLoad/>}
              >
              <Grid
                data={essayData}
                columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                spacing={{ base: "5px", md: "24px" }}
                handleImageClick={handleImageClick}
                component={ImageWithCitation2}
              />
            </InfiniteScroll>
            </HStack>
          ) : (
            <>
            <Stack
              onCopy={(e) =>
                CopyPasteService(e, `${window.location.href}/0/1`)
              }
              margin={"auto"}
            >
              <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
              {filterPills &&  filterPills!==undefined && filterCount>0&&
                <FilterPills
                filters={filterPills}
                setFilterOptions={setFilterOptions}
                setApply={setApply}
                setFilterPills={setFilterPills}
                />
              }
            </Box>
              <DataNotFound />
            </Stack>
          </>
          )}
          <HStack
              marginLeft={"4"}
              marginRight={"-65px!important"}
              className="mt-0"
              alignItems={"flex-start"}
              pos={"relative"}
            >
              {showFilterBox && (
                <FilterBox
                setShowFilterBox={setShowFilterBox}
                setApply={setApply}
                apply={apply}
                handleCheckboxChange={handleCheckboxChange}
                handleSearch={handleSearch}
                filterOptions={filterOptions}
                filteroptionData={facets}
                setFilterOptions={setFilterOptions}
                setSearchTerm={setSearchTerm}
                filterCount={filterCount}
                searchTerm={searchTerm}
                handleReset={handleReset}
                className="box-filter-film4"
                />
              )}
            </HStack>

        </Stack>
      </Container>
        {fullScreenImage && (
          <FullScreenImage
            isOpen={fullScreenImage}
            onClose={() => showImages(null, false)}
            imageData={ImgData}
            data={essayData}
            clickedImageIndex={clickedImageIndex}
            setClickedImageIndex={setClickedImageIndex}
            bannerFlag={false}
            title="easayimages"
          />
        )}
    </Stack>
  );
}

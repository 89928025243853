import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Text,
  Image,
} from "@chakra-ui/react";

//Core files
import React, { useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

//Services files
import * as getLandingMasterlistData from "../../services/SectionService";
import * as getIntroductionData from "../../services/EventExhibition";
import * as getPostData from "../../services/Post";
import * as getInsightData from "../../services/Insight";

//Component files
import ReadMore from "../PersonalityPolymath/ReadMore";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import AccordianText3 from "../../FunctionalComponents/Accordion/AccordianText3";

//Language files
import { common, explore, osiansCinefan } from "../../constants/constants";
import AccordianText from "../../FunctionalComponents/Accordion/AccordianText";
import Accordian from "../../FunctionalComponents/Accordion/Accordian";
import { getImageURL } from "../../util/getImage";
import DrawerFamilyDetails from "../../FunctionalComponents/Personality_Introduction/Drawer/Personalia/DrawerFamilyDetails";
import DrawerModalDetails from "../../FunctionalComponents/Personality_Introduction/Drawer/Personalia/DrawerModalDetails";
import { BodyOfWorkBody } from "./BodyofWork";
import { EconomicsofArtsBody } from "./EconomicsofArts";
import { LibraryAndArchive } from "../../components/ResearchCentre/LibraryAndArchive";
import AccordianText6 from "../../FunctionalComponents/Accordion/AccordianText6";

//Image file
//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`

export default function Introduction({ children }) {
  const [title, setTitle] = React.useState();
  const [date, setDate] = React.useState();
  const [datedod, setDateDod] = React.useState();
  const { isOpen, onClose } = useDisclosure();
  const [readMoreText, setReadMore] = useState(false);
  const { alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState(null);
  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  const [ setResearchCentreFlag] = React.useState(true);
  const [setBookFlag] = useState(false);
  const [setleftFlag] = useState(false);
  const [bookReadmore, setBookReadmore] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [postsCount, setPostsCount] = useState(0);
  const [insightsCount, setInsightsCount] = useState(0);

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        aliasAPI
      );

      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${result[0].find((item) => item?.key === "researchCategories")
              ?.value[0].alias
              }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item.key === "subject").value + "." + result[0].find((item) => item.key === "masterlist_type").value,
          }
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "start_date"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "end_date"
            )?.value;
            return acc;
          }, {})
        );
        setTitle(result[0].find((item) => item?.key === "full_name")?.value)
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        masterlistType,
        aliasAPI
      );

      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${
              it?.find((item) => item?.key === "masterlist_alias")?.value
            }/agraphy/${
              it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value==="body-of-work"?it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value+`/overview/catalouge`:it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value
            }/${it.find(
              (item) =>
                item.key === "landing_page_abbreviation"
            )?.value==="research-centre" ?`library`:``}
             `,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };

    const getPostsCount = async () => {
      const result = await getPostData.getPostsByMasterlist(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setPostsCount(result?.data?.length)
      }
    };

    const getInsightsCount = async () => {
      const result = await getInsightData.getInsightsByMasterlist(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setInsightsCount(result?.data?.length)
      }
    };

    getIntroData();
    getLandingData();
    getPostsCount();
    getInsightsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasAPI]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh)"
        title={title}
        date={date}
        datedod={datedod}
        readMoreText={readMoreText}
        setReadMore={setReadMore}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        masterlistType={masterlistType}
        postsCount={postsCount}
        insightsCount={insightsCount}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            title={title}
            date={date}
            datedod={datedod}
            readMoreText={readMoreText}
            setReadMore={setReadMore}
            aliasAPI={aliasAPI}
          />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        {introData ? (
        <Routes>
        <Route path="/introduction"
        element={
        <IntroductionBody
          setTitle={setTitle}
          setDate={setDate}
          setDateDod={setDateDod}
          readMoreText={readMoreText}
          setReadMore={setReadMore}
          aliasAPI={aliasAPI}
        />
        }
        />
          <Route path="/economics-of-culture/:tabIndex?"
            element={
            <EconomicsofArtsBody
              winnerName={aliasAPI}
            />
            }/>
          <Route path="/body-of-work/:tabValue?/:innerTab?/:childTab?/:screeningAlias?"
            element={
            <BodyOfWorkBody
              winnerName={aliasAPI}
              setReadmoreNavigation={setReadmoreNavigation}
              readmoreNavigation={readmoreNavigation}
            />
            }/>
            <Route path="/research-centre/:tabValue?/:bookAlias?/:bookDetails?"
            element={
              <LibraryAndArchive
              masterlist={aliasAPI}
              setReadmoreNavigation={setReadmoreNavigation}
              readmoreNavigation={readmoreNavigation}
              masterlistType={masterlistType}
              setResearchCentreFlag={setResearchCentreFlag}
              setBookFlag={setBookFlag}
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              setleftFlag={setleftFlag}
              bookReadmore={bookReadmore}
              setBookReadmore={setBookReadmore}
            />
            }/>
        </Routes>
        ) : (
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.WEBPAGE_COMING_SOON}
          </Text>
        )}
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  readMoreText,
  setReadMore,
  aliasAPI,
  horislinkData,
  artistDetails,
  introData,
  masterlistType,
  insightsCount,
  postsCount,
  ...rest
}) => {

  const data = {
    oSections: [
      {
        to: `/${masterlistType}/${aliasAPI}/posts`,
        title: "",
        text: postsCount>0 ? `${explore.POSTS} (${postsCount})`:explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/insights`,
        title: "",
        text: insightsCount>0 ? `${explore.INSIGHTS} (${insightsCount})`:explore.INSIGHTS,
      },
    ]
  };

  const navigate = useNavigate();
  const handleNavigation = (alias) => {
    if (readMoreText) {
      setReadMore(false);
    } else {
      navigate(-1);
    }
  };

  // Need this code for refrence

  // const [drawerName, setDrawerName] = useState("")

  // const artistLinksData = [
  //   // {
  //   //   name: "Aruna Vasudev"
  //   // },
  //   // {
  //   //   name: "Osian's Connoisseurs of Arts Pvt. Ltd.",
  //   // },
  // ];

  // const drawerData = [
  //   {
  //     name: "Osian's 9th CInefan & Aruna Vasudev",
  //     title: "Aruna Vasudev",
  //     links: [
  //       { link_name: "Exhibition Invitation Cards", link: "" },
  //       { link_name: "Newspaper Articles", link: "" },
  //       { link_name: "Research & Reference Material", link: "", },
  //     ],
  //     Image: '',
  //     desc: "",
  //     masterlist: {
  //       text: "Visit Lalit Kala Masterlist →",
  //       link: "",
  //     },
  //   }, {
  //     name: "Osian's 9th CInefan & Osian's Connoisseurs of Arts Pvt. Ltd",
  //     title: "Osian's Connoisseurs of Arts Pvt. Ltd.",
  //     links: [
  //       { link_name: "Exhibition Invitation Cards", link: "" },
  //       { link_name: "Newspaper Articles", link: "" },
  //       { link_name: "Research & Reference Material", link: "" },
  //     ],
  //     Image: '',
  //     desc: "",
  //     masterlist: {
  //       text: "Visit Geeta Kapur Masterlist →",
  //       link: "",
  //     },
  //   }];

  return (
    <>
      <CommonLeftSidebar
       sections={introData}
       oSections={data.oSections}
       hMenu={horislinkData}
       artistDetails={artistDetails}
       backTo={data.backNavigation}
       handleNavigation={handleNavigation}
       leftFlag={true}
       readMoreText={readMoreText}
       aliasAPI={aliasAPI}
      />
    </>
  );
};

const IntroductionBody = ({
  setTitle,
  setDate,
  setDateDod,
  readMoreText,
  setReadMore,
  aliasAPI,
  masterlistType
}) => {
  const [contentAwaited, setContentAwaited] = useState(false);
  const masterlistTitle = aliasAPI;
  const [masterlistImage, setMasterListImage] = useState("");
  const [accordianData, setAccordianData] = useState([]);
  const [familyImgData, setFamilyImgData] = useState(null);
  const [familyFullScreenImage, setFamilyFullScreenImage] = useState(false);

  const showFamilyMemberImages = (images, flag) => {
    if (images) {
      setFamilyImgData(images);
    } else {
      setFamilyFullScreenImage(flag);
      setFamilyImgData(null);
    }
  };

  const showFullImages = (flag) => {
    setFamilyFullScreenImage(flag);
  };

  React.useEffect(() => {
    const getIntroData = async () => {
      let preface = null;

      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        masterlistTitle
      );

      if (result) {
        setContentAwaited(false);
        setMasterListImage(
          result[0].find((item) => item?.key === "image_alias")?.value
        );
        preface = result[0].find((item) => item?.key === "preface")
          ?.value
      } else {
        setContentAwaited(true);
      }

      const curatorItem = result[0].find(item => item.key === "curator");
      const founder_and_presidentItem = result[0].find(item => item.key === "founder_and_president");
      const founder_and_chairmanItem = result[0].find(item => item.key === "founder_and_chairman");
      const joint_directorsItem = result[0].find(item => item.key === "joint_directors");
      const asian_arab_competition_juryItem = result[0].find(item => item.key === "asian_arab_competition_jury");
      const indian_competition_juryItem = result[0].find(item => item.key === "indian_competition_jury");
      const first_features_competition_juryItem = result[0].find(item => item.key === "first_features_competition_jury");

      const team = [];

      if (founder_and_presidentItem) {
        founder_and_presidentItem.value.forEach(curatorObj => {
          team.push({ key: curatorItem.key, value: curatorObj.full_name, display: founder_and_presidentItem?.display });
        });
      }

      if (founder_and_chairmanItem) {
        founder_and_chairmanItem.value.forEach(curatorObj => {
          team.push({ key: curatorItem.key, value: curatorObj.full_name, display: founder_and_chairmanItem?.display });
        });
      }

      if (joint_directorsItem) {
        const artists = joint_directorsItem.value.map(artistObj => ({ full_name: `${artistObj.full_name}`}));
        team.push({ key: joint_directorsItem.key, value: artists, display: joint_directorsItem?.display });
      }

       if (curatorItem) {
        const artists = curatorItem.value.map(artistObj => ({ full_name: `${artistObj.full_name}`}));
        team.push({ key: curatorItem.key, value: artists, display: curatorItem.display });
      }
      let highlights = [];

      let asianTeam = [];
      let indianTeam = [];

      if (asian_arab_competition_juryItem) {
         asianTeam = asian_arab_competition_juryItem.value.map(artistObj => ( `${artistObj.full_name}`));
      }

      if (first_features_competition_juryItem) {
        highlights = first_features_competition_juryItem.value.map(artistObj => ( `${artistObj.full_name}`));
      }

      if (indian_competition_juryItem) {
        indianTeam = indian_competition_juryItem.value.map(artistObj => ( `${artistObj.full_name}`));
      }

      if (preface !== null) {
        const formattedData = formatData(preface, team, asianTeam, indianTeam, highlights)
        setAccordianData(formattedData)
      }
    };
    getIntroData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlistType, masterlistTitle]);

  function formatData(introData, team, asianTeam, indianTeam, highlights) {
    const components = [
      {
        name: `${osiansCinefan.PREFACE}`,
        data: introData,
        component: <AccordianText fontWeight="400"  lineHeight="16.44px" data={introData ? introData :common.CONTENT_AWAITED} />,
      },
      {
        name: `${osiansCinefan.PERSONALIA}`,
        data: team,
        component: <AccordianText6 personalia={team} />,
      },
      {
        name: `${osiansCinefan.ASIAN_ARAB_COMPETITION_JURY}`,
        data: asianTeam,
        component: <AccordianText3 data={asianTeam} fontWeight={"400"} />,
      },
      {
        name: `${osiansCinefan.INDIAN_COMPETITION_JURY}`,
        data: indianTeam,
        component: <AccordianText3 data={indianTeam} fontWeight={"400"} />,
      },
      {
        name: `${osiansCinefan.FIRST_FEATURES_COMPETITION_JURY}`,
        data: team,
        component: <AccordianText3 data={highlights} fontWeight={"400"} />,
      }
    ];

    return components
  }

  return (
    <>
      {readMoreText ? (
        <ReadMore />
      ) : (
        <>
          <Stack>
            {contentAwaited ? (
              <Text
                py={"370px"}
                fontSize={"45px"}
                fontWeight="700"
                textAlign="center"
              >
                Content Awaited
              </Text>
            ) : (
              <>
                <Container maxW="auto" px="0" className="songs">
                  <Stack
                    height="calc(100vh - 64px)"
                    position={"relative"}
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    <HStack px="0" width={{ base: "100%", md: "775px" }}>
                    <Image
                        src={getImageURL(masterlistImage)}
                        fallbackSrc={placeholderImg}
                        width={"100%"}
                        height={"100%"}
                        objectFit={"cover"}
                      />                    </HStack>
                    <HStack zIndex={"99"} alignItems="flex-start" overflowY={"scroll"} borderLeft={"1px"} borderColor={"#e8e8e8"} className="mt-0">
                      <Box px="5" width={{ base: "100%", md: "425px" }}>
                        {accordianData.length > 0 && <Accordian data={accordianData} />}
                      </Box>
                    </HStack>
                  </Stack>
                </Container>
                <DrawerFamilyDetails
                  familyImgData={familyImgData}
                  showFamilyMemberImages={showFamilyMemberImages}
                  showFullImages={showFullImages}
                  closeIcon={closeIcon}
                  width="766px"
                />
                <DrawerModalDetails
                  familyImgData={familyImgData}
                  showFullImages={showFullImages}
                  familyFullScreenImage={familyFullScreenImage}
                />
                <Stack display={{ base: "flex", md: "none" }}>
                  <BottomMainMenu />
                </Stack>
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
};
import {
  Box,
    Text,
  } from "@chakra-ui/react";
  import React from "react";
  
  export default function AccordianText5({data,width="100%", py="2", textAlign="justify", fontSize="16px", fontWeight="400", lineHeight="18.78px"}) {
    return (
      <>
       <Box maxH={"200px"}  overflowY="auto">
        {data && data?.length>0 && data?.map((item,index)=>{
            return(
             
                <Text
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                  color="black"
                  lineHeight={lineHeight}
                  textAlign={textAlign}
                  width={width}
                  py={py}
                  key={index}
                >
                  {item}
                </Text>
               
              )
        })
          }
           </Box>
      </>
    );
  }
  
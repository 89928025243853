import React from 'react'
import ReactEcharts from "echarts-for-react";
import { getChartOptions } from '../../util/graphHelper';

function BarChart({ data }) {
  const options = getChartOptions(data);
    return (
    <>
    <ReactEcharts
          option={options}
          style={{ height: "270px", width: "395px" }}
        />
    </>
  )
}

export default BarChart
import { Box, Container, HStack, Image, Stack } from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Service file
import * as getMonumentsData from "../../../../services/Location";

//Component files
import { FilterButton } from "../../../../CommonComponents/FilterButton";

//Langugae file
import { explore } from "../../../../constants/constants";
import Grid from "../../../../FunctionalComponents/SimpleGrid/Grid";
import ImageWithCitation4 from "../../../../FunctionalComponents/SimpleGrid/ImageWithCitation4";
import { FilterBox } from "../../../../FunctionalComponents/Filter/FilterBox";
import { totalFilterCount, updateFacetsData } from "../../../../util/helper";
import NoMoreToLoad from "../../../../CommonComponents/NoMoreToLoad";
import InfiniteScroll from "react-infinite-scroll-component";
import FilterPills from "../../../../components/FilterPills/FilterPills";
import { CopyPasteService } from "../../../../services/CopyPaste";
import { DataNotFound } from "../../../../CommonComponents/DataNotFound";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

function GridView({ aliasAPI }) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const navigate = useNavigate();

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const handleImage = (it) => {
    navigate(`${it}`);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    search: "",
    filters: {}
  });
  const [apply, setApply] = useState(false);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
      case "gate":
        selectedString = filterOptions.gate;
        break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }

    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }

    if (selectedString !== type) {
      selectedString = type;
    }

    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
      case "gate":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          gate: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };


  const handleReset = () => {
    setFilteredResultCount(0);
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setFilterCount(0)
    setSearchTerm("");
  }

  //api INTEGRATION
  const [facets, setFacets] = useState([]);
  const [sort, setSort] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [filterPills, setFilterPills] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  const citationFactor = (data) => {
    return (
      data?.length > 0 &&
      data?.map((item) => {
        return {
          desc: item?.find((nestedItem) => nestedItem?.key === "bibliographic_citation")?.value,
          image: item?.find((nestedItem) => nestedItem?.key === "image_alias")?.value,
          srNo: item?.find((nestedItem) => nestedItem?.key === "sr_no")?.value,
          monumentAlias: item?.find((nestedItem) => nestedItem?.key === "alias")?.value,
        };
      })
    );
  };
  async function fetchData() {
    if (!navigator.onLine) {
      alert(
        "No network connection. Please check your internet connection and try again."
      );
      return;
    }
    try {
      setIsLoading(true);
      const result = await getMonumentsData.getMonuments(
        masterlistType,
        aliasAPI,
        filterOptions,
        1
      );
      setIsLoading(false);
      if (result && result?.data?.length > 0) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        setPage((prevPage) => prevPage + 1);
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);

          if (formattedFacets && formattedFacets?.length > 0) {
            setFilterOptions((prevFilterOptions) => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                ...formattedFacets.reduce((acc, facet) => {
                  acc[facet.key] = [];
                  return acc;
                }, {}),
              },
            }));
          }
        }

        if (result.total === result?.data.length) {
          setHasMore(false);
        }

        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    setFilterPills(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchMoreData = async () => {
    if (!navigator.onLine) {
      alert(
        "No network connection. Please check your internet connection and try again."
      );
      return;
    }
    try {
      const result = await getMonumentsData.getMonuments(
        masterlistType,
        aliasAPI,
        filterOptions,
        page
      );

      if (result) {
        const processedData = citationFactor(result?.data);
        setData((prevData) => [...prevData, ...processedData]);
        setPage((prevPage) => prevPage + 1);
        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        if (data?.length === result?.total) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  async function fetchFilterData(page = 1) {
    try {
      setIsLoading(true);
      const result = await getMonumentsData.getMonuments(
        masterlistType,
        aliasAPI,
        filterOptions,
        page
      );
      setIsLoading(false);

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        setPage((prev) => prev + 1);
        const sortedFacets = result?.sortByHeader?.map((obj) => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
        });
        setSort(sortedFacets);
        const filtercnt = totalFilterCount(filterOptions?.filters);
        if (filtercnt > 0 || filterOptions?.search) {
          setFilteredResultCount(result?.total);
        } else {
          setFilteredResultCount(0);
        }
        if (result?.data?.length === result?.total) {
          setHasMore(false);
        }

        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (apply) {
      setApply(false);
      setHasMore(true);
      setPage(1)
      fetchFilterData();
      setFilterPills(filterOptions)
    }
    const filtercnt = totalFilterCount(filterOptions?.filters)
    setFilterCount(filtercnt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);
  return (
    <>
      {!isLoading ? (

        <Stack flexDirection={"row-reverse"} paddingTop={"4"}>
          {!showFilterBox && (
            <FilterButton marginRight={"-64px"} marginTop="0px" onClick={handleFilterClick} />
          )}
          <Container maxW="auto" px="0px">
            <Stack flexDirection={"row"} gap={8} justifyContent="center">
              <Stack>
                {data && data?.length > 0 ? (
                  <>
                    {((filterPills &&
                      filterPills !== undefined &&
                      filterCount > 0) ||
                      filterOptions?.search !== "") && (
                        <FilterPills
                          filters={filterPills}
                          setFilterOptions={setFilterOptions}
                          setApply={setApply}
                          setSearchTerm={setSearchTerm}
                          setFilterPills={setFilterPills}
                          filteredResultCount={filteredResultCount}
                        />
                      )}
                    <InfiniteScroll
                      dataLength={data?.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={
                        <Box textAlign="center" marginLeft={"50%"}>
                          <Stack marginTop={"20px"}>
                            <img
                              src={loaderImg}
                              width={"100px"}
                              height={"100px"}
                              alt="Loading"
                            />
                          </Stack>
                        </Box>
                      }
                      endMessage={<NoMoreToLoad />}
                    >
                      <Grid
                        data={data}
                        handleImage={handleImage}
                        columns={{ base: 2, md: 3, lg: 3, xl: 4 }}
                        spacing="35px"
                        component={ImageWithCitation4}
                      />
                    </InfiniteScroll>
                  </>
                ) : (
                  <>
                    <Stack
                      onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
                      margin={"auto"}
                    >
                      <Box
                        position={"relative"}
                        top="-70px"
                        right={"auto"}
                        left={"0px"}
                      >
                        {filterPills &&
                          filterPills !== undefined &&
                          filterCount > 0 && (
                            <FilterPills
                              filters={filterPills}
                              setFilterOptions={setFilterOptions}
                              setApply={setApply}
                              setFilterPills={setFilterPills}
                            />
                          )}
                      </Box>
                      <DataNotFound />
                    </Stack>
                  </>
                )}
              </Stack>
              <HStack className="mt-0" alignItems={"flex-start"}>
                {showFilterBox && (
                  <FilterBox
                    setShowFilterBox={setShowFilterBox}
                    setApply={setApply}
                    explore={explore}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSearch={handleSearch}
                    filterOptions={filterOptions}
                    filteroptionData={facets}
                    sort={sort}
                    setFilterOptions={setFilterOptions}
                    setSearchTerm={setSearchTerm}
                    filterCount={filterCount}
                    searchTerm={searchTerm}
                    handleReset={handleReset}
                    className="box-filter-film2"
                    marginRight={"-64px!important"}
                    apply={apply}
                  />
                )}
              </HStack>
            </Stack>
          </Container>
        </Stack>)
        :
        (
          <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
            <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
          </HStack>
        )
      }
    </>
  );
}

export default GridView;

import { Box, Checkbox, Flex, HStack, Image, Text } from "@chakra-ui/react";
import React from "react";
import { common } from "../../constants/constants";
import { IoTriangleOutline } from "react-icons/io5";
import { FaUserAlt } from "react-icons/fa";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const pplImg = `${IMAGE_BASE_PATH}/ui/Chevron/people.png`

export default function FilterBody({
  filterOptions,
  data,
  Ukey,
  handleCheckboxChange,
  element,
  setApply
}) {
  return (
    <>
      {data && data.length > 0 ? (
        data.map((it, index) => {
          return (
            <>
              {(it?._id!=="" || it!=='') && it?._id!==null && <Flex borderBottom={"1px"} borderBottomColor={"color.lightgray5"} key={index}>
                <Checkbox
                  borderColor={"color.primary"}
                  _checked={{
                    "& .chakra-checkbox__control": {
                      background: "color.primary",
                    },
                  }}
                  iconColor="white"
                  cursor="pointer"
                  marginRight={"5px"}
                  isChecked={filterOptions?.filters?.[element?.key]?.includes(
                    it?._id
                  )}
                  onChange={() => {
                    handleCheckboxChange(Ukey, it?._id ? it?._id:``);
                    // setApply((prev) => !prev);

                  }}
                />
                <Box p="1">
                  {Ukey!=="type_of_event"?<Text fontSize={"14px"}>{it?._id || it._id==="" ? it?._id : it} {it?.count && `(${it?.count})`}</Text> :
                  <>{it?._id === 'Group' ? (
                    <>
                    <Image
                        src={pplImg}
                        display="inline"
                        marginRight="8px"
                    /> 
                    {it?.count && `(${it?.count})`}
                    </>
                ) :it?._id === 'Triennale'?
                (
                  <HStack>
                    <IoTriangleOutline
                        color="black"
                        display="inline"
                        fontSize="11px"
                    />
                    <Text>{it?.count && `(${it?.count})`}</Text>
                    </HStack>
                )
                : (
                  <HStack>
                    <FaUserAlt
                        color="black"
                        display="inline"
                        fontSize="11px"
                    />
                    <Text>{it?.count && `(${it?.count})`}</Text>
                    </HStack>
                )}</>
                  }
                </Box>
              </Flex>}
            </>
          );
        })
      ) : (
        <Box p="1">
          <Text fontSize={"14px"}>{common.CONTENT_AWAITED}</Text>
        </Box>
      )}
    </>
  );
}

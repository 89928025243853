import React, { useEffect, useState } from 'react';
import { Box, Input, Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useRef } from 'react';

const DropdownWithSearch = ({ items, placeholder,formikFieldProps }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const [selectedItem, setSelectedItem] = useState('Country of residence');
  const inputRef = useRef(null);

  useEffect(() => {
    if (formikFieldProps.value) {
      setSelectedItem(formikFieldProps.value);
    }
  }, [formikFieldProps.value]);
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const filtered = items.filter((item) =>
      item?.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item?.name);
    formikFieldProps.onChange({ target: { name: formikFieldProps.name, value: item?.name } });
  };

  return (
    <Menu>
      <MenuButton 
        as={Button} 
        rightIcon={<ChevronDownIcon boxSize={10} fontSize={"40px"}/>}
        className="inputfield"
        w={"100%"}
        bg={"white"}
        border={"1px solid #989898"}
        height={"47px"}
        pl={4}
        pr={0}
        sx={{
          color: 'black',
          fontSize: '16px',
          lineHeight: '18.78px',
          fontWeight: '400',
          textAlign: 'left',  
          justifyContent: 'space-between', 
          width: '100%'
        }}
      >
        {selectedItem}
      </MenuButton>
      <MenuList width={{base: "100%", md:"270px"}} height={"200px"} overflowY={"scroll"}>
        {/* Search Input */}
        <Box padding="10px" w={"100%"}>
          <Input
            ref={inputRef}
            placeholder={placeholder || 'Search...'}
            value={searchTerm}
            onChange={handleSearch}
            autoFocus
            onBlur={() => inputRef.current && inputRef.current.focus()} // Re-focus on blur
          />
        </Box>

        {/* Dropdown Items */}
        {filteredItems && filteredItems?.length > 0 ? (
          filteredItems.map((item, index) => (
            <MenuItem key={index} onClick={() => handleItemClick(item)}>
              {item?.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No results found</MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default DropdownWithSearch;

import { Box, Container, HStack, Image, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react'
import { Link } from 'react-router-dom';
import Small from '../../Theme/Typography/Small';
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholder = `${IMAGE_BASE_PATH}/ui/DummyImage/x-small.png`;

export default function SearchCar({data,searchsTerm}) {
  const itemsToShow = useBreakpointValue({ base: 3, md: 7 }); 
  return (
    <Stack>
    <Container px="0" maxW="auto" className="songs">
      <Stack
      flexDirection={"row"} gap={4} justifyContent={"flex-start"} >
        <HStack marginInlineStart={"0rem!important"} alignItems={"flex-start"} width={"100%"} gap={{base:"15px",md:"28px"}}  pt={{ base: "0", md: "0" }}>
            <>
          {data?.hits?.length > 0 &&
           data.hits.slice(0, itemsToShow).map((item, index) => (
            <Stack  width={{base:"100%",md:"128px"}} marginInlineStart={"0rem!important"}>
      <Stack key={index}  alignItems={"flex-start"}  width={{base:"100%",md:"128px"}}>
        <Link to={item?.type === "generic" ? `${item?.route}/research-centre/all` : `${item?.route}`}>
          <Box
            display="flex"
            justifyContent="center"
            height={{base:"auto",md:"85px"}}
            width={{base:"100%",md:"128px"}}
            bg="white"
            border="1px solid"
            borderColor="#f1f1f1"
           
          >
            <Image
              src={item?.image}
              alt={item?.title || 'Image'}
              cursor="pointer"
              width="100%"
              objectFit="cover"
              height={"inherit"}
              fallbackSrc={placeholder}
            />
          </Box>
        </Link>
        </Stack>
        <Stack>
        <Small
          fontSize={{base:"12px!important",md:"14px!important"}}
          textAlign="left"
          color="color.blue"
          lineHeight={{base:"16px",md:"20px"}}
          py="1"
          className="mt-0"
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {item.quality === "primary" && (
            <Text as="span" className="primary-p-symbol">P - </Text>
          )}
          {item?.title}
        </Small>
        </Stack>
         </Stack>
           ))
          }     
            </>
          </HStack>
        </Stack>
      </Container>
    </Stack>
    )
}     
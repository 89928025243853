import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { MdHomeFilled } from "react-icons/md";

//Services files
import * as getLandingMasterlistData from "../../services/SectionService";
import * as getIntroductionData from "../../services/EventExhibition";
import * as getPostData from "../../services/Post";
import * as getInsightData from "../../services/Insight";

//component files
import LandingPageSlider from "../../FunctionalComponents/LandingPageSlider/LandingPageSlider";
import { AgraphyLinks } from "../../FunctionalComponents/AgraphyLinks";
import LeftVmenu from "../../FunctionalComponents/LeftVmenu";
import UpdateHelmet from "../../util/UpdateHelmet";

//Language files
import { explore } from "../../constants/constants";
import { getImageURL } from "../../util/getImage";
import Posts from "../../FunctionalComponents/Posts/Posts";
import Insights from "../../FunctionalComponents/Insight/Insights";
import _ from "lodash";

export default function LandingPage() {
  const [initialSlide, setInitialSlide] = useState(0);
  const [postsCount, setPostsCount] = useState(0);
  const [insightsCount, setInsightsCount] = useState(0);
  const [landingData, setLandingData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([])
  const { alias } = useParams();
  const masterlistTitle = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [loading, setLoading] = useState(true); // New state for loading
  const attr = parts[3];
  const keyMap = {
    'Introduction': 'introduction',
    'Economics of Culture': 'eoc',
    'Research Centre': 'rc',
    'Body of Work': 'bodyofwork'
  };

  useEffect(() => {
    const getLandingData = async () => {
      setLoading(true); // Set loading to true when starting the API call

      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        masterlistType,
        masterlistTitle
      );

      if (result) {
        const dataIntro = result;
        setLandingData(dataIntro);
      }
      setLoading(false); // Set loading to false after the API call is done

    };

    const getIntroData = async () => {
      setLoading(true); // Set loading to true when starting the API call

      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        masterlistTitle
      );

      if (result.length > 0) {
        setName(() => result[0].find((item) => item.key === "full_name")?.value);

        setAgraphyLink([
          {
            link: `/research-categories/${result[0].find((item) => item.key === "researchCategories")?.value[0]?.alias}`,  // replace with the actual link
            title: result[0].find((item) => item.key === "researchCategories")?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item.key === "subject").value + "." + result[0].find((item) => item.key === "masterlist_type").value,
          }
        ]);
      }
      setLoading(false); // Set loading to false after the API call is done

    };

    const getPostsCount = async () => {
      const result = await getPostData.getPostsCountByMasterlist(masterlistType, masterlistTitle);
      if (result) {
        setPostsCount(result);
      }
    };

    const getInsightsCount = async () => {
      const result = await getInsightData.getInsightsByMasterlist(
        masterlistType,
        masterlistTitle
      );
      if (result) {
        setInsightsCount(result?.length)
      }
    };


    getIntroData();
    getLandingData();
    getPostsCount();
    getInsightsCount();
  }, [masterlistTitle, masterlistType]);

  return (
    <SidebarWithHeader>
      <UpdateHelmet title={name} description={"lorem ipsum"} type={"article"} image={""} />
      <Stack width={"100%"}>
        <Container maxW={"auto"} px={"0px"}>
          {!loading ? (
             <Tabs
             align={{ xs: "start", sm: "end" }}
             w="full"
             variant="unstyled"
             defaultIndex={attr==="posts" ? 1 : attr==="insights" ? 2 : 0}
           >
             <HStack
               position={"fixed"}
               background={"white"}
               zIndex={9}
               paddingY={"16px"}
               top={"64px"}
               justifyContent={"center"}
               width={{base:"100%", md:"1344px"}}
             >
               <TabList
                 width={{base:"100%", md:"container.xl"}}
                 justifyContent={"space-between"}
                 flexDirection={{base: "column", md: "row"}}
                 pb={{base: "0", md: "2"}}
                 alignItems={"center"}
                 px={{base:"0px", md:"90px"}}
                 // className="explorepage-tablist"
               >
                 <Stack flexDirection={{base:"column",md:"row"}} alignItems={{base:"center"}} justifyContent={"space-between"} width={{base:"100%",md:"1100px"}}>
                 <HStack>
                 <Link to={`/${masterlistType}/${alias}`}>
                 <Tab
                   p="0"
                   textStyle={"tertiary"}
                   fontSize={{base:"35px", md:"45px"}}
                   fontWeight={"700"}
                   className="textWrap"
                   fontStyle="normal"
                   lineHeight={{base:"55px", md:"60px"}}
                   _selected={{
                     borderBottom: "4px",
                     borderColor: "#333333",
                   }}
                   onClick={() => {
                     setInitialSlide(initialSlide + 1);
                   }}
                 >
                    {name!==""?name:_.startCase(alias.replace(/-/g, ' '))}
                 </Tab>
                 </Link>
                 </HStack>
                 <HStack>
                 {postsCount > 0 ? (
                 <Link to={`/${masterlistType}/${alias}/posts`}>
                   <Tab
                     p="0"
                     className="textWrap"
                     color={"#333333"}
                     textStyle={"secondary"}
                     fontSize={"16px"}
                     lineHeight={"21.38px"}
                     fontWeight={"600"}
                     _selected={{
                       fontWeight: "bold",
                       fontSize: "16px",
                       marginRight: { base: "50px", md: "0px" },
                       borderBottom: "4px",
                       borderColor: "#333333",
                     }}
                     onClick={() => {
                       setInitialSlide(0);
                     }}
                   >
                     {`${explore.POSTS} ${postsCount > 0 ? `(${String(postsCount)})` : ``}`}
                   </Tab>
                 </Link>
                 ) : (
                     <Text
                     p="0"
                     className="textWrap"
                     color={"color.gray"}
                     textStyle={"secondary"}
                     fontSize={"16px"}
                     fontWeight={"600"}
                   >
                     {`${explore.POSTS} ${postsCount > 0 ? `(${String(postsCount)})` : ``}`}
                   </Text>
                 )}
                  <Text fontSize={"18px"} display={"block"}>{" | "}</Text>
                 {insightsCount > 0 ? (
                 <Link to={`/${masterlistType}/${alias}/insights`}>
                   <Tab
                     p="0"
                     className="textWrap"
                     color={"color.gray"}
                     textStyle={"secondary"}
                     fontSize={"16px"}
                     fontWeight={"600"}
                     _selected={{
                       fontWeight: "bold",
                       fontSize: "16px",
                       marginRight: { base: "50px", md: "0px" },
                       borderBottom: "4px",
                       borderColor: "color.gray",
                     }}
                     onClick={() => {
                       setInitialSlide(0);
                     }}
                   >
                     {`${explore.INSIGHTS} ${insightsCount > 0 ? `(${String(insightsCount)})` : ``}`}
                   </Tab>
                 </Link>
                 ) : (
                  <Text
                  p="0"
                  className="textWrap"
                  color={"color.gray"}
                  textStyle={"secondary"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                >
                     {`${explore.INSIGHTS} ${insightsCount > 0 ? `(${String(insightsCount)})` : ``}`}
                   </Text>
                 )}
                 </HStack>
                 </Stack>
               </TabList>
             </HStack>
 
             <TabPanels>
               <TabPanel p="0px" mt={{base:"115px", md:"115px"}} className="masterlistslider">
               {landingData && landingData?.length > 0 && (
                    <LandingPageSlider
                      initialSlide={initialSlide}
                       data={
                        landingData &&
                        landingData.flatMap((cat) => {
                          return cat.flatMap((it) => {
                            const { key, value } = it;
                            const compName = `masterlist.${keyMap[value]}`;
                            if (key === "landing_page_title") {
                              return {
                                label: `${value}`,
                                componentName: compName,
                                to: `/${masterlistType}/${cat.find((item) => item.key === "masterlist_alias")
                                    .value
                                  }/agraphy/${
                                    cat?.find((item) => item?.key === "landing_page_abbreviation")
                                      ?.value==="body-of-work"?cat?.find((item) => item?.key === "landing_page_abbreviation")
                                      ?.value+`/overview/catalouge`:cat?.find((item) => item?.key === "landing_page_abbreviation")
                                      ?.value
                                  }/${cat.find(
                                    (item) =>
                                      item.key === "landing_page_abbreviation"
                                  )?.value === "research-centre" ? `all` : ``}`,
                                image: `${cat.find((item) => item.key === "image_alias")?.value ?
                                  getImageURL(cat.find((item) => item.key === "image_alias")?.value) : null
                                  }`,
                              };
                            } else {
                              return [];
                            }
                          });
                        })
                      }
                      className={"eventfilmfestival"}
                    />
                  )}
                  {agraphyLinks.length > 0 && (<AgraphyLinks marginTop="-70px" links={agraphyLinks && agraphyLinks} />)}
               </TabPanel>
               <TabPanel px={"0"} p="0px" mt={{base:"115px", md:"115px"}}>
               <Posts masterlist={alias} masterlistType={masterlistType} setPostsCount={setPostsCount}/>
               </TabPanel>
               <TabPanel p="0px" mt={{ base: "115px", md: "115px" }} >
                 <Insights masterlist={alias} masterlistType={masterlistType} setPostsCount={setInsightsCount} />
               </TabPanel>
             </TabPanels>
           </Tabs>
          ) : (
            <Text
              py={"40"}
              fontSize={"45px"}
              fontWeight="700"
              textAlign="center"
            >
              Loading...
            </Text>
          )}
        </Container>
      </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};
const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      handleNavigation={handleNavigation}
      LeftVmenuData={LeftVmenuData}
    />
  );
};

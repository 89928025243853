import {
  Box,
  CloseButton,
  Flex,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { common } from "../constants/constants";
import { BsArrowLeftCircleFill } from "react-icons/bs";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const circle = `${IMAGE_BASE_PATH}/ui/Chevron/sidecircle.svg`

export default function LeftVmenu({
  onClose,
  toggleModal,
  LeftVmenuData,
  subscribe,
  backArrow,
  display,
  handleNavigation,
  ...rest
}) {
  return (
    <>
      <Box
        bg={useColorModeValue("black", "gray.900")}
        w={{ base: "none", md: 14 }}
        display={{ base: "none", md: "block" }}
        pos="fixed"
        h="full"
        {...rest}
        color={"white"}
      >
        <Flex
          h="42px"
          alignItems="flex-end"
          m="4"
          justifyContent="space-between"
          flexDirection="row-reverse"
        >
          {onClose && (
            <CloseButton
              display={{ base: "none", md: "none" }}
              onClick={onClose}
            />
          )}{" "}
        </Flex>
        {LeftVmenuData &&
          LeftVmenuData.map((it, index) => (
            <HStack
              key={it.id}
              justifyContent={it.active ? "space-around" : "center"}
              borderLeft="4px"
              borderColor={it.active ? "#FF4E4E" : "black"}
              borderRadius={it.active ? "0px" : "none"}
              height={"51px"}
              pr="4px"
            >
              <Link to={it.link} id={it.id}>
                <IconButton
                  my="2"
                  py="4"
                  fontSize="24px"
                  bg={"black"}
                  color={it.active ? "white" : "#4C4C4C"}
                  _hover={{
                    color: "white",
                  }}
                >
                  {it.icon && React.createElement(it.icon)}
                </IconButton>
              </Link>
            </HStack>
          ))}
        <HStack justifyContent="center" px="4px"  pt="4" height={"80px"}>
          <Link to="/tris" my="5px" py="3" id="osianamacircleicon">
            <Image
              src={circle}
              width="auto"
              height={"100%"}
              px="1"
              pb="1"
            ></Image>
              <Text
                fontSize={"10px"}
                fontWeight="700"
                textStyle={"secondary"}
                color={"#0066B3"}
                textAlign="center"
              >
                {common.SUBSCRIBE}
              </Text>
          </Link>
        </HStack>
        {backArrow && (
          <Box
            pos={"absolute"}
            px="2"
            bottom={"80px"}
            onClick={handleNavigation}
          >
            <IconButton
              bg={"black"}
              color="#4C4C4C"
              _hover={{
                color: "white",
              }}
            >
              <BsArrowLeftCircleFill size={"30px"} />
            </IconButton>
          </Box>
        )}
      </Box>
      {/* For mobile view */}
      <Stack
        bg={useColorModeValue("black", "gray.900")}
        display={display}
        className="mobilemenu"
      >
        <Stack
          justifyContent={"space-evenly"}
          alignItems={"center"}
          flexDirection={"row"}
          gap={{base:"0px",md:"0px"}}
        >
{backArrow && (
          <HStack mt="2">
          
          <Box
            pos={"relative"}
            bottom={"0px"}
            onClick={handleNavigation}
          >
            <IconButton
               py="4"
               fontSize="24px"
              bg={"black"}
              color="#4C4C4C"
              _hover={{
                color: "white",
              }}
            >
              <BsArrowLeftCircleFill size={"22px"} />
            </IconButton>
          </Box>
       </HStack> )}
          {LeftVmenuData &&
            LeftVmenuData.map((it, index) => (
              <HStack
                justifyContent={it.active ? "space-around" : "center"}
                borderBottom="4px"
                flexDirection={"column"}
                key={index}
                borderColor={it.active ? "#FF4E4E" : "black"}
                borderRadius={it.active ? "0px" : "none"}
                height={"55px"}
              >
                <Link to={it.link} id={it.id}>
                  <IconButton
                    fontSize="24px"
                    bg={"black"}
                    color={it.active ? "white" : "#4C4C4C"}
                    _hover={{
                      color: "white",
                    }}
                  >
                    {it.icon && React.createElement(it.icon)}
                  </IconButton>
                </Link>
              </HStack>
            ))}
          <HStack justifyContent="center" px="4px" height={"51px"}>
            <Link to="/tris" my="5px" py="3" id="osianamacircleicon">
              <Image
                src={circle}
                width="auto"
                height={"100%"}
                px="1"
                pb="1"
              ></Image>
              {subscribe && (
                <Text
                  fontSize={"10px"}
                  fontWeight="700"
                  textStyle={"secondary"}
                  color={"#0066B3"}
                  textAlign="center"
                >
                  {common.SUBSCRIBE}
                </Text>
              )}
            </Link>
          </HStack>
        </Stack>
      </Stack>
    </>
  );
}

import { 
  Box, 
  HStack, 
  Image, 
  Stack, 
  Text 
} from "@chakra-ui/react";

//Core file
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";

//Service file
import { getIntroductionByAlias } from "../../../services/ResearchCategoryDetails";
import { getImageURL } from "../../../util/getImage";

//Language file
import { common } from "../../../constants/constants";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`;

function Introduction({rcAlias}) {
  const [sliderImages, setSliderImages] = useState([]);
  const [description, setDescription] = useState("");
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getIntroductionByAlias("", rcAlias);
        if (result) {
          // setSliderImages(result.data[0]?.find((nestedItem) => nestedItem?.key === "images")
          //     ?.value.split('|')
          //     .map((image) => image.trim())
          // );

          setSliderImages(result.data[0]?.find((nestedItem) => nestedItem?.key === "images")?.value);
          setDescription(result.data[0]?.find((nestedItem) => nestedItem?.key === "description")
          ?.value);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [rcAlias]);


  return (
    <>
      <Stack width={"100%"} position={"relative"} id="homeslider">
        <Swiper
          navigation={true}
          modules={[Autoplay, Navigation, Pagination, A11y]}
          className="mySwiper"
          slidesPerView={1}
          loop={true}
          height={"100%"}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          speed={800}
          onSwiper={(swiper) => {
          swiper.el.addEventListener('mouseenter', () => swiper.autoplay.stop()); 
          swiper.el.addEventListener('mouseleave', () => swiper.autoplay.start()); 
          }}
        >
          {sliderImages && sliderImages.length > 0 &&
            sliderImages?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    width={"100%"}
                    height={{ base: "375px", md: "650px" }}
                    borderY={"1px"}
                    borderColor={"#f1f1f1"}
                    background={"#e8e8e8"}
                    >
                    <Image
                      src={getImageURL(item)}
                      fallbackSrc={placeholderImg}
                      alt=""
                      width={"100%"}
                      objectFit={"contain"}
                      height={"inherit"}
                      border={{base:"1px",md:"0px"}}
                      borderColor={{base:'#dbdbdb',md:'#f1f1f1'}}
                    />
                  </Box>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Stack>
      <Stack flexDirection={"row"} paddingY={{base:"4",md:"8"}}>
        <HStack alignItems={"flex-start"} pt={{base:"8",md:"4"}}  px={{ base: "4", md: "16" }}>
          <Text fontSize={"18px"} marginInlineStart={"0rem!important"}>
            {description}
            <Link to="/research-category/read-more">
              <Text
                px="1"
                as={"span"}
                fontSize={"16px"}
                fontWeight={"600"}
                color={"#035DA1"}
                marginInlineStart={"0rem!important"}
              >
                {common.READ_MORE}
              </Text>
            </Link>
          </Text>
        </HStack>
      </Stack>
    </>
  );
}
export default Introduction;
import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

export default function ImageWithCitation5({
  data,
  handleImageClick
}) {

  return (
    <>
      {data?.length > 0 &&
        data?.map((it, index) => (
          <Box key={index} onClick={() => handleImageClick(it, index)} cursor={"pointer"} display="flex" flexDirection="column" alignContent="center">
            <Box width={"100%"} height={"263px"}>
            <Image src={it?.image} alt="" width={"249px"}height="inherit" objectFit="cover" />
            </Box>
            <Text 
             style={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              height:"93px"
            }}
            textAlign="left" onClick={() => handleImageClick(it, index)} color="#0066B3" py="4" fontSize="16px" fontWeight="400">
              {it?.citation_name}
            </Text>
          </Box>
        ))}
    </>
  );
}

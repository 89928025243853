import { HStack } from "@chakra-ui/react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";
import BarChart from "../../../components/DemographyCharts/Barchart";

export default function BarChartSwiper({ data,graphKey }) {
  return (
    <HStack width={{ base: "100%", md: "395px" }} id={"allsliderskk2"} mt={16}>
      <Swiper
        grabCursor={true}
        pointerEvents="auto"
        navigation={true}
        modules={[EffectFade, Navigation]}
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {data &&
          data?.length > 0 &&
          data?.map((it,index) => (
            <SwiperSlide key={index}>
              <BarChart
              data={it}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </HStack>
  );
}

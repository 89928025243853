import { Box, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { AiOutlinePicture } from '@react-icons/all-files/ai/AiOutlinePicture';
import { isArray } from 'lodash';
import React from 'react';

export default function AccordianEduction(props) {
  return (
    <>
      <Stack>
        {isArray(props?.educationData) ? (
          <>
            <HStack
              pb={"2"}
              pt="0"
              flexDirection={"row"}
              marginLeft={props.educationData.some(education => education?.image_alias) ? "30px" : "0px"}
            >
              <Stack>
                {props?.educationData?.map((education, educationIndex) => {
                  const imageObject = education?.image_alias || null;

                  return (
                    <React.Fragment key={educationIndex}>
                      <Text
                        fontSize={"16px"}
                        fontWeight={"700"}
                        marginTop={"0px !important"}
                      >
                        {education?.year ? <> {education?.year}</> : "#"}
                      </Text>
                      <HStack
                        marginLeft={imageObject ? "-25px!important" : "0px!important"}
                        alignItems={"flex-start"}
                        paddingBottom={"15px"}
                      >
                        {imageObject && (
                          <Box pt="1">
                            <AiOutlinePicture
                              onClick={() => props?.showEducationImages(imageObject, true)}
                              cursor={"pointer"}
                              color="#1E90FF"
                              size={18}
                            />
                          </Box>
                        )}
                        <Text
                          fontSize={"16px"}
                          fontWeight={"400"}
                          width={"280px"}
                        >
                          {education?.education}
                        </Text>
                      </HStack>
                    </React.Fragment>
                  );
                })}
              </Stack>
            </HStack>
            <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
          </>
        ) : (
          <Text
            fontSize={"14px"}
            fontWeight={"500"}
            color="black"
            lineHeight={"16px"}
            textAlign={"justify"}
            width={"100%"}
            py={"0"}
          >
            {props?.educationData}
          </Text>
        )}
      </Stack>
    </>
  );
}

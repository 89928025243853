import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { MdHomeFilled } from "react-icons/md";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { useTranslation } from "react-i18next";

//Component file
import ExplorePosts from "../pages/Explore/ExplorePosts";
// import Masterlists from "./Explore/Masterlist";
import MasterlistTab from "./Explore/MasterlistTab";
import LeftVmenu from "../FunctionalComponents/LeftVmenu";
import GlobalPost from "./Explore/GlobalPost";
import GlobalInsights from "./Explore/GlobalInsights";
import { WebpageComingSoon } from "../CommonComponents/WebpageComingSoon";

//Language file
import { explore } from "../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

export default function Explore() {

  const { t } = useTranslation("");
  const [attr, setAttr] = useState(0)
  const location = useLocation();
  let { tabIndex } = useParams();
  const [showMasterlistType, setShowMasterlistType] = useState(false);

  const pathToIndex = {
    '/posts': 1,
    '/insights': 2,
    '/masterlists': 3,
  };
  useEffect(() => {
    if (tabIndex) {
      if (parseInt(tabIndex) === 1 || parseInt(tabIndex) === 2) {
        setShowMasterlistType(true);
      }
    }
  }, [tabIndex]);
  useEffect(() => {
    const path = location.pathname;
    setAttr(pathToIndex[path] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);

  return (
    <SidebarWithHeader>
      <Stack width={"100%"}>
        <Container maxW={"auto"} px={{base:"15px",md:"62px"}}>
          <Tabs
            align={{ xs: "start", sm: "end" }}
            w="full"
            mt={{base:"2",md:"9"}}
            overflow={"hidden"}
            variant="unstyled"
            index={attr} // Use index instead of defaultIndex
            onChange={(index) => setAttr(index)} // Update attr on tab change
          >
            <Stack
              px="0px"
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={{base :showMasterlistType ? "flex-start" : "flex-start", md:showMasterlistType ?  "flex-start" : "space-between" }}
              width={"100%"}
            >
              <HStack
                width={"100%"}
                justifyContent={{ base: "flex-start", md: "space-between" }}>
                <TabList
                  flexDirection={{ base: "row", md: "row" }}
                  pb="4"
                  gap={"0px"}
                  justifyContent={{base : "flex-start", md: "space-between" }}
                  alignItems={{base:"flex-end",md:"center"}}
                  position={{base:"fixed",md:"relative"}}
                  top={{base:"64px",md:"px"}}
                  bg="white"
                  zIndex={999}
                  width={"inherit"}
                >
                  <Link to={`/explore`}>
                  {/* <Tab
                    p="0"
                    textStyle={"tertiary"}
                    fontSize={{ base: "30px", md: "50px" }}
                    fontWeight={"700"}
                    fontStyle="normal"
                    width={"fit-content"}
                    lineHeight={{ base: "45px", md: "67px" }}
                    marginRight={{base:"20px",md:"0px"}}
                    _selected={{
                      borderBottom: "4px",
                      borderColor: "#333333",
                    }}
                    className="textWrap"
                    zIndex={1}
                    onClick={() => {
                      setShowMasterlistType(false);
                    }}
                  >
                    {t(explore.EXPLORE_TAB_TITLE)}
                  </Tab> */}
                   <Box width={"300px"}>
                  <Tab
                    p="0"
                    textStyle={"tertiary"}
                    fontSize={{ base: "30px", md: "50px" }}
                    fontWeight={"700"}
                    fontStyle="normal"
                    width={"fit-content"}
                    lineHeight={{ base: "45px", md: "67px" }}
                    marginRight={{base:"20px",md:"0px"}}
                    gap={"15px"}
                    _selected={{
                      borderBottom: "4px",
                      borderColor: "#333333",
                      gap:"0px",
                      lineHeight:{base: "45px", md: "67px"}
                    }}
                    className="textWrap"
                    zIndex={1}
                    onClick={() => {
                      setShowMasterlistType(false);
                    }}
                  >
                    {location.pathname.includes("explore")?t(explore.EXPLORE_TAB_TITLE) : `${t(explore.EXPLORE_TAB_TITLE)}`} 
                    <Text   textStyle={"tertiary"}
                    fontSize={{ base: "30px", md: "35px" }}
                    paddingTop={"5px"}
                    fontWeight={"500"}
                              lineHeight={{ base: "45px", md: "55px" }}alignItems={"end"}>{location.pathname.includes("post")?`| Posts`:location.pathname.includes("insight")?`| INSIGHTS`:location.pathname.includes("masterlist")?"| Masterlists":''}</Text>
                  </Tab>
                  </Box>
                  </Link>
                  <Link to={`/posts`}>
                 
                  <Tab
                    p="0"
                    ml={{ base: "0px", md: "810px" }}
                    marginLeft={{base :showMasterlistType ? "" : "0px", md:showMasterlistType ?  "700px" : "685px" }}
                    color={"#333333"}
                    textStyle={"primary"}
                    fontSize={{base:"15px",md:"16px"}}
                    fontWeight={"600"}
                    zIndex={1}
                    _selected={{
                      borderBottom: {base:"2px",md:"4px"},
                      borderColor: "#333333",
                    }}
                    onClick={() => {
                      setShowMasterlistType(false);
                    }}
                  >
                    <Text
                     pr="5px"
                    >
                     {t(explore.POSTS)}                      
                    </Text>
                  </Tab>
                  </Link>
                  <Text display={{ base: "block", md: "block" }}>{" | "}</Text>
                  <Link to={`/insights`}>
                  <Tab
                    p="0"
                    color={"#333333"}
                    textStyle={"primary"}
                    fontSize={{base:"15px",md:"16px"}}
                    fontWeight={"600"}
                    zIndex={1}
                    _selected={{
                      borderBottom: {base:"2px",md:"4px"},
                      borderColor: "#333333",
                    }}
                    onClick={() => {
                      setShowMasterlistType(false);
                    }}
                  >
                    <Text
                      mx="5px"
                    >
                      {t(explore.INSIGHTS)}
                    </Text>
                  </Tab>
                  </Link>
                  <Text display={{ base: "block", md: "block" }}>{" | "}</Text>
                  <Link to={`/masterlists`}>
                  <Tab
                    p="0"
                    color={"#333333"}
                    textStyle={"primary"}
                    fontSize={{base:"15px",md:"16px"}}
                    fontWeight={"600"}
                    mr={{base :showMasterlistType ? "0px" : "0px", md:showMasterlistType ?  "25px" : "350px" }}

                    zIndex={1}
                    _selected={{
                      borderBottom: {base:"2px",md:"4px"},
                      borderColor: "#333333",
                      fontWeight:"700"
                    }}
                    onClick={() => {
                      setShowMasterlistType(false);
                    }}
                  >
                    <Text mx="5px">
                      {t(explore.MASTERLISTS_TAB_TITLE)}
                    </Text>
                  </Tab>
                
                  </Link>
                  <Box display={{base:"none",md:"contents"}}>
                  {showMasterlistType && (
                  <Link to={`/masterlists-index`}>
                   <Text
                   mx="5px"
                   fontSize={{base:"15px",md:"16px"}}
                   fontWeight={"700"}
                   position={"relative"}
                 >
                   {t(explore.INDEX_TAB_TITLE)}
                 </Text>
                 </Link>
                )}
                </Box>
                </TabList>
              </HStack>
            </Stack>
            <TabPanels>
              <TabPanel px={"0"} pt={{base:"65px",md:"4"}}>
                <ExplorePosts />
              </TabPanel>
              <TabPanel px={"0"} pt={{base:"55px",md:"0px"}}>
                <GlobalPost />
              </TabPanel>
              <TabPanel px={"0"} pt={{base:"55px",md:"0px"}}>
                <GlobalInsights />
              </TabPanel>
              <TabPanel px={"0"} pt={{base:"50px",md:"0px"}}>
              <Box pb="4" display={{base:"flex",md:"none"}} justifyContent={"flex-end"}>
                  {showMasterlistType && (
                  <Link to={`/masterlists-index`}>
                   <Text
                   mx="5px"
                   fontSize={{base:"15px",md:"16px"}}
                   fontWeight={"700"}
                   position={"relative"}
                 >
                   {t(explore.INDEX_TAB_TITLE)}
                 </Text>
                 </Link>
                  )}
                 </Box>
                {/* <Masterlists /> */}
                <MasterlistTab/>
              </TabPanel>
              <TabPanel px={"0"} pt="0" mx="35px">
                <Box py="20">
                 <WebpageComingSoon />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Container maxW="1440px" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>


        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};
const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      handleNavigation={handleNavigation}
      LeftVmenuData={LeftVmenuData}
    />
  );
};

import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

//Core files
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCreative,
  EffectFade,
  EffectFlip,
  Navigation,
  Pagination,
} from "swiper";
import { isArray } from "lodash";

//Component files
import { common } from "../../../constants/constants";
import { getImageURL } from "../../../util/getImage";
import { useState } from "react";
import Medium from "../../../Theme/Typography/Medium";
// import { BsCurrencyDollar } from "react-icons/bs";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`
const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

export const FullScreenImage = ({
  isOpen,
  onClose,
  clickedImageIndex,
  data,
  setReadMoreFlag,
  bannerFlag,
  title,
}) => {

  const [expanded, setExpanded] = useState(false);
  const MAX_LENGTH = 500; // Maximum length of description to show when collapsed

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Container maxW={"1400px"} px="0px" >
      <Modal
        size={"full"}
        isCentered
        isOpen={isOpen}
        onClick={onClose}
        Id="modalfullscreen"
        motionPreset="none"
        autoFocus={false}
        returnFocusOnClose={false} 
      >
        <ModalOverlay />
        <ModalContent zIndex={"999"} >
        <ModalBody margin={{ base: "0", md: "16" }}> 
            <Box
              bg={"white"}
              justifyContent={"center"}
              display={"flex"}
            >
             <Stack maxW={{base:"100%",md:"1400px"}}>
                <Swiper
                  effect={"creative"}
                  slidesPerView={1}
                  navigation
                  initialSlide={clickedImageIndex}
                  id="allsliderskk1"
                  modules={[EffectCreative, EffectFade, Navigation, Pagination]}
                >
                  {data?.length > 0 &&
                    data?.map((item, index) => {
                      return (
                        <SwiperSlide key={index + 1}>
                         <Stack
                            maxW={{base:"100%",md:"1400px"}}
                            marginTop={{ base: "250px", md: "0" }}
                            flexDirection={{ base: "column", md: "row" }}
                            gap={{base:"0",md:"8"}}
                          >
                            <Stack p="8"  border={"0.01rem solid #b3b3b3"}
                              borderRadius={"5px"}>
                            <HStack
                              width={{base:"auto",md:"680px"}}
                              justifyContent={"center"}
                             >
                              <Stack>
                                  {isArray(item?.fullImg) &&
                                  item?.fullImg.length > 0 ? (
                                    <Stack width={"100%"} alignItems={"center"}>
                                    <Swiper
                                      grabCursor={true}
                                      navigation={true}
                                      modules={[
                                        EffectFlip,
                                        Navigation,
                                        Pagination,
                                      ]}
                                    >
                                      {item?.fullImg?.map((it, ind) => (
                                        <>
                                          <SwiperSlide key={ind + 1}>
                                            <VStack>
                                              <Stack
                                                justifyContent={"center"}
                                                width={{
                                                  base: "auto",
                                                  md: "100%",
                                                }}
                                                height={{
                                                  base: "275px",
                                                  md: "100%",
                                                }}
                                              >
                                              <Image
                                                src={getImageURL(item?.fullImg, '680', '550')
                                                }
                                                fallbackSrc={placeholderImg}
                                                width={"100%"}
                                                height={"inherit"}
                                                objectFit={"contain"}
                                                alt=""
                                              />
                                              </Stack>
                                              <HStack>
                                                <Text
                                                  fontSize={"16px"}
                                                  color={"#035DA1"}
                                                >
                                                  {`${it?.value} | ${it?.imageName} | ${it?.medium_of_artwork} | ${it?.year}`}
                                                </Text>
                                              </HStack>
                                            </VStack>
                                          </SwiperSlide>
                                        </>
                                      ))}
                                    </Swiper>
                                    </Stack>
                                  ) : (
                                    <>
                                     <HStack width={"100%"} alignItems={"center"}>
                                    <Box  width={"100%"} height={{ base: "auto", md: "550px" }}>
                                      <Image
                                        src={getImageURL(item?.fullImg, '680', '550')
                                        }
                                        fallbackSrc={placeholderImg}
                                        width={"100%"}
                                        height={"inherit"}
                                        objectFit={"contain"}
                                        alt=""
                                      />
                                      </Box>
                                   </HStack>
                                  
                                    </>
                                  )}
                              </Stack>
                              
                            </HStack>
                            <HStack px="64px" alignItems={"flex-start"}>
                                      {item?.auction_estimates && (
                                        <HStack gap="10" flexDirection={"row"} pt={4}>
                                          {item?.auction_estimates?.length > 0 &&
                                            item?.auction_estimates.map(
                                              (it) => (
                                                <Stack
                                                key={index}
                                                >
                                                  <Medium>{it?.display}</Medium>
                                                  <Medium fontWeight="700">₹{it?.value_in_inr} </Medium>
                                                  <Medium as="span">${it?.valuein_dollar}</Medium>
                                                </Stack>
                                              )
                                            )}
                                        </HStack>
                                      )}
                                      </HStack>
                                      </Stack>
                            <HStack
                              alignItems={"flex-start"}
                              width={{base:"auto",md:"500px"}}
                              flexDirection={"column"}
                            >
                              {item?.title && 
                              <Box
                              width={"100%"}
                              borderBottom={"1px"}
                              borderColor={"#b3b3b3"}
                              >
                                <Box paddingBottom={"10px"} width={"100%"}>
                                  <Text
                                    fontSize={"20px"}
                                    fontWeight={"700"}
                                    color="#0066B3"
                                  >
                                  {item?.title}
                                  </Text>
                                  </Box>
                              </Box>}
                              <Stack
                                py="2"
                                marginInlineStart={"0rem!important"}
                                borderBottom={"1px solid #b3b3b3"}
                                width={"100%"}
                              >
                                {item?.essayistData?.length > 0 &&
                                  item?.essayistData?.map((it) => (
                                    <Stack
                                        marginInlineStart={"0rem!important"}
                                        py="1"
                                        key={`${it?.display}-${index}`}
                                        gap={"2"}
                                        className="mt-0 px-0"
                                        flexDirection={"column"}
                                      >
                                      <HStack className="mt-0 px-0">
                                        <Text
                                          fontSize={"14px"}
                                          fontWeight={"700"}
                                          color="black"
                                        >
                                          {it?.display}
                                        </Text>
                                      </HStack>
                                      {it?.value && (
                                        <HStack className="mt-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"400"}
                                            // color="#035DA1"
                                          >
                                            {`${it?.value}`}
                                          </Text>
                                        </HStack>
                                      )}
                                    </Stack>
                                  ))}
                              </Stack>
                              {item?.auctionData && (
                                <Stack
                                  py="2"
                                  marginInlineStart={"0rem!important"}
                                  borderBottom={"1px solid #b3b3b3"}
                                  width={"100%"}
                                >
                                  {item?.auctionData?.length > 0 &&
                                    item?.auctionData?.map((it) => (
                                      <Stack
                                        marginInlineStart={"0rem!important"}
                                        py="1"
                                        gap={"2"}
                                        key={`${it?.display}-${index}`}
                                        className="mt-0 px-0"
                                      >
                                        <HStack className="mt-0 px-0">
                                        <Medium fontWeight="700">
                                            {it?.display}
                                            </Medium>
                                        </HStack>
                                        {it?.value && (
                                          <HStack className="mt-0">
                                            <Medium>
                                              {it?.value}
                                            </Medium>
                                          </HStack>
                                        )}
                                      </Stack>
                                    ))}
                                </Stack>
                              )}
                                {item?.content?.value && (
                                <Box
                                py="2"
                                className="ml-0"
                                alignItems={"flex-start"}
                                flexDirection={"column"}
                              >
                                {isArray(item.content) ? (
                                  item.content.map((it) => (
                                    <Stack
                                      marginInlineStart={"0rem!important"}
                                      py="1"
                                      gap={"2"}
                                      className="mt-0 px-0"
                                      key={`${it?.display}-${index}`}
                                    >
                                      <HStack className="mt-0 px-0">
                                        <Medium lineHeight={"16.44px"}>
                                          {it?.display}
                                        </Medium>
                                      </HStack>
                                      {it.value && (
                                        <HStack className="mt-0">
                                         <Medium lineHeight={"16.44px"}>
                                            {it?.value}
                                            </Medium>
                                        </HStack>
                                      )}
                                    </Stack>
                                  ))
                                ) : (
                                  <Box maxH={"380px"} overflowY={"scroll"}>
                                    <>
                                    <Stack
                                        marginInlineStart={"0rem!important"}
                                        py="1"
                                        gap={"2"}
                                        className="mt-0 px-0"
                                      >
                                      <HStack className="mt-0 px-0">
                                        <Medium lineHeight={"16.44px"} fontWeight="700">
                                          {item?.content?.display}
                                        </Medium>
                                      </HStack>
                                      <Medium lineHeight={"16.44px"}>
                                        {expanded ? item?.content?.value : `${item?.content?.value?.substring(0, MAX_LENGTH)}...`}
                                      </Medium>
                                      { item?.content?.value?.length > MAX_LENGTH && (
                                        <Medium
                                          pt="1"
                                          marginInlineStart={"0px!important"}
                                          fontWeight={"700"}
                                          color="color.blue"
                                          cursor={"pointer"}
                                          onClick={toggleExpanded}>
                                          {expanded ? common.READ_LESS : common.READ_MORE}
                                        </Medium>
                                      )}
                                    </Stack>
                                    </>
                                  </Box>
                                )}
                              </Box>
                              )}
                            </HStack>
                          </Stack>
                          <HStack>
                              <Image
                                 right={"1%"} top={"1%"} position={"absolute"}
                                src={closeIcon}
                                onClick={() => {
                                  onClose();
                                }}
                                cursor={"pointer"}
                              ></Image>
                            </HStack>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

import { Box, Image, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import H4 from '../../Theme/Typography/H4';
import Small from '../../Theme/Typography/Small';
import { getImageURL } from '../../util/getImage';

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/medium.png`

const TimelineWithGrid = ({ data, handleDrawer }) => {
    let currentYear = null;
    let sameYearElements = [];
    const renderGridElements = () => {
        return data.map((item, index) => {
            const isNewYear = currentYear === null || currentYear !== +item?.year;
            let elementsToRender = null;

            // Render elements for the previous year before starting a new one
            if (isNewYear) {
                if (sameYearElements.length > 0) {
                    elementsToRender = (
                            <VerticalTimelineElement
                                id="timeline3"
                                contentStyle={{ fontSize: "sm" }}
                                iconStyle={{
                                    background: "#fff",
                                    color: "black",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                }}
                                icon={currentYear}
                                key={currentYear}
                            >
                                <SimpleGrid columns={1} spacing={1}>
                                    {sameYearElements}
                                </SimpleGrid>
                            </VerticalTimelineElement>
                    );
                    sameYearElements = [];
                }
                currentYear = +item?.year;
            }

            // Add current item to sameYearElements
            sameYearElements.push(
                <Box pb="4" key={item.alias || index}>
                    <Image
                        src={
                            item?.image?.length > 0
                                ? getImageURL(item?.image)
                                : ""
                        }
                        alt={item?.mainTitle || "Image"}
                        fallbackSrc={placeholderImg}
                        width={"471px"}
                        height={"234px"}
                        objectFit={"cover"}
                        cursor={"pointer"}
                        onClick={() => handleDrawer(item)}
                    />
                    {item?.desc && (
                        <H4
                            py="1"
                            color="color.blue"
                            cursor={"pointer"}
                            onClick={() => handleDrawer(item)}
                        >
                            {item?.desc}
                        </H4>
                    )}
                    <Small fontWeight={500}>{item?.shortdesc}</Small>
                </Box>
            );

            // Handle last item rendering if it's the final entry
            if (index === data.length - 1 && sameYearElements.length > 0) {
                elementsToRender = (
                    <VerticalTimelineElement
                        id="timeline3"
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                            background: "#fff",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "700",
                        }}
                        icon={currentYear}
                        key={currentYear}
                    >
                        <SimpleGrid columns={1} spacing={1}>
                            {sameYearElements}
                        </SimpleGrid>
                    </VerticalTimelineElement>
                );
            }

            return elementsToRender || null; // Ensuring a return statement
        });
    };

    return (
        <VerticalTimeline layout={"1-column-left"} className="layout">
            {renderGridElements()}
        </VerticalTimeline>
    );
};

export default TimelineWithGrid;

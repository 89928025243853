import React, { useEffect, useState } from 'react'
import { explore } from '../../../../constants/constants'
import IllustratedBooks from './Publications/IllustratedBooks'
import AuthoredEditedBooks from './Publications/AuthoredEditedBooks'
import TabMain from '../../../../FunctionalComponents/TabComponents/TabMain'
import { useLocation, useParams } from 'react-router-dom'

export default function Publications({masterlist,masterlistType}) {
  const [attr, setAttr] = useState(0)
  const {childTab}=useParams();
  const pathToIndex = {
    'illustrated-book': 0,
    'authored-editor-book': 1,
  };
  const location = useLocation();  
  useEffect(() => {
    setAttr(pathToIndex[childTab] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);
  function formatData() {
    return [
      {
        name: `${explore.ILLUSTRATED_BOOKS}`,
        component:<IllustratedBooks masterlist={masterlist}/>,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/scholarship/publications/illustrated-book`,

      },
      {
        name: `${explore.AUTHORED_EDITED_BOOKS}`,
        component:<AuthoredEditedBooks masterlist={masterlist}/>,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/scholarship/publications/authored-editor-book`,
      },
    ];
}

const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(masterlist){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist])
  return (
    <>
      {tabPanelData && tabPanelData.length>0 && <TabMain data={tabPanelData}  attr={attr} setAttr={setAttr} />}

    </>
  )
}

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import {
  Box,
  Container,
  HStack,
  Image,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";

// Core Files
import React, { useEffect, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { getImageURL } from "../../util/getImage";
import H3 from "../../Theme/Typography/H3";

// Image files
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`

const InsightPageSlider = ({images}) => {
  const [imgData, setImgData] = useState([]);
  const [selectedImg, setSelectedImg] = useState({
    image: "",
    desc: "",
    index: 0,
    slideImage: "",
  });
  const swiperRef = useRef(null);
  const handleNextButtonClick = () => {

    const activeIndex = selectedImg.index + 1;
    const nextSlide = imgData[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: imgData[activeIndex][0],
        index: activeIndex,
        slideImage: imgData[activeIndex][0],
      });
    }
  };

  const handlePreviousButtonClick = () => {
    const activeIndex = selectedImg.index - 1;
    const nextSlide = imgData[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: imgData[activeIndex][0],
        index: activeIndex,
        slideImage: imgData[activeIndex][0],
      });
    }
  };


  useEffect(() => {
    const getData = async () => {
      if (images && images.length > 0) {
        // Split images into chunks of 6
        const chunkSize = 6;
        const imageChunks = [];
  
        for (let i = 0; i < images.length; i += chunkSize) {
          const chunk = images.slice(i, i + chunkSize);
          imageChunks.push(chunk);
        }
  
        // Set the first chunk as the initial selected images
        setImgData(imageChunks);
        setSelectedImg({
          image: imageChunks[0][0], // First image of the first chunk
          index: 0,
          slideImage: imageChunks[0][0], // First image of the first chunk
        });
      }
    };
    getData();
  }, [images]);
  
  return (
    <>
      <Container maxW="auto" className="songs ml-0"  id="allslider" px="0px">
        <Stack direction={{base:"column",md:"row  "}} px="0px" position={"relative"} gap={{base:"10px",md:"60px"}}>
          <Stack alignItems={"flex-start"}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={{ base: "100%", md: "752px" }}
              height={{ base: "375px", md: "445px" }}
              border={{base:'1px',md:'none'}}
              borderColor={"color.lightgray4"}
            >
              <Image
                fallbackSrc={placeholderImg}
                src={getImageURL(selectedImg?.slideImage)}
                width={"100%"}
                height={"inherit"}
                objectFit={"cover"}
              />
            </Box>
            <H3 fontWeight="500" color="color.blue" lineHeight={"18.78px"}>{selectedImg?.image_citation}</H3>
            <H3 fontWeight="400" color="black" lineHeight={"18.78px"}>{selectedImg?.image_caption}</H3>
          </Stack>

          <Stack
            style={{ height: {base:"100%",md:"28rem"} }}
            width={{base:"100%",md:"30%"}}
            className="mt-0"
            position={"relative"}
          >
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              spaceBetween={20}
              // loop={true}
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              height={"100%"}
              modules={[Pagination,Navigation]}
              onSlideNextTransitionEnd={() => {
                handleNextButtonClick();
              }}
              onSlidePrevTransitionEnd={() => {
                handlePreviousButtonClick();
              }}
              id="slideshowinsight"
            >
                {imgData && imgData.length > 0 &&
                imgData.map((item, index) => (
                  <SwiperSlide>
                    <HStack>
                      <Stack>
                          <SimpleGrid
                            justifyItems={"flex-start"}
                            columns={[3, null, 3]}
                            spacing="5px"
                          >
                            {item && item.length > 0 &&
                            item.map((it, index) => (
                              <Box
                              width={{base:"100%",md:"122px"}}
                              height={{base:"100px",md:"161px"}}
                              border={"1px"}
                              borderColor={"color.lightgray4"}
                                className="pegination-img"
                                onClick={() => {
                                  setSelectedImg({
                                    image: it,
                                    index: 0,
                                    slideImage: it,
                                  });
                                }}
                              >
                                <Image
                                  fallbackSrc={placeholderImg}
                                  src={getImageURL(it)}
                                  width={"100%"}
                                  height={"inherit"}
                                  objectFit={"cover"}
                                  alt={""}
                                />
                              </Box>
                            ))}
                          </SimpleGrid>
                      </Stack>
                    </HStack>
                  </SwiperSlide>
                ))}
            </Swiper>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};
export default InsightPageSlider;
// import Config from 'react-native-config';

// console.log(Config, process.env);
const BASE_URL = process.env.REACT_APP_MONGODB_URL;

export const APIS = {
  BASE_URL: BASE_URL,
  request_otp: `/users/request_otp`,
  login: `/users/login`,
  refresh_token: `/users/refreshToken`,
  create_txn: '/transactions/',
  txn_history: '/transactions/me',
  account_details: '/accounts/me',
  get_user_by_mobile: '/users/mobile',
  update_profile: '/users/me',
  reset_pin: '/accounts/me/pin',
  raise_dispute: '/disputes/',
  txn_disputes: '/disputes/me',
  profile:'users/authenticated/profile',
  me:'users/authenticated/me',
  hasAccess:'users/authenticated/masterlist',
  searchAutosuggest:'search/autosuggest?search',
  allResearchCategory: 'research-categories/all',
  allResearchCategoryMasterlist: 'research-categories/masterlists',
  masterlistAgraphySection: 'agraphy',
  awards: 'economics/awards-honours',
  screening: 'economics/screenings',
  screeningFilter: 'economics/screenings/filter',
  integratedTimeline: 'body-of-work/integrated-timeline',
  integratedTimelineYearRange: 'body-of-work/integrated-timeline/yearRange',
  integratedTimelineYearFilter: 'body-of-work/integrated-timeline/year/filter',
  castCrew: 'body-of-work/cast-crew',
  introductionFilm: 'introduction/film',
  filmPreface: 'introduction/film/preface',
  filmSpecification: 'introduction/film/specifications',
  synopsis: 'body-of-work/synopsis',
  cast: 'body-of-work/cast',
  credits: 'body-of-work/credits',
  lyricsSoundtrack: 'body-of-work/lyrics-soundtrack',
  filmography: 'body-of-work/filmography',
  exhibitions: 'body-of-work/exhibitions',
  filmEditorial: 'body-of-work/film-editorial',
  editorial: 'body-of-work/editorial',
  filmographyFilter: 'body-of-work/filmography/filter',
  filmographyPerson: 'body-of-work/filmography/person',
  personality: 'introduction/personality',
  personalityEducation: 'introduction/personality/education',
  personalityTeachingInfrastructure: 'introduction/personality/teaching-infrastructure',
  film:'film',
  auction: 'auction/',
  lot: 'lot/',
  lots: 'lots',
  artwork: 'artwork',
  explore: 'explore',
  masterlists: 'masterlists',
  postsFeatured: 'posts/featured',
  projects: 'projects',
  songs: 'songs',
  userAccess:'hasAccess',
  stages:'stages',
  event:'introduction/event',
  object:'introduction/object',
  essays:'body-of-work/essays',
  exhibits:'body-of-work/exhibits',
  economics:'economics/market-analysis',
  all:'body-of-work/all',
  brandAmbassdorShip:'body-of-work/brand-ambassadorship',
  econSeminars:'economics/seminars',
  scholarshipLettersScribbles:'body-of-work/scholarship/letters-scribbles',
  scholarshipEssays:'body-of-work/scholarship/essays',
  editions:'body-of-work/editions',
  chronology:'chronology',
  illustratedBookData:'body-of-work/scholarship/publications/illustrated-books',
  authoredBookData:'body-of-work/scholarship/publications/authored-edited-books',
  location:'introduction/location',
  topAuctionSales:'economics/auction-sales',
  virtualtour:"body-of-work/virtual_tours"
};
